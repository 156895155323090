import React from "react";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { Link, useParams } from "react-router-dom";
// import SchoolMapAddShapeForm from "./SchoolMapAddShapeForm";
import { faPieChart, faSave } from "@fortawesome/pro-regular-svg-icons";
// import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import SchoolMapGroupList from "./SchoolMapGroupList";
import Popup from "reactjs-popup";
import {
  faBuilding,
  faList,
  faChevronDown,
  faLayerPlus,
  faMapMarked,
  faPeopleRoof,
  faFileChartPie,
} from "@fortawesome/pro-regular-svg-icons";
import "reactjs-popup/dist/index.css";
import CapacityReport from "./CapacityReport";
import ModalHeaderForForms from "./ModalHeaderForForms";
import EligibleBuildings from "./EligibleBuildings";
import InventoryReport from "./InventoryReport";
import AreaReport from "./AreaReport";
// import SchoolMapSidebarToolsHeader from "./SchoolMapSidebarToolsHeader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    overflow: "scroll",
  },
};

const SchoolMapToolbar = ({
  shapes,
  onAddShape,
  handleSaveMapData,
  isAddIconFormshown,
  setIsAddIconFormshown,
  isAddTextFormShown,
  setIsAddTextFormShown,
  onAddIconClicked,
  onAddTextClicked,
  setIsClickOnMapEnabled,
  isMapOnClickEnabled,
  clickedShapeToGetBold,
  handleCancelAddShape,
  onAddBuildingInfo,
  onAddRoomInfo,
  onAddSiteSystemInfo,
  setIsAddSiteSystemOpen,
  onDataListClicked,
}) => {
  // eslint-disable-next-line no-unused-vars
  const handleAddShape = (
    name,
    color,
    opacity,
    group,
    oldName,
    icon,
    fontSize,
    text
  ) => {
    const index = shapes.findIndex(
      (rect) => rect.name.toLowerCase() === name.toLowerCase()
    );

    if (
      index !== -1 ||
      (index !== -1 && oldName && name.toLowerCase() !== oldName.toLowerCase())
    ) {
      if (!oldName) {
        toast.error(
          "Please add a unique name, name: " + name + " already used"
        );
        return false;
      }

      if (oldName && name.toLowerCase() !== oldName.toLowerCase()) {
      }
    }

    if (opacity && (+opacity < 1 || +opacity > 10)) {
      toast.error("Opacity should be in range 1 to 10");
      return false;
    }

    // if (fontSize && text?.length === 0) {
    //   toast.error("Text can not be empty, please type something.");
    //   return false;
    // }

    const newShape = {
      height: 50,
      width: 50,
      name,
      group,
      x: 50,
      y: 50,
      linkedEntitiy: "",
      linkedEntitiyType: "",
      opacity,
      color,
      rotation: 0,
      fontSize,
      text,
    };

    if (icon) newShape.icon = icon;

    onAddShape(newShape, oldName, group);

    reset();
    return true;
  };

  // eslint-disable-next-line no-unused-vars
  const handleCancel = () => {
    handleCancelAddShape();
    reset();
  };

  const reset = () => {
    setIsAddIconFormshown(false);
    setIsAddTextFormShown(false);
  };

  const { siteID } = useParams();

  const [isCapacityReportModalOpen, setIsCapacityReportModal] =
    React.useState(false);
  const [isEligibleBuildingModalOpen, setIsEligibleBuildingModal] =
    React.useState(false);
  const [isInventoryReportModalOpen, setIsInventoryReportModal] =
    React.useState(false);

  const [isAreaReportModal, setIsAreaReportModal] = React.useState(false);

  return (
    // <div className="flex flex-row h-10 bg-black justify-between align-middle">
    <div className="flex flex-row justify-between align-middle h-12 px-2 border-b-2">
      <div className="flex flex-row w-auto h-full justify-center align-middle">
        <Popup
          trigger={
            <div className="flex h-full flex-col justify-center align-middle">
              <button
                className="flex flex-row px-2 py-1  justify-between w-48 border-2 rounded-md border-500"
                type="button"
              >
                <div className="text-700">
                  <span className="mr-2">
                    <FontAwesomeIcon icon={faBuilding} fontSize="14px" />
                  </span>
                  <span className="">Building</span>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    fontSize="14px"
                    color="#334155"
                  />
                </div>
              </button>
            </div>
          }
          position="bottom"
          on="click"
          closeOnDocumentClick
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          // contentStyle={{ padding: "0px", border: "none", width: "150px" }}
          arrow={false}
        >
          {(closePopup) => {
            return (
              <>
                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    onAddBuildingInfo();
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faLayerPlus} /> &nbsp; Add/Edit
                  Building
                </div>

                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    onDataListClicked("Building");
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faList} />
                  &nbsp; Building List
                </div>
              </>
            );
          }}
        </Popup>
        <Popup
          trigger={
            <div className="flex h-full flex-col justify-center align-middle ml-2">
              <button
                className="flex flex-row px-2 py-1  justify-between w-48 border-2 rounded-md border-500"
                type="button"
              >
                <div className="text-700">
                  <span className="mr-2">
                    <FontAwesomeIcon icon={faPeopleRoof} fontSize="14px" />
                  </span>
                  <span className="">Room</span>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    fontSize="14px"
                    color="#334155"
                  />
                </div>
              </button>
            </div>
          }
          position="bottom"
          on="click"
          closeOnDocumentClick
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          // contentStyle={{ padding: "0px", border: "none", width: "150px" }}
          arrow={false}
        >
          {(closePopup) => {
            return (
              <>
                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    onAddRoomInfo();
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faLayerPlus} /> &nbsp; Add/Edit Room
                </div>

                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    onDataListClicked("Room");
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faList} />
                  &nbsp; Room List
                </div>
              </>
            );
          }}
        </Popup>
        <Popup
          trigger={
            <div className="flex h-full flex-col justify-center align-middle ml-2">
              <button
                className="flex flex-row px-2 py-1  justify-between w-48 border-2 rounded-md border-500"
                type="button"
              >
                <div className="text-700">
                  <span className="mr-2">
                    <FontAwesomeIcon icon={faMapMarked} fontSize="14px" />
                  </span>
                  <span className="">Site System</span>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    fontSize="14px"
                    color="#334155"
                  />
                </div>
              </button>
            </div>
          }
          position="bottom"
          on="click"
          closeOnDocumentClick
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          // contentStyle={{ padding: "0px", border: "none", width: "150px" }}
          arrow={false}
        >
          {(closePopup) => {
            return (
              <>
                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    setIsAddSiteSystemOpen(true);
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faLayerPlus} /> &nbsp; Add Site System
                </div>

                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    onDataListClicked("Site System");
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faList} />
                  &nbsp; Site System List
                </div>
              </>
            );
          }}
        </Popup>
        <Popup
          trigger={
            <div className="flex h-full flex-col justify-center align-middle ml-2">
              <button
                className="flex flex-row px-2 py-1  justify-between w-48 border-2 rounded-md border-500"
                type="button"
              >
                <div className="text-700">
                  <span className="mr-2">
                    <FontAwesomeIcon icon={faFileChartPie} fontSize="14px" />
                  </span>
                  <span className="">Reports</span>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    fontSize="14px"
                    color="#334155"
                  />
                </div>
              </button>
            </div>
          }
          position="bottom"
          on="click"
          closeOnDocumentClick
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          // contentStyle={{ padding: "0px", border: "none", width: "150px" }}
          arrow={false}
        >
          {(closePopup) => {
            return (
              <>
                {/* <Link to={`/districts?site=${siteID}`}> */}
                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    setIsInventoryReportModal(true);
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faFileChartPie} /> &nbsp; Inventory
                  Report
                </div>

                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    setIsAreaReportModal(true);
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faFileChartPie} />
                  &nbsp; Area Report
                </div>

                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    setIsCapacityReportModal(true);
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faFileChartPie} />
                  &nbsp; Capacity Report
                </div>

                <div
                  className="schoolMap__sideTools__addShape__popupMenu__item"
                  onClick={() => {
                    setIsEligibleBuildingModal(true);
                    closePopup();
                  }}
                >
                  <FontAwesomeIcon icon={faFileChartPie} />
                  &nbsp; Eligible Buildings Report
                </div>
              </>
            );
          }}
        </Popup>
      </div>
      <button
        onClick={handleSaveMapData}
        className="bg-200 text-700 m-1 px-3 py-1 rounded-md font-rubik"
      >
        <span>
          <FontAwesomeIcon icon={faSave} />
        </span>
        <span className="ml-2">Save</span>
      </button>
      <Modal
        isOpen={isCapacityReportModalOpen}
        onRequestClose={() => setIsCapacityReportModal(false)}
        style={customStyles}
      >
        <ModalHeaderForForms
          onRequestClose={() => setIsCapacityReportModal(false)}
          title="Capacity Report"
          icon={faPieChart}
        />
        <div className="modalContent">
          <CapacityReport siteID={siteID} />
        </div>
      </Modal>
      <Modal
        isOpen={isEligibleBuildingModalOpen}
        onRequestClose={() => setIsEligibleBuildingModal(false)}
        style={customStyles}
      >
        <ModalHeaderForForms
          onRequestClose={() => setIsEligibleBuildingModal(false)}
          title="Capacity Report"
          icon={faPieChart}
        />
        <div className="modalContent">
          <EligibleBuildings siteID={siteID} />
        </div>
      </Modal>
      <Modal
        isOpen={isInventoryReportModalOpen}
        onRequestClose={() => setIsInventoryReportModal(false)}
        style={customStyles}
      >
        <ModalHeaderForForms
          onRequestClose={() => setIsInventoryReportModal(false)}
          title="Inventory Report"
          icon={faPieChart}
        />
        <div className="modalContent">
          <InventoryReport siteID={siteID} />
        </div>
      </Modal>
      <Modal
        isOpen={isAreaReportModal}
        onRequestClose={() => setIsAreaReportModal(false)}
        style={customStyles}
      >
        <ModalHeaderForForms
          onRequestClose={() => setIsAreaReportModal(false)}
          title="Building Area Report"
          icon={faPieChart}
        />
        <div className="modalContent">
          <AreaReport siteID={siteID} />
        </div>
      </Modal>
    </div>
    // </div>
  );
};

export default SchoolMapToolbar;
