export const HIDDEN_COLUMNS = [
  // "note",
  "constructionCost",
  "priority",
  "softCost",
  "totalCost",
  "address",
  "actions",
  "web",
];
