import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileChartPie } from "@fortawesome/pro-regular-svg-icons";
import { getDistrictArea } from "../services/districtService";
import { DISTRICT_AREA_COLUMNS } from "../data/districtAreaColumn";
import Table from "./common/Table";

const DistrictAreaReport = ({ districtID }) => {
  const [areas, setArea] = useState([]);
  useEffect(() => {
    const fetchDistrictArea = async () => {
      const { data } = await getDistrictArea(districtID);
      setArea(data);
    };
    fetchDistrictArea();
  }, [districtID]);
  return (
    <div className="d-flex flex-column" style={{ overflowY: "hidden" }}>
      {/* <Navbar user={user} /> */}
      <div className="mx-8 mb-4">
        <div className="flex flex-row justify-between text-center my-4 ml-2">
          {/* <span className="w-40">{rooms[0]?.schoolName}</span> */}
          <span className="font-Rubik font-semibold">Area Report</span>
          <span>
            <button
              className="flex justify-center bg-700 text-white p-2 px-3 rounded-md ml-2 text-sm"
              // onClick={onPrint}
            >
              <span>
                <FontAwesomeIcon icon={faFileChartPie} fontSize="18px" />
              </span>
              <span className="ml-2">Generate Report</span>
            </button>
          </span>
        </div>
        <Table tableData={areas} tableColumns={DISTRICT_AREA_COLUMNS} />
      </div>
    </div>
  );
};

export default DistrictAreaReport;
