import backpack from "../assets/Icons/backpack.svg";
import baseball from "../assets/Icons/baseball-bat-ball.svg";
import basketball from "../assets/Icons/basketball-hoop.svg";
import bell from "../assets/Icons/bell-school.svg";
import camera from "../assets/Icons/camera-cctv.svg";
import board from "../assets/Icons/chalkboard-user.svg";
import faucet from "../assets/Icons/faucet-drip.svg";
import fire_extinguisher from "../assets/Icons/fire-extinguisher-solid.svg";
import fire_hydrant from "../assets/Icons/fire-hydrant.svg";
import ball from "../assets/Icons/futbol.svg";
import map_pin from "../assets/Icons/map-pin.svg";
import outlet from "../assets/Icons/outlet.svg";
import pipe_valve from "../assets/Icons/pipe-valve.svg";
import racquet from "../assets/Icons/racquet.svg";
import restroom from "../assets/Icons/restroom-simple.svg";
import doctor from "../assets/Icons/user-doctor-hair.svg";
import utility_pole from "../assets/Icons/utility-pole.svg";

export const SCHOOL_MAP_ICONS_DISPLAY = [
  backpack,
  baseball,
  basketball,
  bell,
  camera,
  board,
  faucet,
  fire_extinguisher,
  fire_hydrant,
  ball,
  map_pin,
  outlet,
  pipe_valve,
  racquet,
  restroom,
  doctor,
  utility_pole,
];

export const SCHOOL_MAP_ICONS = [
  {
    value: 1,
    label: "Baseball",
    icon: <img src={baseball} alt="" width={20} />,
  },
  {
    value: 2,
    label: "Basketball",
    icon: <img src={basketball} alt="" width={20} />,
  },
  {
    value: 3,
    label: "Bell",
    icon: <img src={bell} alt="" width={20} />,
  },
  {
    value: 4,
    label: "Camera",
    icon: <img src={camera} alt="" width={20} />,
  },
  {
    value: 5,
    label: "Board",
    icon: <img src={board} alt="" width={20} />,
  },
  {
    value: 6,
    label: "Faucet",
    icon: <img src={faucet} alt="" width={20} />,
  },
  {
    value: 7,
    label: "Fire Extinguisher",
    icon: <img src={fire_extinguisher} alt="" width={20} />,
  },
  {
    value: 8,
    label: "Fire Hydrant",
    icon: <img src={fire_hydrant} alt="" width={20} />,
  },
  {
    value: 9,
    label: "Ball",
    icon: <img src={ball} alt="" width={20} />,
  },
  {
    value: 10,
    label: "Map Pin",
    icon: <img src={map_pin} alt="" width={20} />,
  },
  {
    value: 11,
    label: "Outlet",
    icon: <img src={outlet} alt="" width={20} />,
  },
  {
    value: 12,
    label: "Pip Valve",
    icon: <img src={pipe_valve} alt="" width={20} />,
  },
  {
    value: 13,
    label: "Racquet",
    icon: <img src={racquet} alt="" width={20} />,
  },
  {
    value: 14,
    label: "Restroom",
    icon: <img src={restroom} alt="" width={20} />,
  },
  {
    value: 15,
    label: "Doctor",
    icon: <img src={doctor} alt="" width={20} />,
  },
  {
    value: 16,
    label: "Utility Pole",
    icon: <img src={utility_pole} alt="" width={20} />,
  },
  {
    value: 17,
    label: "Backpack",
    icon: <img src={backpack} alt="" width={20} />,
  },
];
