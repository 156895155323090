import React, { useState, useContext, useEffect } from "react";
import {
  faMessageLines,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import siteDetailService from "../../services/siteDetailService";
import { log } from "../../services/logService";
import { toast } from "react-toastify";
import SiteDetailForm from "./SiteDetailForm";
import DeleteDialog from "../common/DeleteDialog";
import Table from "../common/Table";
import { SITE_DETAIL_COLUMNS } from "../../data/siteDetailColumns";
import { NoteContext } from "../../context/NoteContext";
import ActionBtn from "../common/ActionBtn";
// import Input from "../components/common/Input";
import Editor from "../Editor";
import Select from "../common/Select";
import siteService from "../../services/siteService";

const AddSiteDetail = ({
  filters,
  formId,
  onRequestClose,
  feature,
  saveData,
  point,
  data,
}) => {
  const { setCurrentNote, setIsNoteOpen } = useContext(NoteContext);
  const [details, setDetails] = useState(data ? data : null);
  const [showForm, setShowForm] = useState(false);
  const [detailToEdit, setDetailToEdit] = useState(null);
  const [detailToEditIndex, setDetailToEditIndex] = useState(-1);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  // const [siteName, setSiteName] = useState("");
  const [siteSystemName, setSiteSystemName] = useState(data ? data.name : "");
  const [siteSystemNameFormField, setSiteSystemNameFormField] = useState(
    data ? data.name : ""
  );
  const [note, setNote] = useState(data?.note ?? "");
  const [isUpdateSystemSkipped, setIsUpdateSystemSkipped] = useState(
    data ? false : true
  );
  const [preloadData, setPreloadData] = useState({
    siteLocation: [],
    system: [],
    subSystem: [],
    action: [],
    actionItem: [],
    priority: [],
  });

  async function loadPreloadData() {
    const { data: res } = await siteService.getPreloadData();
    setPreloadData(res.data[0]);
  }

  useEffect(() => {
    loadPreloadData();
  }, []);

  async function onSubmit(e) {
    e.preventDefault();

    if (details || note) {
      const values = {
        name: siteSystemName,
        details: details ? details.details : [],
        siteID: filters.site,
        note: note,
      };

      if (data) values._id = data._id;

      try {
        const { data: res } = await siteDetailService.saveSiteDetail(values);
        saveData(res.data, "siteDetail");
      } catch (error) {
        log(error);
        toast.error("something went wrong");
      }
    }
  }

  const handleAdd = (detail) => {
    setDetails((pre) => {
      if (pre) {
        const newSiteDetail = { ...pre };

        if (detailToEdit && detailToEditIndex !== -1) {
          const newDetails = [...newSiteDetail.details];
          newDetails[detailToEditIndex] = detail;
          newSiteDetail.details = newDetails;
          return newSiteDetail;
        }

        newSiteDetail.details = [...newSiteDetail.details, detail];
        return newSiteDetail;
      }

      return {
        details: [detail],
      };
    });
    setShowForm(false);
    setDetailToEdit(null);
    setDetailToEditIndex(-1);
  };

  const handleDelete = () => {
    setDetails((pre) => {
      const newSiteDetail = { ...pre };
      const newDetails = [...newSiteDetail.details];
      newDetails.splice(detailToEditIndex, 1);
      newSiteDetail.details = newDetails;
      return newSiteDetail;
    });
    setShowForm(false);
    setDetailToEdit(null);
    setDetailToEditIndex(-1);
    setIsDeleteDialogOpen(false);
  };

  const getActionsButtons = (row) => {
    return (
      <>
        <ActionBtn
          icon={faPenToSquare}
          onClick={() => {
            setDetailToEdit(row);
          }}
        />

        <ActionBtn
          icon={faMessageLines}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentNote(row.note);
            setIsNoteOpen(true);
          }}
        />

        <ActionBtn
          isDeleteBtn={true}
          icon={faTrashCan}
          onClick={(e) => {
            setDetailToEdit(row);
            setIsDeleteDialogOpen(true);
          }}
        />
      </>
    );
  };

  const getTableColumns = () => {
    return [
      ...SITE_DETAIL_COLUMNS,
      {
        Header: "Actions",
        Footer: "",
        id: "actions",
        accessor: (row) => getActionsButtons(row),
      },
    ];
  };

  // return console.log(details);

  if (!siteSystemName || !isUpdateSystemSkipped)
    return (
      <div className="modal-content-1">
        <div className="mb-8 text-lg">Select the site system</div>
        <Select
          options={preloadData.system}
          label={"System"}
          name="system"
          classes="mb-custom-3"
          value={siteSystemNameFormField}
          onChange={(e) => setSiteSystemNameFormField(e.target.value)}
          disabled={preloadData.system.length === 0}
          fullWidth={true}
        />
        <div className="w-full h-full flex flex-row justify-end mt-2">
          <button
            type="button"
            className="btn btn-sm btn-custom-primary mt-2 w-1/2"
            onClick={() => {
              if (siteSystemNameFormField.length <= 2)
                return toast.error("Name should be at least 3 characters!");

              setSiteSystemName(siteSystemNameFormField);
            }}
          >
            {data ? "Update" : "Add"}
          </button>
        </div>
        {data && (
          <button
            type="button"
            className="btn btn-sm btn-light mx-2"
            onClick={() => setIsUpdateSystemSkipped(true)}
          >
            Skip
          </button>
        )}
      </div>
    );

  return (
    <>
      <div className="d-none">
        <form onSubmit={onSubmit} id={formId}></form>
      </div>
      <div className="d-flex flex-row justify-content-between mb-4">
        <h6 className="mb-3"> {siteSystemName ?? "Site Details"}</h6>
        <button
          type="button"
          className="btn btn-sm btn-custom-primary"
          onClick={() => setShowForm(true)}
        >
          Add Task
        </button>
      </div>

      {details && details.details?.length > 0 && (
        <>
          <Table
            tableData={details ? details.details : []}
            tableColumns={getTableColumns()}
            resourceToLoad="Site Details"
            onRowClicked={(_record, index) => {
              setDetailToEditIndex(index);
            }}
          />
          <div className="my-5 d-flex justify-content-center">
            <div style={{ borderTop: "1px solid #aaa", width: "12rem" }}></div>
          </div>
        </>
      )}

      {(!details || details?.details?.length === 0) && (
        <>
          <div className="text-center">
            No details yet, try to add by clicking add detail
          </div>
          <hr style={{ margin: "30px" }} />
        </>
      )}

      <Editor label="Site Observations" data={note} onChange={setNote} />

      <div className="my-5 d-flex justify-content-center">
        <div style={{ borderTop: "1px solid #aaa", width: "12rem" }}></div>
      </div>

      <div className="modal-content-1">
        {(showForm || detailToEdit) && (
          <SiteDetailForm
            data={detailToEdit}
            editOrAdd={!!detailToEdit}
            onAdd={handleAdd}
            preloadData={preloadData}
            systemName={siteSystemName}
          />
        )}
      </div>

      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        message={`Your related sites, buildings, facility system and rooms data will be deleted, and you can not undo that`}
        onRequestClose={() => {
          setDetailToEdit(null);
          setDetailToEditIndex(-1);
          setIsDeleteDialogOpen(false);
        }}
        onDelete={handleDelete}
      />
    </>
  );
};

export default AddSiteDetail;
