import { faMoneyBill } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const DashboardSideMenuItem = ({ label, isActive, icon }) => {
  return (
    <div
      className={`rounded-pill mb-1 dashboard__sidebar__item ${
        isActive && "dashboard__sidebar__activeItem"
      }`}
      style={{ textDecoration: "none" }}
    >
      <span className="icon_container">
        <FontAwesomeIcon icon={icon ? icon : faMoneyBill} />
      </span>
      <span>{label}</span>
    </div>
  );
};

export default DashboardSideMenuItem;
