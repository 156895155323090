import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFilePdf, faTrash } from "@fortawesome/pro-solid-svg-icons";
import FileInput from "./common/FileInput";
import { uploadFile } from "../utils/uploadFile";
import Input from "./common/Input";
import Select from "./common/Select";
import { saveBuilding } from "../services/buildingService";
// import { getBuildingsByID } from "../services/buildingService";

const BuildingFiles = ({ building }) => {
  const [pdfFiles, setPDFFiles] = useState(building?.files);
  const getCategorizedFiles = (pdfFiles) => {
    const categorized = pdfFiles.reduce((result, currentItem) => {
      const key = currentItem.category; // Getting the key for categorization
      if (!result[key]) {
        result[key] = []; // Initialize the array if this category hasn't been created yet
      }
      result[key].push(currentItem); // Push the current item into its category array
      return result;
    }, {});
    return categorized;
  };

  const [isOpen, setIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("Administrative Docs");
  const [singleFile, setSingleFile] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newFile = {
      name,
      category,
      url: singleFile,
    };
    // Use the updated `pdfFiles` returned by `setPDFFiles` inside `await saveBuilding`
    const updatedPdfFiles = [...pdfFiles, newFile];
    setPDFFiles(updatedPdfFiles);
    await saveBuilding({ _id: building._id, files: updatedPdfFiles }); // Use `updatedPdfFiles` here
    setIsOpen(false);
  };

  const handleFileDelete = async (fileName) => {
    // Create a new array excluding the file to be deleted
    const updatedPdfFiles = pdfFiles.filter((file) => file.name !== fileName);

    // Update the state
    setPDFFiles(updatedPdfFiles);

    // Perform any additional operations (e.g., updating the database)
    await saveBuilding({ _id: building._id, files: updatedPdfFiles });
  };

  const onChoosePDF = async (e) => {
    const file = await uploadFile(e.target.files[0]);
    setSingleFile(file[0]);
  };

  let CATEGORY_TYPE = [
    "Administrative Docs",
    "General",
    "Civil Plan",
    "Architectural Plan",
    "Structural Plan",
    "Mechanical & Plumbing Plan",
    "Electrical Plan",
    "Building Specs and User Guide",
  ];

  return (
    <div className="p-4 w-full h-full">
      <button
        className="w-3/12 bg-700 text-white h-8 rounded-md font-medium mb-2"
        onClick={() => setIsOpen(true)}
      >
        Add New File
      </button>
      {Object.keys(getCategorizedFiles(pdfFiles)).map((key) => {
        return (
          <div className="w-full" key={key}>
            <h2 className="my-2">{key.toUpperCase()}</h2>
            {getCategorizedFiles(pdfFiles)[key].map((item) => {
              return (
                <div className="h-full hover:bg-slate-100 my-1  rounded-md w-full flex flex-row justify-between items-center bg-slate-50 pr-2">
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <div className="cursor-pointer py-2 px-2 flex flex-row space-x-2 h-full hover:bg-slate-200 hover:text-700  rounded-md">
                      <div className="text-700">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </div>
                      <div>{item.name}</div>
                    </div>
                  </a>
                  <div className="flex flex-row space-x-2">
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <div className="flex flex-row items-center justify-center cursor-pointer m-1 h-7 w-7 bg-slate-200 rounded-full text-700">
                        <FontAwesomeIcon icon={faEye} />
                      </div>
                    </a>
                    <div
                      onClick={() => handleFileDelete(item.name)}
                      className="flex flex-row items-center justify-center cursor-pointer m-1 h-7 w-7 bg-slate-200 rounded-full text-red-400"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      {isOpen && (
        <div className="w-full flex flex-col space-y-1 mt-2">
          <form onSubmit={handleSubmit} className="flex w-full flex-col">
            <div className="flex flex-row w-full justify-between">
              <Input
                label={"Name"}
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="File Name"
              />
              <Select
                options={CATEGORY_TYPE}
                label={"Building Category"}
                name="buildingType"
                classes="mb-custom-3"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                placeholder="Select Category"
              />
            </div>

            <FileInput
              label={"PDFs"}
              showLabel={true}
              name="PDFs"
              type="file"
              onChange={onChoosePDF}
              title="Upload File"
            />
            <div className="flex flex-row mt-2 w-full justify-between">
              <button
                onClick={() => setIsOpen(false)}
                className="border-2 w-5/12 bg-white-200 text-700 h-10 rounded-md font-medium"
              >
                Cancel
              </button>
              <button
                className="w-5/12 bg-700 text-white h-10 rounded-md font-medium"
                type="submit"
              >
                Add File
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default BuildingFiles;
