import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActionBtn = ({ onClick, icon, isDeleteBtn }) => {
  return (
    <button
      type="button"
      // className={`w-6 h-6 mx-2 ${
      //   isDeleteBtn ? "text-danger" : "text-custom-primary"
      // } `}
      className={`w-6 h-6 mx-2`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} fontSize={18} className="text-700" />
    </button>
  );
};

export default ActionBtn;
