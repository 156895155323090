import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileInput from "../common/FileInput";
import Select from "../common/Select";
import Editor from "../Editor";
import Input from "../common/Input";
import { uploadImage } from "../../utils/uploadImage";
import { BUILDING_PRIORITY_OPTIONS } from "../../data/buildingDetailPriority";
import {
  handleCostChange,
  handleSoftCostChange,
} from "../../utils/commonUtils";
import PhotoPreviewForForms from "../common/PhotoPreviewForForms";

// Validation Schema
const validate = Yup.object({
  system: Yup.string()
    .min(2, "Must be 2 characters or more")
    .required("Required"),
  // subSystem: Yup.string().required("Required"),
  yearBuild: Yup.number()
    .min(1900, "Should not be less than 1900")
    .max(3000, "Should not be more than 3000"),
  areaUnits: Yup.number(),
});

const initialValues = {
  system: "",
  subSystem: "",
  note: "",
  constructionCost: "",
  softCost: "",
  areaUnits: "",
  priority: "",
};

const BuildingDetailForm = ({
  data,
  preloadData,
  onAddSystem,
  onAddSubSystem,
  onAddDetail,
}) => {
  const [note, setNote] = useState(data ? data.note : "");
  const [totalCost, setTotalCost] = useState(data ? data.totalCost : "");
  const [images, setImages] = useState(data ? data.imgUrls : []);

  const formik = useFormik({
    initialValues: data ? data : initialValues,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit,
  });

  function onSubmit(values) {
    values.note = note;
    values.totalCost = totalCost;
    values.imgUrls = images;

    onAddDetail(values);
  }

  const onChooseFile = async (e) => {
    const images = await uploadImage(e.target.files);
    setImages(images);
  };

  const printTotalCost = () => {
    // return totalCost;
    const newTotalCost = totalCost + "";
    if (newTotalCost.length > 0)
      return (
        "$" + parseInt(newTotalCost.replace(/\D/g, ""), 10).toLocaleString()
      );

    return "$" + newTotalCost;
  };

  return (
    <div className="mb-3 w-full">
      <h4 className="mb-3">Task</h4>

      <form onSubmit={formik.handleSubmit} className="w-full">
        <Editor label="Note" data={note} onChange={setNote} />
        <div className="w-full h-full flex flex-col justify-center align-middle">
          <div className="flex flex-col border-2 border-200 mb-3 rounded-lg pt-4 w-full h-full">
            <div className="grid grid-cols-3  gap-2">
              <div className="ml-6">
                <Select
                  options={preloadData.system}
                  label={"System"}
                  name="system"
                  classes="mb-custom-3"
                  error={formik.errors.system}
                  touched={formik.touched.system}
                  value={formik.values.system}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={preloadData.system.length === 0}
                  // addBtn={true}
                  // onClickAddBtn={onAddSystem}
                />
              </div>

              {/* <Select
          options={
            formik.values.system &&
            getOptions(formik.values.system, preloadData.subSystem)
          }
          label={"Sub System"}
          name="subSystem"
          classes="mb-custom-3"
          error={formik.errors.subSystem}
          touched={formik.touched.subSystem}
          value={formik.values.subSystem}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!formik.values.system}
          addBtn={true}
          onClickAddBtn={onAddSubSystem}
        /> */}

              <Input
                label={"Construction Cost"}
                name="constructionCost"
                error={formik.errors.constructionCost}
                touched={formik.touched.constructionCost}
                value={formik.values.constructionCost}
                onChange={(e) => handleCostChange(e, formik, setTotalCost)}
                onBlur={formik.handleBlur}
              />

              <Input
                label={"Soft Cost"}
                name="softCost"
                error={formik.errors.softCost}
                touched={formik.touched.softCost}
                value={formik.values.softCost}
                onChange={(e) => handleSoftCostChange(e, formik, setTotalCost)}
                onBlur={formik.handleBlur}
              />

              <Input
                label={"Total Cost"}
                name="totalCost"
                value={printTotalCost()}
                readOnly
              />

              <Select
                options={BUILDING_PRIORITY_OPTIONS}
                label={"Priority"}
                name="priority"
                classes="mb-custom-3"
                error={formik.errors.priority}
                touched={formik.touched.priority}
                value={formik.values.priority}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // disabled={preloadData.priority.length === 0}
              />

              <Input
                label={"Area/Units"}
                name="areaUnits"
                error={formik.errors.areaUnits}
                touched={formik.touched.areaUnits}
                value={formik.values.areaUnits}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                min={0}
              />
            </div>
            {/* <Input
          label={"Images"}
          type="file"
          onChange={onChooseFile}
          multiple
          // error={imageErrors}
          // touched={imageErrors !== ""}
        /> */}
            <div className="flex flex-row justify-start">
              <FileInput
                label={"Images"}
                showLabel={true}
                name="images"
                type="file"
                onChange={onChooseFile}
              />
              <PhotoPreviewForForms data={images} setData={setImages} />
            </div>
            <br />
            <br />
          </div>
          {/* <div className="text-end">
          <button className="btn btn-sm btn-custom-primary" type="submit">
            {data ? "Update" : "Add"}
          </button>
        </div> */}
          <div className="flex flex-row justify-between  flex-nowrap max-w-5xl space-x-2">
            <button
              type="submit"
              className="w-1/2  bg-700 text-white h-10 rounded-md font-medium"
            >
              {data ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BuildingDetailForm;
