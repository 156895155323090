// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function SelectDistrict({
  options,
  label,
  classes,
  icon,
  iconClasses,
  error,
  touched,
  ...props
}) {
  return (
    <div className={classes}>
      <select
        className="normalize-text custom-select district-select bg-white rounded-md"
        autoComplete="off"
        {...props}
      >
        <option value="" disabled>
          {label}
        </option>

        {options &&
          options.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
      </select>

      <div className="normalize-text text-danger px-2 error-text">
        {touched && error ? error : " "}
      </div>
    </div>
  );
}

export default SelectDistrict;
