import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import userService from "../../services/userService";
import { toast } from "react-toastify";
import auth from "../../services/authService";
import PasswordField from "../common/PasswordField";

// Validation Schema
const validate = Yup.object({
  currentPassword: Yup.string()
    .min(6, "Must be 6 characters or more")
    .required("Required"),
  newPassword: Yup.string()
    .min(6, "Must be 6 characters or more")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});

const ChangePassword = ({ formId, onRequestClose }) => {
  const [passwordClear, setPasswordClear] = useState(false);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validate,
    onSubmit: async (values) => {
      // console.log(values)

      try {
        const { data } = await userService.changePassword({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        });

        onRequestClose();

        auth.logout();
        auth.loginWithJwt(data.token);
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        )
          toast.error(error.response.data.error);
      }
    },
  });

  return (
    <>
      <div className="mb-3">
        <form onSubmit={formik.handleSubmit} id={formId}>
          <PasswordField
            label="Current Password"
            icon={faLock}
            classes="my-4"
            error={formik.errors.currentPassword}
            touched={formik.touched.currentPassword}
            name="currentPassword"
            value={formik.values.currentPassword}
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <PasswordField
            label="New Password"
            icon={faLock}
            classes="my-4"
            error={formik.errors.newPassword}
            touched={formik.touched.newPassword}
            name="newPassword"
            value={formik.values.newPassword}
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <PasswordField
            label="Confirm Password"
            icon={faLock}
            classes="my-4"
            error={formik.errors.confirmPassword}
            touched={formik.touched.confirmPassword}
            name="confirmPassword"
            value={formik.values.confirmPassword}
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
