// import { PhotoProvider, PhotoView } from "react-photo-view";
import { stringToCommaSeparatedNum } from "../utils/commonUtils";
import PhotoPreviewCreator from "../components/common/PhotoPreviewCreator";

export const ROOM_DETAIL_COLUMNS = [
  {
    Header: "System",
    accessor: "system",
  },
  // {
  //   Header: "Sub System",
  //   accessor: "subSystem",
  // },
  {
    Header: "Construction Cost",
    accessor: "constructionCost",
  },
  {
    Header: "Soft Cost",
    Footer: "Soft Cost",
    accessor: "softCost",
  },
  {
    Header: "Total",
    Footer: "Total",
    accessor: (row) => stringToCommaSeparatedNum(row.totalCost),
  },
  {
    Header: "Priority",
    accessor: "priority",
  },
  {
    Header: "Area/Unit",
    accessor: "areaUnits",
  },

  {
    Header: "Image",
    accessor: (row) => {
      if (row.imgUrls && row.imgUrls[0])
        return <PhotoPreviewCreator data={row.imgUrls} />;

      return "";
    },
  },
  // {
  //   Header: "Note",
  //   accessor: "note",
  // },
];
