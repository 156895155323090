import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faPrint } from "@fortawesome/pro-regular-svg-icons";
import { uploadOneImage } from "../utils/uploadImage";
import SVGMapInfoBlock from "./SVGMapInfoBlock";
import SchoolMapToPrint from "./SchoolMapToPrint";
import { useReactToPrint } from "react-to-print";
import Modal from "./common/Modal";
import BuildingReportToPrintWithMap from "./BuildingReportToPrintWithMap";
import SchoolMapPlaygroundMain from "./SchoolMapPlaygroundMain";

const SchoolMapPlayground = ({
  rects,
  setRects,
  mapImg,
  setMapImg,
  onClickShape,
  onClearMapImg,
  isClearMapImgDisabled,
  layers,
  activeLayerIndex,
  handleOnChangeLayer,
  activeLayer,
  setActiveLayer,
  onAddLayer,
  onAddIconClicked,
  isAddTextFormShown,
  onAddText,
  isLocked,
  onDoubleClickShape,
  rectsToPrint,
  dataId,
  onMapDataChange,
  isClickOnMapEnabled,
  setIsClickOnMapEnabled,
  onAddShape,
  clickedRectDetail,
  setClickedSVGPartID,
  onBuildingClickedForAddingInfo,
  isAddBuildingInfoEnabled,
  isAddRoomInfoEnabled,
  buildings,
  isAddSiteSystemEnabled,
  onSVGClickedForAddingSiteSystem,
  clickedBuildingInteriorArea,
}) => {
  const [isSelectBuildingToPrintOpen, setIsSelectBuildingToPrintOpen] =
    useState(false);

  const [buildingToGenerateReportFor, setBuildingToGenerateReportFor] =
    useState(null);

  const node = useRef();

  // eslint-disable-next-line no-unused-vars
  const handlePrint = useReactToPrint({
    content: () => node.current,
  });

  const onChooseFile = async (e) => {
    const { data: url } = await uploadOneImage(e.target.files[0]);
    setMapImg(url.secure_url);
  };

  const handleOnSelectBuilding = (building) => {
    setBuildingToGenerateReportFor(building);
    setIsSelectBuildingToPrintOpen(false);
    setTimeout(() => handlePrint(), 500);
  };

  if (!mapImg)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          // position: "relative",
          width: "100vw",
          height: "80vh",
          paddingLeft: "300px",
        }}
      >
        <label className="profilepic__label" htmlFor="profilepic__input">
          <input
            type="file"
            accept="image/*"
            onChange={onChooseFile}
            className="profilepic__input"
            id="profilepic__input"
          />
          <FontAwesomeIcon icon={faPaperclip} className="profilepic__icon" />
          <span className="profilepic__text">Choose a Photo</span>
        </label>
      </div>
    );

  return (
    <>
      <div className="school-map__playground">
        <div className="school-map__playground__TheMap position-relative">
          <SchoolMapToPrint ref={node}>
            <>
              <div className="schoo-map__palayground-main">
                <SchoolMapPlaygroundMain
                  nodes={rectsToPrint}
                  nodesChangesTracker={rects}
                  setNodesChangesTracker={setRects}
                  onMapDataChange={onMapDataChange}
                  onClickShape={onClickShape}
                  onDoubleClickShape={onDoubleClickShape}
                  isClickOnMapEnabled={isClickOnMapEnabled}
                  setIsClickOnMapEnabled={setIsClickOnMapEnabled}
                  onAddShape={onAddShape}
                  setClickedSVGPartID={setClickedSVGPartID}
                  onBuildingClickedForAddingInfo={
                    onBuildingClickedForAddingInfo
                  }
                  isAddBuildingInfoEnabled={isAddBuildingInfoEnabled}
                  isAddRoomInfoEnabled={isAddRoomInfoEnabled}
                  isAddSiteSystemEnabled={isAddSiteSystemEnabled}
                  onSVGClickedForAddingSiteSystem={
                    onSVGClickedForAddingSiteSystem
                  }
                />

                <BuildingReportToPrintWithMap
                  building={buildingToGenerateReportFor}
                />
              </div>
            </>
          </SchoolMapToPrint>

          <div className="schoolMap__printBtn__container">
            <div className="mb-2">
              <div
                className="schoolMap__printBtn"
                onClick={() => setIsSelectBuildingToPrintOpen(true)}
              >
                <FontAwesomeIcon icon={faPrint} fontSize={16} color="#5d6475" />
              </div>
            </div>
          </div>
        </div>

        <div className="school-map__playground__info">
          {mapImg === "Interactive" && (
            <SVGMapInfoBlock
              dataId={dataId}
              clickedRectDetail={clickedRectDetail}
              onPrint={() => setIsSelectBuildingToPrintOpen(true)}
              clickedBuildingInteriorArea={clickedBuildingInteriorArea}
            />
          )}
        </div>
      </div>

      <Modal
        isOpen={isSelectBuildingToPrintOpen}
        onRequestClose={() => setIsSelectBuildingToPrintOpen(false)}
        width={600}
      >
        <div
          style={{ height: "83vh", overflowY: "auto" }}
          className="p-3 my-2 mx-1"
        >
          <div className="d-flex flex-column align-items-center">
            <h6 className="mb-3">Select a building to generate report</h6>
            <div style={{ width: "250px" }}>
              {buildings &&
                buildings?.map((b) => (
                  <div
                    key={b._id}
                    className="school-map__resource-item"
                    onClick={() => handleOnSelectBuilding(b)}
                  >
                    {b.buildingName}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SchoolMapPlayground;

// SchoolMapPlayground
