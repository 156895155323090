import React from "react";

const TableBody = ({
  getTableBodyProps,
  page,
  prepareRow,
  onRowClicked,
  total = false,
}) => {
  return (
    <tbody {...getTableBodyProps()} className="border-0">
      {page.map((row, index) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            onClick={() => onRowClicked(row.original, index)}
            role="button"
            className={
              total
                ? "border-b-2 border-300 last:border-b-0 last:font-bold bg-white"
                : "border-b-2 border-300 last:border-b-0 bg-white"
            }
          >
            {row.cells.map((cell) => (
              <td
                className="border border-300  text-center pl-2"
                {...cell.getCellProps()}
              >
                {" "}
                {cell.render("Cell")}{" "}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
