import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

// const apiEndPoint = config.apiEndPoint + "/sites";

export const saveSite = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(http.put(`${config.apiEndPoint1}/sites/${id}`, data), {
      pending: "Updating Site...",
      success: "Site Updated Successfully!",
      error: "Failed, please try again.",
    });
  }

  return toast.promise(http.post(config.apiEndPoint1 + "/sites", data), {
    pending: "Adding Site...",
    success: "Site Added Successfully!",
    error: "Failed, please try again.",
  });
};

export const getSites = () => {
  return http.get(config.apiEndPoint1 + "/sites");
};
export const getSitesByDistrict = (districtID) => {
  return http.get(`${config.apiEndPoint1}/sites/${districtID}/allsites`);
};

export const deleteSite = (id) => {
  return toast.promise(http.delete(`${config.apiEndPoint1}/sites/${id}`), {
    pending: "Deleting Site...",
    success: "Site Deleted Successfully!",
    error: "Site has already been deleted",
  });
};

export const getSiteById = (siteID) => {
  return http.get(`${config.apiEndPoint1}/sites/${siteID}`);
};

export const getPreloadData = () => {
  return http.get(config.apiEndPoint1 + "/sitepreload/sitepreloads");
};

export const updatePreload = (data, id) => {
  // return http.put(`${config.apiEndPoint1}/buildingpreload/${id}`);
  return toast.promise(
    http.put(`${config.apiEndPoint1}/sitepreload/${id}`, data),
    {
      pending: "Updating Preload...",
      success: "Preload Updated Successfully!",
      error: "Failed, please try again.",
    }
  );
};

export const getSitesCount = () => {
  return http.get(config.apiEndPoint1 + "/sites/count");
};

export const getSiteSummary = (siteID) => {
  return http.get(config.apiEndPoint1 + "/sites/" + siteID + "/sitesummary");
};

const siteService = {
  saveSite,
  getSites,
  getSitesByDistrict,
  deleteSite,
  getSiteById,
  getPreloadData,
  updatePreload,
  getSitesCount,
  getSiteSummary,
};

export default siteService;
