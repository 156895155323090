import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

// const apiEndPoint = config.apiEndPoint1 + "/users";

export const register = (user) => {
  return toast.promise(
    http.post(config.apiEndPoint1 + "/auth/register", user),
    {
      pending: "Registering User...",
      success: "Registered Successfully!",
      error: "Failed, please try again.",
    }
  );
};

export const update = (data) => {
  return toast.promise(
    http.put(config.apiEndPoint1 + "/auth/updatedetails", data),
    {
      pending: "Updating User...",
      success: "Successfully Updated!",
      error: "Failed, please try again.",
    }
  );
};

export const changePassword = (data) => {
  return toast.promise(
    http.put(config.apiEndPoint1 + "/auth/updatepassword", data),
    {
      pending: "Updating Password...",
      success: "Successfully Updated!",
      error: "Failed, please try again.",
    }
  );
};

export const saveOrgUser = (data, id) => {
  if (id) {
    return toast.promise(http.put(`${config.apiEndPoint1}/users/${id}`, data), {
      pending: "Updating User...",
      success: "Successfully Updated!",
      error: "Failed, please try again.",
    });
  }

  return toast.promise(http.post(config.apiEndPoint1 + "/users", data), {
    pending: "Creating User...",
    success: "Successfully Created!",
    error: "Failed, please try again.",
  });
};

export const getOrgUsers = () => {
  return http.get(config.apiEndPoint1 + "/users/orgusers");
};

export const deleteOrgUser = (id) => {
  return toast.promise(http.delete(config.apiEndPoint1 + "/users/" + id), {
    pending: "Delete User...",
    success: "Successfully Deleted!",
    error: "Failed, please try again.",
  });
};

const userService = {
  register,
  update,
  changePassword,
  saveOrgUser,
  getOrgUsers,
  deleteOrgUser,
};

export default userService;
