export const DISTRICT_CAPACITY_COLUMNS = [
  {
    Header: "Schools",
    accessor: "schoolName",
  },
  {
    Header: "Current Enrollment",
    accessor: "currentEnrollment",
  },
  {
    Header: "State Classroom Count",
    accessor: "stateLoadingClassrooms",
  },
  {
    Header: "State Capacity",
    accessor: "stateCapacity",
  },
  {
    Header: "% of State Capacity",
    accessor: "stateCapacityPercentage",
  },
  {
    Header: "District Classroom Count",
    accessor: "districtLoadingClassrooms",
  },
  {
    Header: "District Capacity",
    accessor: "districtCapacity",
  },
  {
    Header: "Classrooms Excluded",
    accessor: "excludedClassrooms",
  },
  {
    Header: "% of State Capacity",
    accessor: "districtCapacityPercentage",
  },
];
