import React from "react";
import Navbar from "../Navbar";
import SideMenu from "./SideMenu";

const DistrictUserDashboardLayout = ({
  user,
  sites,
  handleOnSelect,
  selected,
  selectedSchoolObj,
  children,
  onCreateReport,
}) => {
  return (
    <>
      <Navbar user={user} />
      {sites && (
        <div
          className="d-flex"
          style={{
            backgroundColor: "#F8F9FC",
            // height: "86vh",
            // overflowY: "auto",
          }}
        >
          <SideMenu
            handleOnSelect={handleOnSelect}
            selected={selected}
            selectedSchoolObj={selectedSchoolObj}
            sites={sites}
            onCreateReport={onCreateReport}
          />

          {children}
        </div>
      )}
    </>
  );
};

export default DistrictUserDashboardLayout;
