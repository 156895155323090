import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { faEnvelope, faUser, faLock } from "@fortawesome/pro-regular-svg-icons";
import IconInput from "../IconInput";
import { toast } from "react-toastify";
import auth from "../../services/authService";
import districtService from "../../services/districtService";
import SelectDistrict from "../SelectDistrict";
import userService from "../../services/userService";
import PasswordField from "../common/PasswordField";

// Validation Schema
const validate = Yup.object({
  fullName: Yup.string()
    .max(30, "Must be 30 characters or less")
    .min(3, "Must be 3 characters or more")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(6, "Must be 6 characters or more")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  districtID: Yup.string().required(),
});

const initialValues = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  districtID: "",
};

const AddUser = ({ formId, data, saveUser, onRequestClose }) => {
  const [passwordClear, setPasswordClear] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);

  async function loadData() {
    try {
      const { data: res } = await districtService.getDistricts();
      setDistricts(res.data);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      )
        toast.error(error.response.data.error);
      else toast.error("Something went wrong while loading districts.");
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const formData = data && {
    fullName: data.name,
    email: data.email,
    password: "",
    confirmPassword: "",
    districtID: data.districtID,
  };

  const formik = useFormik({
    initialValues: data ? formData : initialValues,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit,
  });

  async function onSubmit(values) {
    try {
      const id = data ? data._id : null;
      const userData = {
        name: values.fullName,
        email: values.email,
        password: values.password,
        adminID: auth.getCurrentUser().id,
        districtID: values.districtID,
      };

      await saveUser(userData, id);
      onRequestClose();
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      )
        toast.error(error.response.data.error);

      if (error.response) {
        toast.error(error.message);
      }
    }
  }

  const handleOnEmailBlur = async (e) => {
    formik.handleChange(e);
    const email = e.target.value.trim();
    const { data: res } = await userService.getOrgUsers();
    const result = res?.data.some((user) => {
      return user.email === email;
    });

    if (result) {
      toast.error("Email already taken!");
      setEmailError("Email already taken!");
      return setEmailTouched(true);
      // "email", "Email exist, please enter another email"
    }

    setEmailError("");
  };

  return (
    <div className="overflow-y-scroll">
      <form onSubmit={formik.handleSubmit} id={formId} className="space-y-1">
        <IconInput
          label="Full Name"
          icon={faUser}
          classes="my-4"
          error={formik.errors.fullName}
          touched={formik.touched.fullName}
          type="text"
          name="fullName"
          value={formik.values.fullName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <IconInput
          label="Work Email"
          icon={faEnvelope}
          classes="my-4"
          error={emailError ? emailError : formik.errors.email}
          touched={emailTouched}
          type="text"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={handleOnEmailBlur}
        />

        <PasswordField
          label="Password"
          icon={faLock}
          classes="mb-custom-3"
          error={formik.errors.password}
          touched={formik.touched.password}
          name="password"
          value={formik.values.password}
          passwordClear={passwordClear}
          setPasswordClear={setPasswordClear}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <PasswordField
          label="Confirm Password"
          icon={faLock}
          classes="mb-custom-3"
          error={formik.errors.confirmPassword}
          touched={formik.touched.confirmPassword}
          name="confirmPassword"
          value={formik.values.confirmPassword}
          passwordClear={passwordClear}
          setPasswordClear={setPasswordClear}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="my-2"></div>
        <SelectDistrict
          options={districts}
          label="District"
          name="districtID"
          classes="mb-custom-3"
          error={formik.errors.districtID}
          touched={formik.touched.districtID}
          value={formik.values.districtID}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={districts.length === 0}
        />
      </form>
    </div>
  );
};

export default AddUser;
