import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileChartPie } from "@fortawesome/pro-regular-svg-icons";
import {
  getRoomsBySite,
  getRoomsCapacityBySite,
} from "../services/roomService";
import { ROOM_COLUMNS } from "../data/roomColumns";
import { ROOM_CAPACITY_COLUMNS } from "../data/roomCapacityColumn";
import Table from "./common/Table";

//  const { data: res1 } = await buildingService.getBuildingsBySite(
//    fieldsValue.site
//  );

const CapacityReport = ({ user, siteID }) => {
  const [rooms, setRooms] = useState([]);
  const [capacities, setCapacity] = useState([]);
  useEffect(() => {
    const fetchRooms = async () => {
      const { data } = await getRoomsBySite(siteID);
      setRooms(data.data);
    };
    const fetchCapacity = async () => {
      const { data } = await getRoomsCapacityBySite(siteID);
      setCapacity(data.data);
    };
    fetchRooms();
    fetchCapacity();
  }, [siteID]);
  return (
    <div className="d-flex flex-column" style={{ overflowY: "hidden" }}>
      <div className="mx-8 mb-4">
        <div className="flex flex-row justify-between text-center my-4 ml-2">
          <span className="w-40">{rooms[0]?.schoolName}</span>

          <span>
            <button
              className="flex justify-center bg-700 text-white p-2 px-3 rounded-md ml-2 text-sm"
              // onClick={onPrint}
            >
              <span>
                <FontAwesomeIcon icon={faFileChartPie} fontSize="18px" />
              </span>
              <span className="ml-2">Generate Report</span>
            </button>
          </span>
        </div>
        <Table tableData={rooms} tableColumns={ROOM_COLUMNS} />
        <div className="font-Rubik font-semibold my-2 w-full h-full text-center">
          SUMMARY
        </div>
        <Table tableData={capacities} tableColumns={ROOM_CAPACITY_COLUMNS} />
      </div>
    </div>
  );
};

export default CapacityReport;
