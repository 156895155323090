import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCity } from "@fortawesome/pro-regular-svg-icons";

const SchoolMapSelectEntityType = ({
  shapeLinkEntityType,
  setShapeLinkEntityType,
  isForLink,
}) => {
  // if (isForLink) setShapeLinkEntityType("Site System");

  return (
    <>
      {shapeLinkEntityType === "" && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "85vh" }}
        >
          <div>
            <h6 style={{ color: "#4f4f4f" }}>
              What type of entity do you want to link your shape to ?
            </h6>
            <br />
            <div className="d-flex justify-content-center">
              <button
                onClick={() => setShapeLinkEntityType("Site System")}
                className="btn btn-custom-primary"
              >
                <FontAwesomeIcon icon={faCity} /> &nbsp; Site System
              </button>
              <button
                onClick={() => setShapeLinkEntityType("building")}
                className="btn btn-custom-primary mx-2"
              >
                <FontAwesomeIcon icon={faBuilding} /> &nbsp; Building
              </button>
              <button
                onClick={() => setShapeLinkEntityType("room")}
                className="btn btn-custom-primary mx-2"
              >
                <FontAwesomeIcon icon={faBuilding} /> &nbsp; Room
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SchoolMapSelectEntityType;
