import React from "react";
import DoughnutChart from "../Charts/DoughnutChart";
import PieChart from "../Charts/PieChart";

const DashboardCharts = ({ siteSummary, isForPrint }) => {
  return (
    <div className="dashboard__chartsContainer">
      <div className="charts__container">
        <div className={isForPrint ? "chart__item__print" : "chart__item"}>
          <PieChart
            title="Costs"
            labels={["Modernization Cost", "New Construction Cost"]}
            backgroundColor={["rgba(255, 99, 132, 1)", "rgba(255, 159, 64, 1)"]}
            data={
              siteSummary
                ? [
                    siteSummary.modernizationCost,
                    siteSummary.newConstructionCost,
                  ]
                : [0, 0]
            }
          />
        </div>

        <div className={isForPrint ? "chart__item__print" : "chart__item"}>
          <DoughnutChart
            title="Costs"
            labels={[
              "Site Systems",
              "Exterior Systems",
              "Interior Systems",
              "New Construction",
            ]}
            backgroundColor={[
              "rgba(255, 99, 132, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 159, 64, 1)",
            ]}
            data={
              siteSummary
                ? [
                    siteSummary.siteSystemData.total,
                    siteSummary.exteriorBuildingSystems.total,
                    siteSummary.interiorBuildingSystems,
                    siteSummary.newConstructionCost,
                  ]
                : [0, 0, 0, 0]
            }
          />
        </div>

        {siteSummary && (
          <div className={isForPrint ? "chart__item__print" : "chart__item"}>
            <DoughnutChart
              title="Site Systems"
              labels={getSiteSystemsLabels(siteSummary)}
              backgroundColor={[
                "rgba(255, 99, 132, 1)",
                "rgba(255, 205, 86, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(90, 89, 64, 1)",
                "rgba(50, 244, 19, 1)",
                "rgba(80, 250, 200, 1)",
                "rgba(154, 200, 180, 1)",
                "rgba(167, 89, 89, 1)",
                "rgba(210, 89, 213, 1)",
              ]}
              data={getSiteSystemsValues(siteSummary)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

function getSiteSystemsLabels(siteSummary) {
  return Object.keys(getSiteSystems(siteSummary));
}

function getSiteSystemsValues(siteSummary) {
  return Object.values(getSiteSystems(siteSummary));
}

function getSiteSystems(siteSummary) {
  const copy = { ...siteSummary.siteSystemData };
  delete copy.total;
  return copy;
}

export default DashboardCharts;
