export const ROOM_CAPACITY_COLUMNS = [
  {
    Header: "School Capacity Analysis",
    accessor: "capacityAnalysis",
  },
  {
    Header: "Classroom sqft",
    accessor: "classroomsSqft",
    Cell: ({ value }) => {
      const numberValue = Number(value); // Convert to number if it's a string
      return numberValue.toLocaleString(); // Convert to local string format
    },
  },
  {
    Header: "Classrooms Count",
    accessor: "classrooms",
  },
  {
    Header: "Site Capacity",
    accessor: "capacity",
    Cell: ({ value }) => {
      const numberValue = Number(value); // Convert to number if it's a string
      return numberValue.toLocaleString(); // Convert to local string format
    },
  },
];
