import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

// const apiEndPoint = config.apiEndPoint + "/buildings";

export const saveBuildingSystem = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(
      http.put(`${config.apiEndPoint1}/buildingsystems/${id}`, data),
      {
        pending: "Updating Building System...",
        success: "Building System Updated Successfully!",
        error: "Failed, please try again.",
      }
    );
  }

  return toast.promise(
    http.post(`${config.apiEndPoint1}/buildingsystems`, data),
    {
      pending: "Adding Building System...",
      success: "Building Information Added Successfully!",
      error: "Failed, please try again.",
    }
  );
};

export const getBuildingSystem = () => {
  return http.get(config.apiEndPoint1 + "/buildingsystems");
};

export const getBuildingSystemsByBuilding = (buildingID) => {
  return http.get(
    `${config.apiEndPoint1}/buildingsystems/${buildingID}/allbuildingsystems`
  );
};

export const deleteBuildingSystem = (id) => {
  return toast.promise(
    http.delete(`${config.apiEndPoint1}/buildingsystems/${id}`),
    {
      pending: "Deleting Building system...",
      success: "Building system Deleted Successfully!",
      error: "Building system has already been deleted",
    }
  );
};

export const getBuildingSystemByID = (id) => {
  return http.get(`${config.apiEndPoint1}/buildingsystems/${id}`);
};

const buildingSystemService = {
  saveBuildingSystem,
  getBuildingSystem,
  deleteBuildingSystem,
  getBuildingSystemsByBuilding,
  getBuildingSystemByID,
};

export default buildingSystemService;
