import React, { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { toast } from "react-toastify";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userService from "../services/userService";

import {
  base64StringtoFile,
  extractImageFileExtensionFromBase64,
  image64toCanvasRef,
} from "../utils/resuableUnits";
import { uploadOneImage } from "../utils/uploadImage";

const CropProfilePicture = ({ setIsModalOpen, setUserInfo }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });

  const imgPreview = useRef(null);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const currentFile = e.target.files[0];
      const myFileItemReader = new FileReader();
      myFileItemReader.addEventListener(
        "load",
        () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          setImgSrc(myResult);
        },
        false
      );

      myFileItemReader.readAsDataURL(currentFile);
    }
  }

  function handleOnCropComplete(_, pixelCrop) {
    const canvasRef = imgPreview.current;
    image64toCanvasRef(canvasRef, imgSrc, pixelCrop);
  }

  const handleUploadClick = async (event) => {
    event.preventDefault();
    if (imgSrc) {
      const canvasRef = imgPreview.current;
      const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
      const imageData64 = canvasRef.toDataURL("image/" + fileExtension);

      const myFileName = "previewFile" + fileExtension;

      // file to be uploaded
      const myNewCroppedFile = base64StringtoFile(imageData64, myFileName);
      // console.log(myNewCroppedFile);

      try {
        const { data: url } = await uploadOneImage(myNewCroppedFile);
        // eslint-disable-next-line no-unused-vars
        const { data: res } = userService.update({ image: url.secure_url });
        setUserInfo((pre) => {
          const newUser = { ...pre };
          newUser.image = url.secure_url;
          return newUser;
        });
        // console.log("URL: ", url.secure_url);
        // console.log("User: ", res.data);
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        )
          toast.error(error.response.data.error);
      }
      setIsModalOpen(false);
    }
  };

  function handleClearToDefault(event) {
    if (event) event.preventDefault();
    const canvasRef = imgPreview.current;
    const ctx = canvasRef.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);

    setImgSrc(null);
    setCrop({ aspect: 1 / 1 });
  }

  return (
    <div className="cropper-container">
      <div className="cropper">
        {!imgSrc ? (
          <div className="position-relative">
            <label className="profilepic__label" htmlFor="profilepic__input">
              <input
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                className="profilepic__input"
                id="profilepic__input"
              />
              <FontAwesomeIcon
                icon={faPaperclip}
                className="profilepic__icon"
              />
              <span className="profilepic__text">Choose a Photo</span>
            </label>
          </div>
        ) : (
          <ReactCrop
            src={imgSrc}
            onComplete={handleOnCropComplete}
            crop={crop}
            onChange={(c) => setCrop(c)}
            style={{
              height: "300px",
            }}
          />
        )}
      </div>

      <div className="d-flex flex-column gap-2 px-5 mx-5">
        <button
          className="crop-profile-btn crop-profile-upload-btn"
          onClick={imgSrc ? handleUploadClick : () => {}}
          disabled={imgSrc ? false : true}
        >
          Upload
        </button>
        <button
          className="crop-profile-btn crop-profile-btn-clear"
          onClick={imgSrc ? handleClearToDefault : () => {}}
          disabled={imgSrc ? false : true}
        >
          Clear
        </button>
      </div>

      <div
        style={{
          display: "none",
        }}
      >
        <canvas ref={imgPreview}></canvas>
      </div>
    </div>
  );
};

export default CropProfilePicture;
