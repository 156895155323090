// import http from "../services/httpService";
import axios from "axios";
import config from "../config/config.json";
import { toast } from "react-toastify";

export const uploadImage = async (selectedImages) => {
  const imagesUrls = [];

  if (!selectedImages) return console.log("Select Images First");

  Object.values(selectedImages).map(async (selectedImage) => {
    const data = new FormData();
    data.append("file", selectedImage);
    data.append("upload_preset", "schoolfacilitypro");

    try {
      //   const { data: res_data } = await http.post(config.cloudinaryUrl, data);

      const { data: res_data } = await toast.promise(
        axios.post(config.cloudinaryUrl, data),
        {
          pending: `Uploading Image: ${selectedImage.name}`,
          success: "Uploaded Successfully",
          error: "Uploading failed, please try again.",
        }
      );

      imagesUrls.push(res_data.secure_url);
      // console.log(res_data.secure_url);
    } catch (error) {
      if (error.response) console.log(error.response.data.error.message);
    }
  });

  return imagesUrls;
};

export const uploadOneImage = async (image) => {
  if (!image) return console.log("Select Images First");

  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", "schoolfacilitypro");

  try {
    return toast.promise(axios.post(config.cloudinaryUrl, data), {
      pending: `Uploading Image: ${image.name}`,
      success: "Uploaded Successfully",
      error: "Uploading failed, please try again.",
    });
  } catch (error) {
    if (error.response) console.log(error.response.data.error.message);
  }
};
