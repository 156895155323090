import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

export const saveRoomDetail = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(
      http.put(`${config.apiEndPoint1}/roomdetails/${id}`, data),
      {
        pending: "Updating Room Detail...",
        success: "Room Detail Updated Successfully!",
        error: "Failed, please try again.",
      }
    );
  }

  return toast.promise(http.post(config.apiEndPoint1 + "/roomdetails", data), {
    pending: "Adding Room...",
    success: "Room detail Added Successfully!",
    error: "Failed, please try again.",
  });
};

export const getRoomDetails = () => {
  return http.get(config.apiEndPoint1 + "/roomdetails");
};
export const getRoomDetailsByRoom = (roomID) => {
  return http.get(
    `${config.apiEndPoint1}/roomdetails/${roomID}/allroomdetails`
  );
};

export const deleteRoomDetail = (id) => {
  return toast.promise(
    http.delete(`${config.apiEndPoint1}/roomdetails/${id}`),
    {
      pending: "Deleting Room detail...",
      success: "Room detail Deleted Successfully!",
      error: "Room detail has already been deleted",
    }
  );
};

export const getRoomDetailById = (id) => {
  return http.get(`${config.apiEndPoint1}/roomdetails/${id}`);
};

const roomDetailService = {
  saveRoomDetail,
  getRoomDetails,
  getRoomDetailsByRoom,
  deleteRoomDetail,
  getRoomDetailById,
};

export default roomDetailService;
