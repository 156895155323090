import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

// const apiEndPoint = config.apiEndPoint + "/buildings";

export const saveSchoolMap = (data, id) => {
  if (id) {
    // const id = data._id;
    // delete data._id;
    return toast.promise(
      http.put(`${config.apiEndPoint1}/schoolmaps/${id}`, data),
      {
        pending: "Updating Map Detail...",
        success: "Map Updated Successfully!",
        error: "Failed, please try again.",
      }
    );
  }

  return toast.promise(http.post(`${config.apiEndPoint1}/schoolmaps`, data), {
    pending: "Adding Map Detail...",
    success: "Map Added Successfully!",
    error: "Failed, please try again.",
  });
};

export const getSchoolMaps = () => {
  return http.get(config.apiEndPoint1 + "/schoolmaps");
};

export const getSchoolmapBySite = (siteID) => {
  return http.get(`${config.apiEndPoint1}/schoolmaps/${siteID}/allschoolmap`);
};

export const deleteSchoolMap = (id) => {
  return toast.promise(http.delete(`${config.apiEndPoint1}/schoolmaps/${id}`), {
    pending: "Deleting Map Detail...",
    success: "Deleted Successfully!",
    error: "Map has already been deleted",
  });
};

export const getSchoolMapByID = (id) => {
  return http.get(`${config.apiEndPoint1}/schoolmaps/${id}`);
};

const schoolMapService = {
  saveSchoolMap,
  getSchoolMaps,
  deleteSchoolMap,
  getSchoolmapBySite,
  getSchoolMapByID,
};

export default schoolMapService;
