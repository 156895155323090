import React from "react";

const SideFeaturedImages = ({ images }) => {
  return (
    <div className="image-grid">
      {images && images.length > 0 ? (
        <>
          <img
            className="image-grid-col-2 image-grid-row-2"
            src={images[0]}
            alt="architecture"
          />
          {images.map((img) => (
            <img key={img} src={img} alt="" />
          ))}
        </>
      ) : (
        <h2>No Image</h2>
      )}

      {/* <img
          className="image-grid-col-2 image-grid-row-2"
          src="https://picsum.photos/400"
          alt="architecture"
        /> */}

      {/* <img src="https://picsum.photos/400" alt="architecture" />
    <img src="https://picsum.photos/400" alt="architecture" />
    <img src="https://picsum.photos/400" alt="architecture" />
    <img src="https://picsum.photos/400" alt="architecture" /> */}
    </div>
  );
};

export default SideFeaturedImages;
