import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

// const apiEndPoint = config.apiEndPoint + "/sitedetails";

export const saveNewConstruction = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(
      http.put(`${config.apiEndPoint1}/newconstructions/${id}`, data),
      {
        pending: "Updating New Construction...",
        success: "New Construction Updated Successfully!",
        error: "Failed, please try again.",
      }
    );
  }

  return toast.promise(
    http.post(config.apiEndPoint1 + "/newconstructions", data),
    {
      pending: "Adding New Construction...",
      success: "New Construction Added Successfully!",
      error: "Failed, please try again.",
    }
  );
};

export const getNewConstructions = () => {
  return http.get(config.apiEndPoint1 + "/newconstructions");
};

export const getNewConstructionsBySite = (siteID) => {
  return http.get(
    `${config.apiEndPoint1}/newconstructions/${siteID}/allnewconstructions`
  );
};

export const deleteNewConstruction = (id) => {
  return toast.promise(
    http.delete(`${config.apiEndPoint1}/newconstructions/${id}`),
    {
      pending: "Deleting New Construction...",
      success: "New Construction Deleted Successfully!",
      error: "New Construction has already been deleted",
    }
  );
};

export const getNewConstructionById = (id) => {
  return http.get(`${config.apiEndPoint1}/newconstructions/${id}`);
};

const newConstructionService = {
  saveNewConstruction,
  getNewConstructions,
  getNewConstructionsBySite,
  deleteNewConstruction,
  getNewConstructionById,
};

export default newConstructionService;
