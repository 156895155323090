import React from "react";

const AssessmentDetail = ({ siteSummary, children }) => {
  if (!siteSummary) return <div className="m-5">Loading...</div>;

  return (
    <>
      <div
        className="w-80 text-custom-secondary"
        style={{
          height: "90vh",
          overflowY: "auto",
          width: "80vw",
          flexGrow: 10,
          backgroundColor: "#fff",
          borderLeft: "1px solid #efefef",
          // borderRadius: "5px",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default AssessmentDetail;
