import React from "react";

function Button({ classes, children, marginClass, type }) {
  return (
    <div className={`d-grid col-12 mx-auto ${marginClass}`}>
      <button className={`btn btn-custom ${classes}`} type={type || "button"}>
        <div className="d-flex align-items-center justify-content-evenly">
          {children}
        </div>
      </button>
    </div>
  );
}

export default Button;
