export const GRADE_LEVELS = [
  "PS",
  "TK",
  "K",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "SDC",
  "CH",
  "Other",
];
