import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function IconInput({
  label,
  classes,
  icon,
  iconClasses,
  error,
  touched,
  ...props
}) {
  return (
    <div className={classes}>
      {/* <label className="form-label mx-1">{label}</label> */}
      <div
        className={`icon-input d-flex px-3 justify-content-space-between align-items-center bg-white rounded-md`}
      >
        <FontAwesomeIcon
          icon={icon}
          className={`${iconClasses} text-secondary`}
          fontSize="16px"
        />
        <input
          {...props}
          className="input-control normalize-text bg-white"
          placeholder={label}
          autoComplete="off"
        />
      </div>

      <div className="normalize-text text-danger px-2 error-text">
        {touched && error ? error : " "}
      </div>
    </div>
  );
}

export default IconInput;
