import React, { useState, useRef, useEffect } from "react";
import {
  faAngleDown,
  faTableColumns,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import DropdownList from "./DropdownList";

const TableColumnsDropdown = ({ label, columns, disabled }) => {
  const [isActive, setIsActive] = useState(false);

  const node = useRef();

  // Track events outside scope
  const clickOutside = (e) => {
    if (node && node.current && !node.current.contains(e.target)) {
      // outside click
      setIsActive(false);
      return;
    }
    // inside click
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);

    // clean up function before running new effect
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  return (
    <div className="custom-dropdown-1">
      <div
        // className={`custom-dropdown-btn-1`}
        className="focus:border-700 border-2 border-tb-color rounded-md px-2 py-1 text-700"
        onClick={() => setIsActive(!isActive)}
      >
        <span className="text-base">
          <span style={{ marginRight: "0.6rem" }}>
            <FontAwesomeIcon icon={faTableColumns} />
          </span>
          {label}
        </span>
        &nbsp; &nbsp;
        <span>
          <FontAwesomeIcon icon={faAngleDown} />
        </span>
      </div>

      {!disabled && isActive && columns && (
        <div ref={node}>
          <div className="custom-dropdown-content">
            {columns.map((column) => (
              <div
                key={column.id}
                // onClick={() => {
                //   setIsActive(false);
                // }}
                className="custom-dropdown-item"
              >
                {/* <span>{column}</span> */}
                <label>
                  <input type="checkbox" {...column.getToggleHiddenProps()} />
                  &nbsp; &nbsp; &nbsp;
                  {column.Header}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TableColumnsDropdown;
