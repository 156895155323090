import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import { toast } from "react-toastify";
import roomService from "./../services/roomService";

const SchoolMapSelectEntity = ({
  shapeLinkEntityType,
  options,
  handleOnEntitySelect,
  selectedShapes,
  title,
  idKey,
  valueKey,
  secondaryValueKey,
  handleOnSelectEntitySVGPart,
}) => {
  const [rooms, setRooms] = useState([]);
  const [roomsBuilding, setRoomsBuilding] = useState("");

  const loadResource = useCallback(async () => {
    if (shapeLinkEntityType === "room" && !!roomsBuilding) {
      try {
        const { data: res } = await roomService.getRoomsByBuilding(
          roomsBuilding
        );
        setRooms(res.data);
      } catch (ex) {
        setRoomsBuilding("");
        toast.error(
          "Something went wrong please check your internet connection and try again!"
        );
      }
    }
  }, [roomsBuilding, shapeLinkEntityType]);

  useEffect(() => {
    loadResource();
  }, [loadResource]);

  const getOptions = () => {
    if (shapeLinkEntityType === "room" && !!roomsBuilding) return rooms;

    return options;
  };

  if (shapeLinkEntityType === "") return null;

  return (
    <div
      className="mx-1 my-2"
      style={{ height: "82vh", color: "#5f5f5f", overflow: "auto" }}
    >
      <div className="school-map__entity-select-container">
        <h5 style={{ textAlign: "center" }}>{title + shapeLinkEntityType}</h5>
        {getOptions().map((record) => (
          <div
            key={record[idKey]}
            onClick={() => {
              if (shapeLinkEntityType === "room" && !roomsBuilding)
                return setRoomsBuilding(record[idKey]);

              if (handleOnEntitySelect)
                handleOnEntitySelect(record._id, selectedShapes[0]?.name);

              if (handleOnSelectEntitySVGPart)
                handleOnSelectEntitySVGPart(record._id);
            }}
            className="school-map__resource-item"
          >
            {record[valueKey] ? record[valueKey] : record[secondaryValueKey]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SchoolMapSelectEntity;
