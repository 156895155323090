export const ROOM_COLUMNS = [
  {
    Header: "Room Number",
    accessor: "roomNumber",
  },
  {
    Header: "Room Usage",
    accessor: "roomUsage",
  },
  {
    Header: "OPSC Code",
    accessor: "OPSCCode",
  },
  {
    Header: "Grade Level",
    accessor: "gradeLevel",
  },
  {
    Header: "Interior sqft",
    accessor: "interiorSquarFeet",
    Cell: ({ value }) => {
      const numberValue = Number(value); // Convert to number if it's a string
      return numberValue.toLocaleString(); // Convert to local string format
    },
  },
  {
    Header: "District Loading",
    accessor: "districtLoading",
  },
  {
    Header: "State Loading",
    accessor: "stateLoading",
  },
  {
    Header: "Exclude From Capacity",
    accessor: "exclude",
    Cell: ({ value }) => (value ? "Yes" : "No"),
  },

  // {
  //   Header: "School",
  //   Footer: "School",
  //   accessor: "schoolName",
  // },
  // {
  //   Header: "Building",
  //   Footer: "Building",
  //   accessor: "buildingName",
  // },
  // {
  //   Header: "System",
  //   Footer: "System",
  //   accessor: "system",
  // },
  // {
  //   Header: "Sub System",
  //   Footer: "Sub System",
  //   accessor: "subSystem",
  // },
  // {
  //   Header: "Action",
  //   Footer: "Action",
  //   accessor: "action",
  // },
  // {
  //   Header: "Action Item",
  //   Footer: "Action Item",
  //   accessor: "actionItem",
  // },
  // {
  //   Header: "Note",
  //   Footer: "Note",
  //   accessor: "note",
  // },
  // {
  //   Header: "Area/Units",
  //   Footer: "Area/Units",
  //   accessor: "areaUnits",
  // },
  // {
  //   Header: "Cost",
  //   Footer: "Cost",
  //   accessor: "constructionCost",
  // },
  // {
  //   Header: "Soft Cost",
  //   Footer: "Soft Cost",
  //   accessor: "softCost",
  // },
  // {
  //   Header: "Total",
  //   Footer: "Total",
  //   accessor: (row) => stringToCommaSeparatedNum(row.totalCost),
  // },
  // {
  //   Header: "Priority",
  //   Footer: "Priority",
  //   accessor: "priority",
  // },
];

// export const HIDDEN_COLUMNS = [
//   "note",
//   "areaUnits",
//   "constructionCost",
//   "priority",
// ];
