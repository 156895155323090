import React from "react";

export const NoteContext = React.createContext({
  currentNote: "",
  setCurrentNote: (note) => {
    console.log(note);
  },
  isNoteOpen: false,
  setIsNoteOpen: (value) => {
    console.log(value);
  },
});
