import { faFolderOpen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useCallback } from "react";
import siteDetailService from "../services/siteDetailService";
import SiteDetailItem from "./SiteDetailItem";

const SiteDetails = ({ siteID, data, onEdit, onDelete }) => {
  const [siteDetails, setSiteDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadResources = useCallback(async () => {
    if (!siteID) {
      setIsLoading(false);
      return;
    }

    const { data: res } = await siteDetailService.getSiteDetailsBySite(siteID);
    // console.log(res);
    setSiteDetails(res.data);
    setIsLoading(false);
  }, [siteID]);

  useEffect(() => {
    loadResources();
  }, [loadResources]);

  const printDetails = (data) => {
    const arr = [];
    data.forEach((siteDetail) => {
      if (siteDetail.system && siteDetail?.details?.length === 0) {
        arr.push(
          <SiteDetailItem siteDetail={siteDetail} key={siteDetail._id} />
        );
      } else {
        siteDetail.details.forEach((detail) => {
          arr.push(<SiteDetailItem siteDetail={detail} key={detail?._id} />);
        });
      }
    });

    return arr;
  };

  const printDetailsArray = (data) => {
    // data.forEach((siteDetail)
    return data.map((record, index) => (
      <SiteDetailItem
        siteDetail={record}
        key={record._id ? record._id : record.note}
        index={index}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    ));
  };

  return (
    <>
      {/*  <div
       className="p-3"
       style={{
         overflowX: "auto",
         height: "85vh",
         margin: "5px",
       }}
     > */}
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center h-100">
          Loading...
        </div>
      )}

      {!isLoading && !data && siteDetails.length === 0 && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <h1>
            <FontAwesomeIcon
              icon={faFolderOpen}
              className="text-custom-primary"
            />{" "}
            &nbsp;No Site Details
          </h1>
        </div>
      )}

      {!isLoading && data && data.details.length === 0 && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <h1>
            <FontAwesomeIcon
              icon={faFolderOpen}
              className="text-custom-primary"
            />{" "}
            &nbsp;No Site Details
          </h1>
        </div>
      )}

      {((data && data.details.length > 0) || siteDetails.length > 0) && (
        <h1
          className="mb-4"
          style={{
            textAlign: "center",
          }}
        >
          Site Details
        </h1>
      )}

      {siteDetails.length > 0 && printDetails(siteDetails)}

      {data && data.details.length > 0 && printDetailsArray(data.details)}
      {/*  </div> */}
    </>
  );
};

export default SiteDetails;
