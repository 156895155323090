import create from "zustand";

export const useStore = create((set) => ({
  roomArea: 0, // replace 0 with your initial area number
  setRoomArea: (number) => set({ roomArea: number }),
  buildingRoofArea: 0,
  setBuildingRoofArea: (number) => set({ buildingRoofArea: number }),
  buildingInteriorArea: 0,
  setBuildingInteriorArea: (number) => set({ buildingInteriorArea: number }),
  currentBuilding: {},
  setCurrentBuilding: (building) => set({ currentBuilding: building }),
  currentRoom: {},
  setCurrentRoom: (room) => set({ currentRoom: room }),
}));
