import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faImage,
  faPenToSquare,
  faTableList,
  faTrashCan,
  faMapMarked,
  faBuilding,
  faPeopleRoof,
  faCity,
  faCircleInfo,
  faMessageLines,
  faFileChartPie,
} from "@fortawesome/pro-regular-svg-icons";
import { Route, Routes, useNavigate } from "react-router-dom";

import Table from "./common/Table";
// import FiltersNavbar from "./FiltersNavbar";
import Navbar from "./Navbar";
import DeleteDialog from "./common/DeleteDialog";
import Sidebar from "./common/Sidebar";
import { DISTRICTS_COLUMNS } from "../data/districtColumns";
import {
  deleteDistrict,
  getDistricts,
  saveDistrict,
} from "../services/districtService";
import { log } from "../services/logService";
import {
  deleteSite,
  saveSite,
  getSitesByDistrict,
} from "../services/siteService";
import { deleteBuilding, saveBuilding } from "../services/buildingService";
import {
  deleteRoom,
  getRoomsByBuilding,
  saveRoom,
} from "../services/roomService";
import { SITE_COLUMNS } from "../data/siteColumns";
import { BUILDING_COLUMNS } from "../data/buildingColumns";
import { ROOM_COLUMNS } from "../data/roomColumns";
import { BUILDING_STATISTIC_COLUMNS } from "../data/building_statics_columns";
import { BUILDING_OPSC_COLUMNS } from "../data/building_opsc_columns";
import DataOnGridLayout from "./DataOnGridLayout";
import Modal from "./common/Modal";
import ModalHeaderForForms from "./ModalHeaderForForms";
import AddSite from "./Site/AddSite";
import AddBuilding from "./Building/AddBuilding";
import AddRoom from "./Room/AddRoom";
import AddDistrict from "./District/AddDistrict";
import { getBuildingsBySite } from "../services/buildingService";
import {
  determineResourceToLoad,
  mergeSiteDetails,
} from "../utils/commonUtils";
import SideFeaturedImages from "./SideFeaturedImages";
import SiteDetails from "./SiteDetails";
import ActionBtn from "./common/ActionBtn";
import { NoteContext } from "../context/NoteContext";
import { SiteChildrenContext } from "../context/SiteChildrenContext";
import siteDetailService from "../services/siteDetailService";
import districtService from "../services/districtService";
import buildingService from "../services/buildingService";
import newConstructionService, {
  deleteNewConstruction,
} from "../services/newConstructionService";
import SiteChildren from "./SiteChildren";
import { NEW_CONSTRUCTION_COLUMNS } from "../data/newConstructionColumns";
import { SITE_DETAILS_COLUMNS } from "../data/siteDetailsColumns";
import { useContext } from "react";
import StatisticReport from "./common/StatisticReport";

const saveMethods = {
  district: saveDistrict,
  site: saveSite,
  building: saveBuilding,
  room: saveRoom,
};

const loadMethods = {
  district: getDistricts,
  site: getSitesByDistrict,
  building: getBuildingsBySite,
  room: getRoomsByBuilding,
};

const deleteMethods = {
  district: deleteDistrict,
  site: deleteSite,
  building: deleteBuilding,
  room: deleteRoom,
  newConstruction: deleteNewConstruction,
};

const resourceColumns = {
  district: DISTRICTS_COLUMNS,
  site: SITE_COLUMNS,
  building: BUILDING_COLUMNS,
  room: ROOM_COLUMNS,
  newConstruction: NEW_CONSTRUCTION_COLUMNS,
  siteDetails: SITE_DETAILS_COLUMNS,
  buildingStatistic: BUILDING_STATISTIC_COLUMNS,
};

const layouts = ["table", "grid"];

const mockModalState = {
  site: false,
  building: false,
  room: false,
  district: false,
  showSiteDetail: false,
  newConstruction: false,
};

const Home = ({ user }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [reportBuildings, setReportBuildings] = useState([]);
  const [disabledFields, setDisabledFields] = useState({
    district: false,
    site: true,
    building: true,
  });
  const [fieldsValue, setFieldsValue] = useState({
    district: "",
    site: "",
    building: "",
    statistic: "",
  });

  const [data, setData] = useState([]);

  // The site to show it's siteDetails when clicked on he details on the table
  const [siteToShowdetails, setSiteToShowdetails] = useState(null);

  const [resourceToLoad, setResourceToLoad] = useState("district");
  const [recordTodelete, setRecordTodelete] = useState(null);
  const [resourceToDelete, setResourceToDelete] = useState("");
  const [sidebarImages, setSidebarImages] = useState(null);

  // building statistic in generating report page
  const [buildingStatistic, setBuildingStatistic] = useState([]);
  // building OPSC Code in generating report page
  const [buildingOPSCReport, setBuildingOPSCReport] = useState([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [layout, setLayout] = useState(layouts[1]);

  // Site children like site details and new Construction
  const [siteDetails, setSiteDetails] = useState([]);
  const [newConstructions, setNewConstructions] = useState([]);

  const [recordToEdit, setRecordToEdit] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState({
    site: false,
    building: false,
    room: false,
    district: false,
    showSiteDetail: false,
    newConstruction: false,
  });

  const { setCurrentNote, setIsNoteOpen } = useContext(NoteContext);

  const navigate = useNavigate();

  const selectSiteReport = (site) => {
    setFieldsValue((preState) => ({
      district: preState.district,
      site,
      building: "",
      facility: "",
    }));
    setResourceToLoad(
      determineResourceToLoad({
        district: fieldsValue.district,
        site,
        building: "",
        facility: "",
      })
    );
  };

  const loadResources = useCallback(async () => {
    try {
      let param = "";
      if (resourceToLoad === "site") param = fieldsValue.district;
      if (resourceToLoad === "building") param = fieldsValue.site;
      if (resourceToLoad === "room") param = fieldsValue.building;

      const { data: res } = await loadMethods[resourceToLoad](param);
      setData(res.data);

      if (user && user.types === "district" && resourceToLoad === "district") {
        setFieldsValue({
          district: res.data[0]._id,
          site: "",
          building: "",
        });
        setDisabledFields({
          district: false,
          site: false,
          building: true,
        });

        setResourceToLoad("site");
      }
    } catch (error) {
      toast.error("Something went wrong while loading data!");
    }
  }, [
    resourceToLoad,
    fieldsValue.district,
    fieldsValue.site,
    fieldsValue.building,
    user,
  ]);

  // handling building statistic in generating report page
  const handleBuildingStatistic = (data) => {
    setBuildingStatistic(data);
  };
  const handleBuildingOPSCReport = (data) => {
    setBuildingOPSCReport(data);
  };

  const loadData = useCallback(
    async function loadData() {
      // const { data: res } = await districtService.getDistricts();
      // setDistricts(res.data);

      // if (fieldsValue.district) {
      //   const { data: res1 } = await siteService.getSitesByDistrict(
      //     fieldsValue.district
      //   );
      //   setSites(res1.data);
      // }

      if (fieldsValue.site) {
        const { data: res1 } = await buildingService.getBuildingsBySite(
          fieldsValue.site
        );
        // building statistic for site
        const { data: res2 } = await buildingService.getBuildingStatistic(
          fieldsValue.site
        );
        const { data: res3 } = await buildingService.getBuildingOPSCReport(
          fieldsValue.site
        );
        // building OPSC Code
        // setBuildings(res1.data);

        setReportBuildings(res1.data);
        handleBuildingStatistic(res2.data);
        handleBuildingOPSCReport(res3.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldsValue, data]
  );

  useEffect(() => {
    loadResources();
  }, [loadResources]);

  useEffect(() => {
    loadData();
  }, [fieldsValue, loadData]);

  useEffect(() => {
    const site = query.get("site");
    if (site) {
      selectSiteReport(site);
    }
  }, []);

  const loadSiteChildren = useCallback(async () => {
    if (!fieldsValue.site) return;

    const { data: res1 } = await siteDetailService.getSiteDetailsBySite(
      fieldsValue.site
    );
    setSiteDetails(res1.data);
  }, [fieldsValue.site]);

  useEffect(() => {
    loadSiteChildren();
  }, [loadSiteChildren]);

  const saveData = async (formData, type, recordId) => {
    try {
      let { data: newRecord } = await saveMethods[type](formData);
      newRecord = newRecord.data;

      let newData = [...data];
      if (recordId) {
        newData = newData.map((d) => (recordId === d._id ? newRecord : d));
      } else newData.push(newRecord);

      if (resourceToLoad === "district") return loadResources();

      setData(newData);

      return newRecord;
    } catch (error) {
      log(error);
      toast.error("something went wrong");
    }
  };

  const getTableColumns = (resource, hideDeleteBtn, hideEditBtn) => {
    let columns = [
      ...resourceColumns[resource],
      {
        Header: "Actions",
        Footer: "",
        id: "actions",
        accessor: (row) =>
          getActionsButtons(row, resource, hideDeleteBtn, hideEditBtn),
      },
    ];

    if (resourceToLoad === "district") {
      columns[0] = {
        ...columns[0],
        accessor: (row) => {
          return (
            <span
              role="button"
              onClick={() => {
                setFieldsValue({
                  district: row._id,
                  site: "",
                  building: "",
                });
                setDisabledFields({
                  district: false,
                  site: false,
                  building: true,
                });

                setResourceToLoad(
                  determineResourceToLoad({
                    district: row._id,
                    site: "",
                    building: "",
                  })
                );
              }}
            >
              {row.name}
            </span>
          );
        },
      };
    }

    return columns;
  };

  const getActionsButtons = (record, resource, hideDeleteBtn, hideEditBtn) => {
    return (
      <>
        {resourceToLoad === "site" && (
          <ActionBtn
            icon={faCircleInfo}
            onClick={(e) => {
              e.stopPropagation();
              setSiteToShowdetails(record._id);
              openEditModal("showSiteDetail");
            }}
          />
        )}

        <ActionBtn
          icon={faImage}
          onClick={(e) => {
            e.stopPropagation();
            if (resourceToLoad === "district")
              setSidebarImages([record.imgUrl]);
            else setSidebarImages(record.imgUrls);
            setIsSidebarOpen(true);
          }}
        />

        {!hideEditBtn && (
          <ActionBtn
            icon={faPenToSquare}
            onClick={(e) => {
              e.stopPropagation();
              openEditModal(resource);
              setRecordToEdit(record);
            }}
          />
        )}

        {fieldsValue.district !== "" && (
          <ActionBtn
            icon={faMessageLines}
            onClick={(e) => {
              e.stopPropagation();
              setCurrentNote(record.note);
              setIsNoteOpen(true);
            }}
          />
        )}

        {!hideDeleteBtn && (
          <ActionBtn
            isDeleteBtn={true}
            icon={faTrashCan}
            onClick={(e) => {
              e.stopPropagation();
              setResourceToDelete(resource);
              setRecordTodelete(record);
              setIsDeleteDialogOpen(true);
            }}
          />
        )}
      </>
    );
  };

  const handleDelete = async () => {
    try {
      await deleteMethods[resourceToDelete](recordTodelete._id);

      if (resourceToDelete === "newConstruction") {
        let newNewConstructions = [...newConstructions];
        newNewConstructions = newNewConstructions.filter(
          (record) => record._id !== recordTodelete._id
        );
        setNewConstructions(newNewConstructions);
      } else {
        let newData = [...data];
        newData = newData.filter((record) => record._id !== recordTodelete._id);
        setData(newData);
      }
    } catch (error) {
      log(error);
    }

    setIsDeleteDialogOpen(false);
  };

  function getRowClickHandler() {
    if (fieldsValue.district === "") return onDistrictRowClicked;
    else if (fieldsValue.site === "" && fieldsValue.building === "")
      return onSiteRowClicked;

    return () => {};
  }

  function handleNewConstructonSave(data, _type, id) {
    let newNewConstructions = [...newConstructions];

    if (id) {
      const index = newNewConstructions.findIndex(
        (record) => record._id === id
      );

      if (index !== -1) newNewConstructions[index] = data;
    } else newNewConstructions.push(data);

    setNewConstructions(newNewConstructions);
  }

  const printTable = (data, columns, isDataSiteChildren, saveMethod) => {
    return (
      <>
        {!query.get("area") && (
          <>
            <div className="flex flex-row justify-between text-center my-4 ml-2 mb-4">
              <span></span>
              <span className="font-Rubik font-semibold">
                {resourceToLoad === "building" ? "Inventory Report" : ""}
              </span>
              <span></span>
            </div>
            <Table
              tableColumns={columns}
              tableData={resourceToLoad === "building" ? reportBuildings : data}
              saveData={saveMethod ? saveMethod : saveData}
              filtersStatus={disabledFields}
              filtersValue={fieldsValue}
              resourceToLoad={resourceToLoad}
              isDataSiteChildren={isDataSiteChildren}
              onRowClicked={getRowClickHandler()}
            />
          </>
        )}

        {fieldsValue.site &&
          resourceToLoad === "building" &&
          !query.get("area") && (
            <StatisticReport buildingStatistic={buildingStatistic} />
          )}
        {fieldsValue.site && query.get("area") && (
          <div>
            <div className="flex flex-row justify-between text-center my-4 ml-2 ">
              <span>
                <button
                  className="flex justify-center bg-700 text-white p-2 px-3 rounded-md ml-2 text-sm"
                  // onClick={onPrint}
                >
                  <span>
                    <FontAwesomeIcon icon={faFileChartPie} fontSize="18px" />
                  </span>
                  <span className="ml-2">Generate Report</span>
                </button>
              </span>
              <span className="font-Rubik font-semibold">
                {" "}
                Building Area Report
              </span>
              <span className="w-40"></span>
            </div>
            <Table
              tableData={buildingOPSCReport}
              tableColumns={BUILDING_OPSC_COLUMNS}
              total={true}
            />
          </div>
        )}
      </>
    );
  };

  const canBeGrid = () => {
    return resourceToLoad === "district" || resourceToLoad === "site";
  };

  function openEditModal(label) {
    const newIsModalOpen = { ...mockModalState, [label]: true };
    setIsEditModalOpen(newIsModalOpen);
  }

  function closeEditModal(label) {
    const newIsModalOpen = { ...mockModalState, [label]: false };
    setIsEditModalOpen(newIsModalOpen);
  }

  const layoutSwitcher = (
    <div
      className="d-flex flex-row justify-content-end pt-2"
      style={{ color: "#777", marginTop: "3px" }}
    >
      <div className="flex flex-row mx-3">
        <div
          className={`flex flex-col justify-center item-center mx-2 w-7 h-7 border-gray-300 border-2 rounded  ${
            layout === layouts[0] && "option-active"
          }`}
          role="button"
          onClick={() => setLayout(layouts[0])}
        >
          <FontAwesomeIcon icon={faTableList} color="#334155" fontSize={18} />
        </div>
        <div
          className={`flex flex-col justify-center item-center mx-2 w-7 h-7 border-gray-300 border-2 rounded ${
            !canBeGrid() && "option-disabled"
          } ${layout === layouts[1] && "option-active"}`}
          role="button"
          onClick={() => setLayout(layouts[1])}
        >
          <FontAwesomeIcon icon={faBorderAll} color="#334155" fontSize={18} />
        </div>
      </div>
    </div>
  );

  const tableInfo = (
    <>
      {layoutSwitcher}
      <div className="home-content-container">
        {resourceToLoad === "building" ? (
          <>
            <SiteChildren
              printTable={printTable}
              buildings={printTable(
                Array.isArray(data) ? data : [],
                getTableColumns("building"),
                {
                  building: true,
                }
              )}
              newConstructionsPrinted={printTable(
                newConstructions,
                getTableColumns("newConstruction"),
                {
                  newConstruction: true,
                },
                handleNewConstructonSave
              )}
              siteDetails={printTable(
                mergeSiteDetails(siteDetails),
                getTableColumns("siteDetails", true, true),
                { siteDetails: true }
              )}
            />
          </>
        ) : (
          printTable(data, getTableColumns(resourceToLoad))
        )}
      </div>
    </>
  );

  if (layout === layouts[1] && !canBeGrid()) {
    setLayout(layouts[0]);
  }

  const indexRouteContent =
    layout === layouts[1] ? (
      <>
        {layoutSwitcher}
        <DataOnGridLayout
          data={data}
          filtersStatus={disabledFields}
          filtersValue={fieldsValue}
          saveData={saveData}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          onEdit={() => openEditModal(resourceToLoad)}
          setRecordToEdit={setRecordToEdit}
          setRecordTodelete={setRecordTodelete}
          resourceToLoad={resourceToLoad}
          onGridItemClicked={getRowClickHandler()}
          setResourceToDelete={setResourceToDelete}
        />
      </>
    ) : (
      tableInfo
    );

  function onDistrictRowClicked(row) {
    setFieldsValue({
      district: row._id,
      site: "",
      building: "",
    });
    setDisabledFields({
      district: false,
      site: false,
      building: true,
    });

    setResourceToLoad(
      determineResourceToLoad({
        district: row._id,
        site: "",
        building: "",
      })
    );
  }

  function onSiteRowClicked(row) {
    if (!!fieldsValue.district) navigate("/school-map/" + row._id);
  }

  const handleHomeButtonClicked = () => {
    setFieldsValue({
      district: "",
      site: "",
      building: "",
    });
    setDisabledFields({
      district: false,
      site: true,
      building: true,
    });
    setResourceToLoad("district");
    setLayout(layouts[1]);
  };
  return (
    <div>
      <SiteChildrenContext.Provider
        value={{
          siteDetails,
          setSiteDetails,
          newConstructions,
          setNewConstructions,
        }}
      >
        <div className="header">
          <Navbar user={user} onHomeButtonClicked={handleHomeButtonClicked} />
          {/* <FiltersNavbar
            fieldsValue={fieldsValue}
            setFieldsValue={setFieldsValue}
            fieldsStatus={disabledFields}
            setFieldsStatus={setDisabledFields}
            setResourceToLoad={setResourceToLoad}
            data={data}
            user={user}
            setLayout={setLayout}
            handleBuildingStatistic={handleBuildingStatistic}
            handleBuildingOPSCReport={handleBuildingOPSCReport}
          /> */}
        </div>

        <Routes>
          <Route index element={indexRouteContent} />
        </Routes>

        <Sidebar
          isOpen={isSidebarOpen}
          onRequestClose={() => setIsSidebarOpen(false)}
          showBackdrop={true}
        >
          <SideFeaturedImages images={sidebarImages} />
        </Sidebar>

        <DeleteDialog
          isOpen={isDeleteDialogOpen}
          message={`Your related sites, buildings, facility system and rooms data will be deleted, and you can not undo that`}
          onRequestClose={() => setIsDeleteDialogOpen(false)}
          onDelete={handleDelete}
        />
        <Modal
          isOpen={isEditModalOpen.district}
          onRequestClose={() => closeEditModal("district")}
          // width="40rem"
        >
          <ModalHeaderForForms
            formId="updateDistrict"
            onRequestClose={() => closeEditModal("district")}
            title="Update District"
            icon={faCity}
          />
          <div className="form-on-modal-container w-full h-full flex flex-col justify-center">
            <AddDistrict
              filters={fieldsValue}
              formId="updateDistrict"
              onRequestClose={() => closeEditModal("district")}
              // feature={feature}
              saveData={saveData}
              data={recordToEdit}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isEditModalOpen.site}
          onRequestClose={() => closeEditModal("site")}
          // width="40rem"
        >
          <ModalHeaderForForms
            formId="updateSite"
            onRequestClose={() => closeEditModal("site")}
            title="Update Site"
            icon={faMapMarked}
          />
          <div className="form-on-modal-container">
            {/* <div className="modal-content"> */}
            <AddSite
              filters={fieldsValue}
              formId="updateSite"
              onRequestClose={() => closeEditModal("site")}
              // feature={feature}
              saveData={saveData}
              data={recordToEdit}
            />
            {/* </div> */}
          </div>
        </Modal>

        <Modal
          isOpen={isEditModalOpen.building}
          onRequestClose={() => closeEditModal("building")}
          height="90vh"
        >
          <ModalHeaderForForms
            onRequestClose={() => closeEditModal("building")}
            title={
              recordToEdit && recordToEdit.buildingName
                ? recordToEdit.buildingName
                : "Add Building Detail"
            }
            icon={faBuilding}
            formId="updateBuilding"
          />
          <div className="form-on-modal-container">
            <AddBuilding
              filters={fieldsValue}
              formId="updateBuilding"
              onRequestClose={() => closeEditModal("building")}
              // feature={recordToEdit.feature}
              saveData={saveData}
              data={recordToEdit}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isEditModalOpen.room}
          onRequestClose={() => closeEditModal("room")}
        >
          <ModalHeaderForForms
            onRequestClose={() => closeEditModal("room")}
            title="Update Room"
            icon={faPeopleRoof}
            formId={"updateRoom"}
          />
          <div className="form-on-modal-container">
            <div className="modal-content">
              <AddRoom
                filters={fieldsValue}
                formId={"updateRoom"}
                onRequestClose={() => closeEditModal("room")}
                // feature={feature}
                saveData={saveData}
                data={recordToEdit}
              />
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={isEditModalOpen.showSiteDetail}
          onRequestClose={() => closeEditModal("showSiteDetail")}
        >
          <div
            className="p-3"
            style={{
              overflowX: "auto",
              height: "85vh",
              margin: "5px",
            }}
          >
            <SiteDetails
              siteID={fieldsValue.site ? fieldsValue.site : siteToShowdetails}
            />
          </div>
        </Modal>
      </SiteChildrenContext.Provider>
    </div>
  );
};

export default Home;
