export const HARD_CODED_MAPS_IDS_LIST = [
  "642af138d2b5e7f76a09d505",
  "64511ce3a6a596ba222d1c86",
  "644fc95f0539e0922fe2c9cc",
  "651f00eef343b084a7b4e086",
  "651fd706f343b084a7b4e223",
];

export const hasHardCodedMap = (id) => {
  return HARD_CODED_MAPS_IDS_LIST.findIndex((m) => m === id) === -1
    ? false
    : true;
};
