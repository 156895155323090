import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";

function PasswordField({
  label,
  classes,
  icon,
  iconClasses,
  error,
  touched,
  passwordClear,
  setPasswordClear,
  ...props
}) {
  //   const [passwordClear, setPasswordClear] = useState(false);
  return (
    <div className={classes}>
      <div
        className={`icon-input d-flex px-3 justify-content-space-between align-items-center bg-white rounded-md `}
      >
        <FontAwesomeIcon
          icon={icon}
          className={`${iconClasses} text-custom-primary text-secondary`}
          fontSize="16px"
        />
        <input
          {...props}
          type={passwordClear ? "text" : "password"}
          className="input-control normalize-text custom-password bg-white"
          placeholder={label}
          autoComplete="off"
        />

        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          icon={passwordClear ? faEyeSlash : faEye}
          className="fw-lighter text-custom-primary text-tiny text-secondary"
          onClick={() => setPasswordClear((preState) => !preState)}
        />
      </div>

      <div className="normalize-text text-danger px-2 error-text">
        {touched && error ? error : " "}
      </div>
    </div>
  );
}

export default PasswordField;
