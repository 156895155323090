import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";

const FileInput = ({
  label,
  value,
  name,
  error,
  touched,
  showBtn,
  btnLabel,
  onBtnClick,
  title = "Upload Image",
  ...rest
}) => {
  const [fileInput, setFileInput] = useState(null);
  const handleClick = () => {
    if (fileInput) {
      fileInput.click();
    }
  };
  //   const handleChange = (event) => {
  //     const fileUploaded = event.target.files[0];
  //     console.log(fileUploaded);
  //   };
  return (
    <div className="border-1 border-gray-300 px-16 py-2 rounded-lg mx-4">
      <div className="d-flex justify-content-between gap-1">
        <div
          onClick={handleClick}
          className="flex flex-col justify-center items-center"
        >
          <div
            title="District Image"
            className="bg-gray-200 cursor-pointer w-12 h-12 text-center flex flex-col justify-center border-5 border-gray-100 rounded-full"
          >
            <FontAwesomeIcon icon={faCloudArrowUp} size="lg" />
          </div>
          <div>{title}</div>
        </div>
        <input
          {...rest}
          id={name}
          className={`w-56 normalize-text ${
            showBtn ? "custom-input-1" : "custom-input"
          } `}
          name={name}
          placeholder={label}
          value={value}
          autoComplete="off"
          style={{ display: "none" }} // Hide this
          ref={(el) => setFileInput(el)}
          //   onChange={handleChange}
        />
        {showBtn && (
          <button
            type="button"
            className="btn btn-sm btn-info text-light"
            onClick={onBtnClick}
          >
            {btnLabel}
          </button>
        )}
      </div>
      <div className="normalize-text text-danger px-2 error-text">
        {touched && error ? error : " "}
      </div>
    </div>
  );
};

export default FileInput;
