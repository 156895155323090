import React, { useState } from "react";
import { useFormik } from "formik";
import { faHome, faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Button from "../Button";
import PasswordField from "../common/PasswordField";

// Validation Schema
const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Must be 6 characters or more")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const initialValues = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const [passwordClear, setPasswordClear] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  function onSubmit(values) {
    console.log(values);
  }

  return (
    <>
      <div className="custom-navbar d-flex justify-content-between align-items-center ">
        <nav className="custom-nav">
          <Link to="/districts" className="brand-logo custom-nav-item">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </nav>

        <div></div>
      </div>
      <div className="home-content-container d-flex justify-content-center align-items-center flex-column gap-2">
        <h3 className="mb-5">Reset Password</h3>

        <form onSubmit={formik.handleSubmit}>
          <PasswordField
            label="New Password"
            icon={faLock}
            classes="mb-custom-3"
            error={formik.errors.password}
            touched={formik.touched.password}
            name="password"
            value={formik.values.password}
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <PasswordField
            label="Confirm New Password"
            icon={faLock}
            classes="mb-custom-3"
            error={formik.errors.confirmPassword}
            touched={formik.touched.confirmPassword}
            name="confirmPassword"
            value={formik.values.confirmPassword}
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <Button type="submit" classes="btn-custom-primary" marginClass="my-4">
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
