import React from "react";
import Table from "./Table";
import { BUILDING_STATISTIC_COLUMNS } from "../../data/building_statics_columns";

const Statistic_Report = ({ buildingStatistic }) => {
  console.log("buildingStatistic", buildingStatistic);

  return (
    <div>
      <div className="flex flex-row border-x-1 border-1 border-y-white justify-between text-center py-3 pl-2 ">
        <span></span>
        <span className="font-Rubik font-semibold"> Building Statistic</span>
        <span></span>
      </div>
      <Table
        tableData={buildingStatistic}
        tableColumns={BUILDING_STATISTIC_COLUMNS}
        total={true}
        tableType="building_statistic"
      />
    </div>
  );
};

export default Statistic_Report;
