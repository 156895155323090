import React from "react";
import PixleyElementarySVG from "../data/School Svgs/PixleyElementarySVG";
import { useParams } from "react-router-dom";
import SequoiaUnionElementary from "../data/School Svgs/SequoiaUnionElementary";
import OakValleyUnionElementary from "../data/School Svgs/OakValleyUnionElementary";
import ForestHillElementary from "../data/School Svgs/foresthillElementary";
import ForesthillDivide from "../data/School Svgs/foresthillDivide";

const SchoolSVGRenderer = (props) => {
  const { siteID } = useParams();

  const maps = {
    "642af138d2b5e7f76a09d505": <PixleyElementarySVG {...props} />,
    "64511ce3a6a596ba222d1c86": <OakValleyUnionElementary {...props} />,
    "644fc95f0539e0922fe2c9cc": <SequoiaUnionElementary {...props} />,
    "651f00eef343b084a7b4e086": <ForestHillElementary {...props} />,
    "651fd706f343b084a7b4e223": <ForesthillDivide {...props} />,
  };

  return maps[siteID];
};

export default SchoolSVGRenderer;
