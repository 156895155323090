import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../common/Input";
import FileInput from "../common/FileInput";
import { uploadImage } from "../../utils/uploadImage";
import { toast } from "react-toastify";
import { getCurrentUser } from "../../services/authService";
import { addressToLatLng } from "../../utils/geocode";
import PhotoPreviewForForms from "../common/PhotoPreviewForForms";

// Validation Schema
const validate = Yup.object({
  districtName: Yup.string().min(2).max(50).required(),
});

const initialValues = {
  districtName: "",
  username: "",
  password: "",
  confirmPassword: "",
  address: "",
  phone: "",
  fax: "",
  website: "",
};

const AddDistrict = ({ formId, onRequestClose, saveData, data }) => {
  const [images, setImages] = useState(data ? [data.imgUrl] : []);
  const [places, setPlaces] = useState([]);
  const [addresss, setAddresss] = useState(
    data && data.address ? data.address : ""
  );
  const [districtCoord, setDistrictCoord] = useState(
    data && data.coord ? data.coord : null
  );

  const transformedData = data && {
    districtName: data.name,
    username: data.userName,
    password: "",
    confirmPassword: "",
    address: data.address,
    phone: data.phone,
    fax: data.fax,
    website: data.website,
  };

  const formik = useFormik({
    initialValues: data ? transformedData : initialValues,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit,
  });

  async function onSubmit(values) {
    if (addresss) values.address = addresss;
    if (addresss && districtCoord) values.coord = districtCoord;

    try {
      await saveData(
        {
          _id: data ? data._id : null,
          name: values.districtName,
          imgUrl: images.length > 0 ? images[0] : "",
          user: getCurrentUser().id,
          address: values.address,
          phone: values.phone,
          fax: values.fax,
          website: values.website,
          coord: values.coord,
        },
        "district",
        data ? data._id : null
      );

      onRequestClose();
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      )
        toast.error(error.response.data.error);
    }
  }

  const onChooseFile = async (e) => {
    const images = await uploadImage(e.target.files);
    setImages(images);
  };

  // function handlePhoneNumberChange(e) {
  //   if (
  //     e.target.value &&
  //     e.target.value.length === 3 &&
  //     e.target.value[0] !== "("
  //   ) {
  //     e.target.value = `(${e.target.value})`;
  //   }

  //   if (
  //     e.target.value &&
  //     e.target.value.length === 4 &&
  //     e.target.value[3] !== ")"
  //   ) {
  //     e.target.value = `${e.target.value})`;
  //   }

  //   if (
  //     e.target.value &&
  //     e.target.value.length === 8 &&
  //     e.target.value[7] !== "-"
  //   ) {
  //     e.target.value = `${e.target.value}-`;
  //   }

  //   if (e.target.value && e.target.value.length > 13) return;

  //   formik.handleChange(e);
  // }

  function handlePhoneNumberChange(e) {
    let { value } = e.target;
    value = value.replace(/\D/g, "");

    if (value.length > 3 && value.length <= 6)
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    else if (value.length > 6)
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(
        6,
        10
      )}`;

    if (value.length > 14) return;

    e.target.value = value;
    formik.handleChange(e);
  }

  function handleAddressChange() {
    addressToLatLng(addresss, setPlaces);
  }
  //  className =
  //    "mb-3 flex flex-row flex-wrap border-2 rounded-lg px-5 py-5 max-w-5xl";
  return (
    <>
      <form onSubmit={formik.handleSubmit} id={formId}>
        <div className="w-full h-full flex flex-col justify-center align-middle">
          <div className="flex flex-col border-2 border-200 mb-3 rounded-lg pt-4 w-full h-full">
            <div className="flex flex-col flex-grow w-full justify-center px-2">
              <div className="flex flex-row w-full justify-between">
                <Input
                  label={"District Name"}
                  name="districtName"
                  type="text"
                  error={formik.errors.districtName}
                  touched={formik.touched.districtName}
                  value={formik.values.districtName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <div className="position-relative">
                  <Input
                    label={"Address"}
                    name="address"
                    type="text"
                    autoComplete={"off"}
                    value={addresss}
                    onChange={(e) => {
                      setAddresss(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    showBtn={true}
                    btnLabel="Geocode"
                    onBtnClick={handleAddressChange}
                  />

                  {places.length > 0 && (
                    <div className="places__list">
                      {places.map((place) => (
                        <div
                          key={place.place_id}
                          className="places__list__item"
                          onClick={() => {
                            setAddresss(place?.formatted_address);
                            setDistrictCoord([
                              +place.geometry.location.lat,
                              +place.geometry.location.lng,
                            ]);
                            setPlaces([]);
                          }}
                        >
                          <img
                            src="/placeholder.png"
                            alt="Placeholder"
                            style={{ width: 18, height: 18 }}
                          />
                          &nbsp;&nbsp;{place?.formatted_address}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <Input
                  label={"Phone"}
                  name="phone"
                  type="phone"
                  error={formik.errors.phone}
                  touched={formik.touched.phone}
                  value={formik.values.phone}
                  onChange={handlePhoneNumberChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
              <div className="flex flex-row w-full justify-between">
                <Input
                  label={"Fax Number"}
                  name="fax"
                  type="text"
                  error={formik.errors.fax}
                  touched={formik.touched.fax}
                  value={formik.values.fax}
                  onChange={handlePhoneNumberChange}
                  onBlur={formik.handleBlur}
                />
                <Input
                  label={"Website"}
                  name="website"
                  type="text"
                  error={formik.errors.website}
                  touched={formik.touched.website}
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FileInput
                  label={"Images"}
                  showLabel={true}
                  name="images"
                  type="file"
                  onChange={onChooseFile}
                />
                <PhotoPreviewForForms data={images} setData={setImages} />
              </div>
            </div>
            <br />
            <br />
          </div>
          <div className="flex flex-row justify-between  flex-nowrap max-w-5xl space-x-2">
            <button
              onClick={onRequestClose}
              className="w-1/2  box-border border-1 rounded-md border-700 h-10 text-700 mb-4"
            >
              Cancel
            </button>
            <button
              type="submit"
              form={formId}
              className="w-1/2  bg-700 text-white h-10 rounded-md font-medium"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddDistrict;
