import React from "react";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import StatisticReport from "./StatisticReport";

export default class TableContentToPrint extends React.PureComponent {
  render() {
    const district = this.props.district;
    const filters = this.props.filters;
    const resourceToLoad = this.props.resourceToLoad;

    return (
      <div className="table-content-to-print__container">
        <div className="d-flex justify-content-between align-items-center mb-4">
          {filters.district && district && (
            <>
              <div>
                <h4>{district.name}</h4>
              </div>

              <img
                src={district.imgUrl}
                alt=""
                width={100}
                style={{
                  borderRadius: "3px",
                }}
              />
            </>
          )}
        </div>

        <div className="table-container-1">
          <h3 className="mb-4">
            {resourceToLoad === "building" && "Pixley Middle School"}
          </h3>
          <table {...this.props.getTableProps()}>
            <TableHeader headerGroups={this.props.headerGroups} />
            <TableBody
              getTableBodyProps={this.props.getTableBodyProps}
              page={this.props.page}
              prepareRow={this.props.prepareRow}
            />
          </table>

          {resourceToLoad === "building" && (
            <>
              {/* <div className="flex flex-row border-x-1 border-1 border-y-white justify-between text-center py-3 pl-2 ">
                <span></span>
                <span className="font-Rubik font-semibold">
                  {" "}
                  Building Statistic
                </span>
                <span></span>
              </div> */}
              <StatisticReport
                buildingStatistic={[
                  {
                    buildingType: "Permanent",
                    totalAreaUnites: 36926,
                    totalInteriorSquareFeet: 25892,
                    totalRooms: 43,
                    avgAge: 13,
                  },
                  {
                    buildingType: "Portable",
                    totalAreaUnites: 2820,
                    totalInteriorSquareFeet: 0,
                    totalRooms: 0,
                    avgAge: 63,
                  },
                  {
                    buildingType: "Other",
                    totalAreaUnites: 0,
                    totalInteriorSquareFeet: 0,
                    totalRooms: 0,
                    avgAge: null,
                  },
                  {
                    buildingType: "Total",
                    totalAreaUnites: 39746,
                    totalInteriorSquareFeet: 25892,
                    totalRooms: 43,
                    avgAge: "",
                  },
                ]}
              />
            </>
          )}
        </div>

        {/* <div className="mt-5 mx-1 flex-wrap d-flex gap-5">
          <div className="normalize-text-print">
            Address: {district?.address}
          </div>

          <div className="normalize-text-print">Phone: {district?.phone}</div>

          <div className="normalize-text-print">
            Fax Number: {district?.fax}
          </div>

          <div className="normalize-text-print">
            Website: {district?.website}
          </div>
        </div> */}
      </div>
    );
  }
}
