import PhotoPreviewCreator from "../components/common/PhotoPreviewCreator";
import { stringToCommaSeparatedNum } from "../utils/commonUtils";

export const SITE_DETAIL_COLUMNS = [
  {
    Header: "System",
    accessor: "system",
  },

  {
    Header: "Construction Cost",
    accessor: "constructionCost",
  },
  {
    Header: "Soft Cost",
    accessor: "softCost",
  },
  {
    Header: "Total Cost",
    accessor: (row) => stringToCommaSeparatedNum(row.totalCost),
  },
  {
    Header: "Priority",
    accessor: "priority",
  },
  {
    Header: "Area/Units",
    accessor: "areaUnits",
  },

  {
    Header: "Image",
    accessor: (row) => {
      if (row.imgUrls && row.imgUrls[0])
        return <PhotoPreviewCreator data={row.imgUrls} />;

      return "";
    },
  },
];
