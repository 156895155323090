export const SITE_COLUMNS = [
  {
    Header: "School",
    Footer: "School",
    accessor: "schoolName",
  },
  // {
  //   Header: "Site Location",
  //   Footer: "Site Location",
  //   accessor: "siteLocation",
  // },
  // {
  //   Header: "System",
  //   Footer: "System",
  //   accessor: "system",
  // },
  // {
  //   Header: "Sub System",
  //   Footer: "Sub System",
  //   accessor: "subSystem",
  // },
  // {
  //   Header: "Action",
  //   Footer: "Action",
  //   accessor: "action",
  // },
  // {
  //   Header: "Action Item",
  //   Footer: "Action Item",
  //   accessor: "actionItem",
  // },
  // {
  //   Header: "Note",
  //   Footer: "Note",
  //   accessor: "note",
  // },
  // {
  //   Header: "Area/Units",
  //   Footer: "Area/Units",
  //   accessor: "areaUnits",
  // },
  // {
  //   Header: "Cost",
  //   Footer: "Cost",
  //   accessor: "constructionCost",
  // },
  // {
  //   Header: "Priority",
  //   Footer: "Priority",
  //   accessor: "priority",
  // },
  {
    Header: "Telephone",
    Footer: "Telephone",
    accessor: "telephone",
  },
  {
    Header: "Address",
    Footer: "Address",
    accessor: "address",
  },
  {
    Header: "Current Enrollment",
    Footer: "Current Enrollment",
    accessor: "currentEnrollment",
  },
  {
    Header: "Web Address",
    Footer: "Web Address",
    accessor: "web",
  },
  {
    Header: "School Type",
    Footer: "School Type",
    accessor: "gradeLevel",
  },
];

// export const HIDDEN_COLUMNS = [
//   "note",
//   "areaUnits",
//   "constructionCost",
//   "priority",
// ];
