import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

const TableHeader = ({ headerGroups, tableType = "" }) => {
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          // className={`${
          //   tableType === "building_statistic"
          //     ? "border-x-2 border-300"
          //     : "border-x-0"
          // }`}
        >
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              // className="bg-700 text-white text-center font-normal text-base font-rubik"
              className={`${
                tableType === "building_statistic"
                  ? "bg-tb-static text-700 text-sm border-1"
                  : "bg-700 text-white"
              }  text-center font-normal text-base font-rubi`}
            >
              {column.render("Header")} &nbsp;{" "}
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )
              ) : (
                ""
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
