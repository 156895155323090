import { stringToCommaSeparatedNum } from "../utils/commonUtils";

export const NEW_CONSTRUCTION_COLUMNS = [
  {
    Header: "System",
    accessor: "system",
  },
  {
    Header: "Sub System",
    accessor: "subSystem",
  },
  {
    Header: "Construction Cost",
    accessor: "constructionCost",
  },
  {
    Header: "Soft Cost",
    accessor: "softCost",
  },
  {
    Header: "Total Cost",
    accessor: (row) => stringToCommaSeparatedNum(row.totalCost),
  },
  {
    Header: "Priority",
    accessor: "priority",
  },
  {
    Header: "Area/Units",
    accessor: (row) => {
      if (!row.areaUnits) return "";
      return row.areaUnits + (row.unit === "square feet" ? " sqft" : " lft");
    },
  },
];
