import { faPenToSquare, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import imgPlaceholder from "../assets/img-placeholder.jpg";

const SiteDetailItem = ({ siteDetail, index, onEdit, onDelete }) => {
  return (
    <div className="d-flex align-items-start gap-3 mb-5">
      <div
        style={{
          width: "30%",
          textAlign: "end",
        }}
      >
        <img
          src={
            siteDetail.imgUrls && siteDetail.imgUrls.length > 0
              ? siteDetail.imgUrls[0]
              : imgPlaceholder
          }
          alt=""
          width={80}
          style={{ borderRadius: "5px" }}
        />

        {(index || index > -1) && (
          <div className="d-flex justify-content-around p-2">
            <button
              onClick={() => onEdit(siteDetail, index)}
              className="action-btn text-custom-primary"
              type="button"
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <button
              onClick={() => onDelete(siteDetail, index)}
              className="action-btn text-danger"
              type="button"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        )}
      </div>

      <div>
        {/* Site Location: {siteDetail.siteLocation} <br /> */}
        System: {siteDetail.system} <br />
        {/* Sub System: {siteDetail.subSystem} <br /> */}
        {/* Action: {siteDetail.action} <br /> */}
        {/* Action Item: {siteDetail.actionItem} <br /> */}
        Construction Cost: {siteDetail.constructionCost} <br />
        Priority: {siteDetail.priority} <br />
        Area/Unit: {siteDetail.areaUnits} <br />
        Note:{" "}
        <div
          dangerouslySetInnerHTML={{
            __html: siteDetail.note?.replaceAll("&lt;", "<"),
          }}
        ></div>
        <br />
      </div>
    </div>
  );
};

export default SiteDetailItem;
