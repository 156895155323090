import React from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Editor = ({ data, onChange, label }) => {
  const newData = data?.replaceAll("&lt;", "<");

  return (
    <div className="mb-3 d-flex flex-column">
      <span className="input__label">{label || "Note"}</span>

      <CKEditor
        editor={ClassicEditor}
        data={newData}
        config={{
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "bulletedList",
              "numberedList",
              "insertTable",
            ],
            shouldNotGroupWhenFull: true,
          },
        }}
        onChange={(_event, editor) => {
          onChange(editor.getData());
        }}
      />
    </div>
  );
};

export default Editor;
