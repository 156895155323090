import React from "react";

const RoomSystemsTable = ({ roomSystems, setRoomSystems }) => {
  const addRow = () => {
    setRoomSystems((pre) => ({
      ...pre,
      systems: [
        ...pre?.systems,
        {
          systemName: "",
          manufacturer: "",
          serialID: "",
          materialUnitType: "",
          installYear: "",
          lifeCycleTimeline: "",
          endOfLifeCycleTimeline: "",
          yearsRemaining: "",
        },
      ],
    }));
  };

  const updateRoomSystem = (index, updatedData, fieldName) => {
    const updatedRoomSystems = roomSystems.systems.map((roomSystem, idx) =>
      idx === index ? { ...roomSystem, ...updatedData } : roomSystem
    );

    const newSystem = { ...updatedRoomSystems[index] };
    if (
      (fieldName === "installDate" || fieldName === "lifeCycleTimeline") &&
      newSystem.installDate &&
      newSystem.lifeCycleTimeline
    ) {
      newSystem.endOfLifeCycleDate =
        +newSystem.installDate + +newSystem.lifeCycleTimeline;
      updatedRoomSystems[index] = newSystem;
    }

    setRoomSystems((pre) => ({
      ...pre,
      systems: updatedRoomSystems,
    }));
  };

  const getRemainingSystemAge = (roomSystem) => {
    if (roomSystem.installDate && roomSystem.endOfLifeCycleDate) {
      const currentYear = new Date().getFullYear();

      return {
        age: +roomSystem.endOfLifeCycleDate - currentYear,
        success: true,
      };
    }

    return { success: false };
  };

  const getStyleForPermRoofSystem = (age) => {
    if (age >= 11) {
      return {
        backgroundColor: "green",
        color: "white",
      };
    }

    if (age >= 6 && age <= 10) {
      return {
        backgroundColor: "yellow",
      };
    }

    if (age >= 2 && age <= 5) {
      return {
        backgroundColor: "orange",
        color: "white",
      };
    }

    if (age < 2) {
      return {
        backgroundColor: "red",
        color: "white",
      };
    }
  };

  const getRemainingFieldStyle = (buildingSystem) => {
    const { age, success } = getRemainingSystemAge(buildingSystem);
    if (success) {
      // if (buildingSystem.systemName?.toLowerCase() === "")
      return getStyleForPermRoofSystem(age);
    }

    return {};
  };

  if (!roomSystems) return <div>Loading...</div>;

  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-4">
        <h6>Building Systems</h6>
        <button
          type="button"
          className="btn btn-sm btn-custom-primary"
          onClick={addRow}
        >
          Add Row
        </button>
      </div>
      <div className="table-responsive">
        <table className="buildingSystemsTable">
          <thead>
            <tr>
              <th>System Name</th>
              <th>Manufacturer</th>
              <th>Serial ID</th>
              <th>Material/Unit Type</th>
              <th>Install Year</th>
              <th>Life Cycle Timeline</th>
              <th>End of Life Cycle Timeline</th>
              <th>Years Remaining</th>
            </tr>
          </thead>
          <tbody>
            {roomSystems?.systems?.map((roomSystem, index) => (
              <tr key={index}>
                <td>
                  <input
                    className="buildingsystemtable__inputs"
                    value={roomSystem.systemName}
                    onChange={(e) =>
                      updateRoomSystem(index, {
                        systemName: e.target.value,
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    className="buildingsystemtable__inputs"
                    value={roomSystem.manufacturer}
                    onChange={(e) =>
                      updateRoomSystem(index, {
                        manufacturer: e.target.value,
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    className="buildingsystemtable__inputs"
                    value={roomSystem.serialID}
                    onChange={(e) =>
                      updateRoomSystem(index, { serialID: e.target.value })
                    }
                  />
                </td>
                <td>
                  <input
                    className="buildingsystemtable__inputs"
                    value={roomSystem.materialUnitType}
                    onChange={(e) =>
                      updateRoomSystem(index, {
                        materialUnitType: e.target.value,
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="buildingsystemtable__inputs"
                    value={roomSystem.installDate}
                    onChange={(e) =>
                      updateRoomSystem(
                        index,
                        {
                          installDate: e.target.value,
                        },
                        "installDate"
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="buildingsystemtable__inputs"
                    value={roomSystem.lifeCycleTimeline}
                    onChange={(e) =>
                      updateRoomSystem(
                        index,
                        {
                          lifeCycleTimeline: e.target.value,
                        },
                        "lifeCycleTimeline"
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    className="buildingsystemtable__inputs"
                    value={roomSystem.endOfLifeCycleDate}
                    readOnly
                    onChange={(e) =>
                      updateRoomSystem(index, {
                        endOfLifeCycleDate: e.target.value,
                      })
                    }
                  />
                </td>
                <td
                  className="px-1 text-center"
                  style={getRemainingFieldStyle(roomSystem)}
                >
                  {getRemainingSystemAge(roomSystem)?.age}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RoomSystemsTable;
