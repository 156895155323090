import React, { useState, useRef, useEffect } from "react";
import {
  faEdit,
  faEllipsisVertical,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image from "../assets/img-placeholder.jpg";

const GridLayoutItem = ({ record, onDelete, onEdit, onGridItemClicked }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const node = useRef();

  // Track events outside scope
  const clickOutside = (e) => {
    if (node && node.current && !node.current.contains(e.target)) {
      // outside click
      setIsPopupOpen(false);
      return;
    }
    // inside click
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);

    // clean up function before running new effect
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [isPopupOpen]);

  return (
    <div
      className="data-on-grid-item flex flex-col justify-evenly items-center"
      onClick={() => onGridItemClicked(record)}
      role="button"
    >
      <div className="flex flex-col justify-center items-center w-3/4 h-3/4 rounded-md">
        <img
          className="w-18 h-18 rounded-md"
          src={
            record.imgUrl
              ? record.imgUrl
              : record.imgUrls
              ? record.imgUrls[0]
              : image
          }
          alt=""
          style={{
            objectFit: "contain",
          }}
        />
      </div>

      <div className="flex flex-row items-center justify-between pb-2 w-full">
        <div className="text-sm font-semibold pl-4">
          {record.name && record.name}
          {record.schoolName && record.schoolName}
        </div>
        <div
          className="px-3 "
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            setIsPopupOpen((pre) => !pre);
          }}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
        {isPopupOpen && (
          <div ref={node} className="grid-layout-popup">
            <div
              className="grid-layout-popup-item"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
                setIsPopupOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
              &nbsp;&nbsp; Edit
            </div>
            <div
              className="grid-layout-popup-item"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
                setIsPopupOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> &nbsp;&nbsp; Delete
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GridLayoutItem;
