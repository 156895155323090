import React from "react";
import DashboardDataSummaryItem from "./DashboardDataSummaryItem";

const DashboardDetailItem = ({ infoObj, label, isForPrint }) => {
  if (!infoObj) return null;

  return (
    <div
      className={
        isForPrint ? "dashboard__detailItem__print" : "dashboard__detailItem"
      }
    >
      <div className="dashboard__detailItem__header">{label}</div>

      {[
        Object.keys(infoObj).map((system) => {
          if (system !== "total")
            return (
              <DashboardDataSummaryItem
                key={system + infoObj[system]}
                // isTotal={system === "total"}
                label={system}
                value={infoObj[system]}
              />
            );
          return <span key={system + infoObj[system]}></span>;
        }),

        // To make sure total gets rendered last
        <DashboardDataSummaryItem
          key={"total" + infoObj["total"]}
          isTotal={true}
          label={"Total"}
          value={infoObj["total"]}
        />,
      ]}
    </div>
  );
};

export default DashboardDetailItem;
