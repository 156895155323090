const getEligibleDate = (buildingType, yearBuild, modernizationDate) => {
  if (buildingType === "Permanent" && modernizationDate === "0") {
    return Number(yearBuild) + 25;
  }
  if (buildingType === "Permanent" && modernizationDate !== "0") {
    return Number(modernizationDate) + 25;
  }
  if (buildingType === "Portable" && modernizationDate === "0") {
    return Number(yearBuild) + 20;
  }
  if (buildingType === "Portable" && modernizationDate !== "0") {
    return Number(modernizationDate) + 20;
  }
  return 0;
};
export const BUILDING_COLUMNS = [
  {
    Header: "Building Name",
    Footer: "Building Name",
    accessor: "buildingName",
  },
  {
    Header: "Building Type",
    Footer: "Building Type",
    accessor: "buildingType",
  },
  {
    Header: "Classrooms",
    Footer: "Classrooms",
    accessor: "classRooms",
  },
  {
    Header: "Building Area sqft",
    Footer: "Building Area",
    accessor: "areaUnits",
    Cell: ({ value }) => {
      let numValue = Number(value);
      if (!isNaN(numValue)) {
        return numValue.toLocaleString(); // This will add commas as thousand separators.
      } else {
        console.error(`'value' cannot be converted to a number: ${value}`);
        return value; // or return some fallback value
      }
    },
  },
  {
    Header: "Roof Area sqft",
    Footer: "Roof Area",
    accessor: "roofArea",
    Cell: ({ row }) => {
      const roofArea = row.original.areaUnits;
      let numValue = Number(roofArea);
      return numValue.toLocaleString();
    },
  },
  {
    Header: "Age",
    Footer: "Age",
    accessor: "age",
    Cell: ({ row }) => {
      const thisYear = new Date().getFullYear();
      const yearBuild = row.original.yearBuild;
      return yearBuild ? thisYear - yearBuild : 0;
    },
  },
  {
    Header: "Date Constructed",
    Footer: "Date Constructed",
    accessor: "yearBuild",
  },
  {
    Header: "SFP Mod Date",
    Footer: "SFP Mod Date",
    accessor: "sfpModDate",
  },
  {
    Header: "Eligible Date",
    Footer: "Eligible Date",
    accessor: "eligibleDate",
    Cell: ({ row }) => {
      const buildingType = row.original.buildingType;
      const yearBuild = row.original.yearBuild;
      const modernizationDate = row.original.sfpModDate;
      const eligibleDate = getEligibleDate(
        buildingType,
        yearBuild,
        modernizationDate
      );
      return isNaN(eligibleDate) ? "" : eligibleDate;
    },
  },
];

export const BUILDING_COLUMNS_DASHBOARD = [
  {
    Header: "Building Name",
    Footer: "Building Name",
    accessor: "buildingName",
  },
  {
    Header: "Building Type",
    Footer: "Building Type",
    accessor: "buildingType",
  },
  {
    Header: "Area",
    Footer: "Area",
    accessor: "areaUnits",
  },
  {
    Header: "Gross Classrooms",
    Footer: "Gross Classrooms",
    accessor: "classRooms",
  },
  {
    Header: "Date Constructed",
    Footer: "Date Constructed",
    accessor: "yearBuild",
  },
  {
    Header: "Age",
    Footer: "Age",
    accessor: (row) => {
      if (row.yearBuild) return new Date().getFullYear() - +row.yearBuild;

      return "";
    },
  },
];

// export const HIDDEN_COLUMNS = [
//   "note",
//   "areaUnits",
//   "constructionCost",
//   "priority",
// ];
