import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

const numberOfOptions = 10;

const DropdownList = ({
  options,
  selected,
  setSelected,
  setIsActive,
  idKey,
  valueKey,
}) => {
  const [data, setData] = useState(
    options.length > numberOfOptions
      ? options.slice(0, numberOfOptions)
      : options
  );

  const onSearch = (e) => {
    let data = [...options];
    data = data.filter((option) =>
      option[valueKey].toLowerCase().includes(e.target.value.toLowerCase())
    );

    if (data.length > numberOfOptions) data = data.slice(0, numberOfOptions);

    setData(data);
  };

  return (
    <div className="custom-dropdown-content">
      <input
        type="text"
        name="serach"
        placeholder="Search..."
        onChange={onSearch}
        className="custom-dropdown-search-box"
      />

      {data.map((option, index) => (
        <div
          key={option[idKey]}
          onClick={() => {
            setSelected(option[idKey], option, index);
            setIsActive(false);
          }}
          className="custom-dropdown-item"
        >
          <span>{option[valueKey]}</span>
          <span>
            {option[valueKey] === selected ? (
              <FontAwesomeIcon
                icon={faCheck}
                className="normalize-text text-custom-primary"
              />
            ) : (
              ""
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default DropdownList;
