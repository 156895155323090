import React, { useState } from "react";
import DashboardMainContentDetails from "./DashboardMainContentDetails";
// import img_placeholder from "../assets/img-placeholder.jpg";
import { useEffect } from "react";
import DashboardCharts from "./DashboardCharts";

const AssessmentSummaryPrint = ({ siteSummary, selectedSchoolObj }) => {
  const [runPrint, setRunPrint] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      if (runPrint) {
        window.print();
        setRunPrint(false);
      }
    }, 800);
  }, [runPrint]);
  return (
    <div>
      <div className="d-flex">
        <div className="w-50">
          {selectedSchoolObj && (
            <h4 className="mx-3 mb-3">{selectedSchoolObj.schoolName}</h4>
          )}
          <DashboardMainContentDetails
            siteSummary={siteSummary}
            isForPrint={true}
          />
        </div>
        <div
          className="w-50 d-flex flex-column align-items-center"
          style={{ marginTop: "1rem" }}
        >
          {siteSummary && (
            <div>
              <DashboardCharts siteSummary={siteSummary} isForPrint={true} />
            </div>
          )}
          {/* {selectedSchoolObj &&
            selectedSchoolObj.imgUrls &&
            selectedSchoolObj.imgUrls.map((image) => (
              <div style={{ alignSelf: "self-start", objectFit: "cover" }}>
                <img
                  src={image}
                  key={image}
                  alt=""
                  //   width={}
                  style={{
                    objectFit: "cover",
                    width: "400px",
                    borderRadius: "3px",
                  }}
                />
              </div>
            ))}
          {selectedSchoolObj.imgUrls.length === 0 && (
            <img src={img_placeholder} alt="" />
          )} */}
        </div>
      </div>

      {/* <br />
      <br />
      <br /> */}

      {/* {siteSummary && (
        <div style={{ pageBreakInside: "avoid" }}>
          <DashboardCharts siteSummary={siteSummary} />
        </div>
      )} */}
    </div>
  );
};

export default AssessmentSummaryPrint;
