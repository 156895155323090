import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/pro-regular-svg-icons";

const Input = ({
  label,
  value,
  name,
  error,
  touched,
  showBtn,
  btnLabel,
  onBtnClick,
  smallinput = false,
  ...rest
}) => {
  return (
    <div className="mx-4 mb-4">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="flex flex-row justify-left align-middle">
        {/* <span className="input__label">{label}</span> */}

        <input
          {...rest}
          id={name}
          className={`${smallinput ? "w-40" : "w-56"} normalize-text ${
            showBtn ? "custom-input-1" : "custom-input"
          } `}
          name={name}
          placeholder={label}
          value={value}
          autoComplete="off"
        />

        {showBtn && (
          <div
            title="Geo Code"
            onClick={onBtnClick}
            className="ml-4 flex flex-col justify-center bg-gray-200 w-8 rounded-md cursor-pointer"
          >
            <FontAwesomeIcon icon={faMapPin} size="lg" />
          </div>
          // <button type="button" className="" onClick={onBtnClick}>
          //   {btnLabel}
          // </button>
        )}
      </div>
      <div className="normalize-text text-danger px-2 error-text">
        {touched && error ? error : " "}
      </div>
    </div>
  );
};

export default Input;
