import React from "react";
import DashboardCharts from "./DashboardCharts";
import DashboardMainContent from "./DashboardMainContent";
import DashboardMainContentBasicInfo from "./DashboardMainContentBasicInfo";
// import { useNavigate } from "react-router-dom";
// import { faPrint } from "@fortawesome/pro-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AssessmentSummary = ({ selectedSchoolObj, siteSummary }) => {
  // const navigate = useNavigate();
  return (
    <div
      className="w-80 px-2 py-3 text-custom-secondary"
      style={{
        height: "90vh",
        overflowY: "auto",
        width: "80vw",
        flexGrow: 10,
      }}
    >
      {/* <div style={{ textAlign: "right" }}>
        <button
          className="modal-btn-custom btn-custom-primary ml-custom-1 my-2 mb-4"
          onClick={() => navigate("/dashboard/assessment-summary/print")}
        >
          <FontAwesomeIcon icon={faPrint} className="normalize-text" />
          &nbsp;&nbsp;Create Report
        </button>
      </div> */}
      {selectedSchoolObj && (
        <>
          <DashboardMainContentBasicInfo
            selectedSchoolObj={selectedSchoolObj}
            siteSummary={siteSummary}
          />

          <div className="d-flex">
            <DashboardMainContent
              selectedSchoolObj={selectedSchoolObj}
              siteSummary={siteSummary}
            />
            <DashboardCharts siteSummary={siteSummary} />
          </div>
        </>
      )}
    </div>
  );
};

export default AssessmentSummary;
