import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { uploadImage } from "../../utils/uploadImage";
// import Editor from "../components/Editor";
import Input from "../common/Input";
import Select from "../common/Select";
// import { PRIORITY_OPTIONS } from "../data/priority";
import auth from "../../services/authService";
import { BUILDING_TYPES } from "../../data/buildingType";
import PhotoPreviewForForms from "../common/PhotoPreviewForForms";
import FileInput from "../common/FileInput";
import { uploadFile } from "../../utils/uploadFile";

// Validation Schema
const validate = Yup.object({
  yearBuild: Yup.number()
    .min(1900, "Should not be less than 1900")
    .max(3000, "Should not be more than 3000"),
  // areaUnits: Yup.number(),
  classRooms: Yup.number(),
});

// write this year date in year format
const thisYear = new Date().getFullYear();

const BuildingForm = ({
  data,
  onSaveBuilding,
  isEditing,
  onCancel,
  preloadData,
  onMapEdit,
  buildingArea,
  clickedBuildingInteriorArea,
}) => {
  const [images, setImages] = useState(data ? data.imgUrls : []);
  const [pdfFiles, setPDFFiles] = useState(data ? data.files : []);
  // eslint-disable-next-line no-unused-vars
  const [note, setNote] = useState(data ? data.note : "");
  const [unit, setUnit] = useState(data && data.unit ? data.unit : "sqft");

  const initialValues = {
    schoolName: "",
    buildingID: "",
    buildingName: "",
    buildingType: "",
    classRooms: "",
    yearBuild: "",
    age: "",
    sfpModDate: "0",
    eligibleDate: "",
    buildingArea: clickedBuildingInteriorArea, // interior area(building area)
    // system: "",
    // subSystem: "",
    // action: "",
    // actionItem: "",
    // note: "",
    areaUnits: buildingArea, // roof area
    // constructionCost: "",
    // softCost: "",
    // priority: "",
  };

  const formik = useFormik({
    initialValues: data ? data : initialValues,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit,
  });

  function onSubmit(values) {
    // values.note = note;
    values.imgUrls = images;
    values.user = auth.getCurrentUser().id;
    values.unit = unit;
    // values.files = values?.files?.concat(pdfFiles);
    if (buildingArea) values.areaUnits = buildingArea;
    // values.totalCost = totalCost;

    onSaveBuilding(values);
  }

  const onChooseFile = async (e) => {
    const images = await uploadImage(e.target.files);
    setImages(images);
  };
  const onChoosePDF = async (e) => {
    const file = await uploadFile(e.target.files[0]);
    let newFiles = pdfFiles.push(file[0]);
    setPDFFiles(newFiles);
  };

  // eslint-disable-next-line no-unused-vars
  function getOptions(parentValue, childValues) {
    if (parentValue === "" || childValues.length === 0) return [];

    const result = childValues.find(
      (c) =>
        c?.parent === parentValue ||
        parentValue.toLowerCase().includes(c?.parent)
    );
    return result?.value;
  }

  // eslint-disable-next-line no-unused-vars
  const handleUnitChange = (e) => {
    setUnit(e.target.value);
  };

  const getEligibleDate = (buildingType, yearBuild, sfpModDate) => {
    if (
      buildingType === "Permanent" &&
      (sfpModDate === 0 || sfpModDate === "")
    ) {
      return Number(yearBuild) + 25;
    }
    if (
      buildingType === "Permanent" &&
      (sfpModDate !== 0 || sfpModDate !== "")
    ) {
      return Number(sfpModDate) + 25;
    }
    if (
      buildingType === "Portable" &&
      (sfpModDate === 0 || sfpModDate === "")
    ) {
      return Number(yearBuild) + 20;
    }
    if (
      buildingType === "Portable" &&
      (sfpModDate !== 0 || sfpModDate !== "")
    ) {
      return Number(sfpModDate) + 20;
    }
    return 0;
  };

  return (
    <>
      {/* <div className="d-flex justify-content-between"> 
      <h4 className="mb-3">Building Information</h4>
      <div>
      {onCancel && (
            <button
              onClick={onCancel}
              className="btn btn-sm btn-custom-primary"
              type="button"
            >
              Details List
            </button>
          )} 
      </div>
     </div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full h-full flex flex-col justify-center align-middle">
          <div className="flex flex-col border-2 border-200 mb-2 rounded-lg pt-4 w-full h-full">
            <div className="flex flex-col flex-grow w-full justify-center px-2">
              <div className="flex flex-row w-full justify-between">
                {" "}
                <Input
                  label={"Building Name"}
                  name="buildingName"
                  error={formik.errors.buildingName}
                  touched={formik.touched.buildingName}
                  value={formik.values.buildingName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Select
                  options={BUILDING_TYPES}
                  label={"Building Type"}
                  name="buildingType"
                  classes="mb-custom-3"
                  error={formik.errors.buildingType}
                  touched={formik.touched.buildingType}
                  value={formik.values.buildingType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Input
                  label={"Classrooms"}
                  name="classRooms"
                  error={formik.errors.classRooms}
                  touched={formik.touched.classRooms}
                  value={formik.values.classRooms}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  min={0}
                />
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
              <div className="flex flex-row w-full justify-between">
                <Input
                  label={"Date Constructed"}
                  name="yearBuild"
                  error={formik.errors.yearBuild}
                  touched={formik.touched.yearBuild}
                  value={formik.values.yearBuild}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  smallinput={true}
                />
                <Input
                  label={"Age"}
                  name="age"
                  error={formik.errors.age}
                  touched={formik.touched.age}
                  value={thisYear - formik.values.yearBuild}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  smallinput={true}
                  disabled={true}
                />
                <Input
                  label={"Modernization Date"}
                  name="sfpModDate"
                  error={formik.errors.sfpModDate}
                  touched={formik.touched.sfpModDate}
                  value={formik.values.sfpModDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  smallinput={true}
                />
                <Input
                  label={"Eligible Date"}
                  name="yearBuild"
                  error={formik.errors.yearBuild}
                  touched={formik.touched.yearBuild}
                  value={
                    getEligibleDate(
                      formik.values.buildingType,
                      formik.values.yearBuild,
                      formik.values.sfpModDate
                    ) < 1900
                      ? ""
                      : getEligibleDate(
                          formik.values.buildingType,
                          formik.values.yearBuild,
                          formik.values.sfpModDate
                        )
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  min={1900}
                  smallinput={true}
                  disabled={true}
                />

                {/* <Input
              label={"Images"}
              type="file"
              onChange={onChooseFile}
              multiple
              // error={imageErrors}
              // touched={imageErrors !== ""}
            /> */}
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
            </div>
            <div className="flex flex-row justify-start pb-2">
              <Input
                label={"Building Area sqft"}
                name="areaUnits"
                error={formik.errors.areaUnits}
                touched={formik.touched.areaUnits}
                value={clickedBuildingInteriorArea}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                min={1900}
                disabled={true}
              />
              <Input
                label={"Roof Area sqft"}
                name="roofArea"
                error={formik.errors.roofArea}
                touched={formik.touched.roofArea}
                value={buildingArea}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
                min={1900}
                disabled={true}
              />
              <FileInput
                label={"Images"}
                showLabel={true}
                name="images"
                type="file"
                onChange={onChooseFile}
              />
              <PhotoPreviewForForms data={images} setData={setImages} />
            </div>

            <br />
          </div>
          <div className="flex flex-row justify-end  flex-nowrap max-w-5xl ">
            {/* {onCancel && (
              <button
                onClick={onCancel}
                className="mx-3 px-3 modal-btn-custom btn-light"
                type="button"
              >
                Details List
              </button>
            )} */}

            <button
              className="w-3/5 bg-700 text-white h-10 rounded-md font-medium"
              type="submit"
            >
              {isEditing ? "Update" : "Add Building"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default BuildingForm;
