import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import roomService from "../services/roomService";
import Table from "./common/Table";
import { ROOM_COLUMNS } from "./../data/roomColumns";

const SchoolMapRoomsList = ({ buildingID }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rooms, setRooms] = useState([]);

  const loadResource = useCallback(
    async function () {
      try {
        const { data: res } = await roomService.getRoomsByBuilding(buildingID);

        setRooms(res.data);
      } catch (error) {
        toast.error("Something went wrong while loading rooms.");
      }

      setIsLoading(false);
    },
    [buildingID]
  );

  useEffect(() => {
    loadResource();
  }, [loadResource]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div
      className="py-3 px-5 m-1"
      style={{ height: "84vh", overflowY: "auto" }}
    >
      <h6 className="text-center mb-4">Rooms</h6>
      <Table tableColumns={ROOM_COLUMNS} tableData={rooms} />
    </div>
  );
};

export default SchoolMapRoomsList;
