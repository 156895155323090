import React from "react";

const DashboardDataSummaryItem = ({ label, value, isTotal }) => {
  return (
    <div className={`dataSummaryItem ${isTotal && "dataSummaryItem__total"}`}>
      <span>{label}</span>
      <span>{value ? getValue(value) : "-"} </span>
    </div>
  );
};

function getValue(value) {
  return (
    "$" + parseInt(value.toString().replace(/\D/g, ""), 10).toLocaleString()
  );
}

export default DashboardDataSummaryItem;
