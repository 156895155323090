import React, { useState, useEffect, useCallback } from "react";

import {
  faCity,
  faCloudUpload,
  faEnvelope,
  faUserPen,
  faKey,
  faTrashCan,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import image from "../assets/img-placeholder.jpg";

import Modal from "./common/Modal";

import "react-image-crop/dist/ReactCrop.css";
import CropProfilePicture from "./CropProfilePicture";
import userService from "../services/userService";
import districtService from "../services/districtService";

const ProfileInfo = ({
  onChangePassword,
  userInfo,
  setUserInfo,
  onEditUser,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [district, setDistrict] = useState(null);

  const loadDistrict = useCallback(async () => {
    if (userInfo && userInfo.types === "district") {
      const { data: res } = await districtService.getDistrictById(
        userInfo.districtID
      );
      setDistrict(res.data);
    }
  }, [userInfo]);

  useEffect(() => {
    loadDistrict();
  }, [loadDistrict]);

  async function handleDeleteProfile() {
    await userService.update({ image: "" });
  }

  const getOrgDistrict = () => {
    if (userInfo && userInfo.types === "district" && district)
      return district.name;

    if (userInfo && userInfo.types !== "district") return userInfo.orgName;

    return "Organization";
  };

  return (
    <>
      <div className="account-info mt-10 bg-white">
        <div
          style={{
            position: "relative",
          }}
          className="rounded-full"
        >
          <img
            src={(userInfo && userInfo.image) || image}
            alt=""
            className="rounded-full h-36 w-36"
          />

          <div className="profile-picutre-overlay rounded-full">
            <button
              className="profile-overlay-btn overlay-btn-primary my-2"
              onClick={() => setIsModalOpen(true)}
            >
              <FontAwesomeIcon icon={faCloudUpload} />
            </button>

            <button
              className="profile-overlay-btn overlay-btn-delete my-2"
              onClick={handleDeleteProfile}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        </div>

        <div>
          <div className="text-xl text-700">
            {userInfo ? userInfo.name : "Full Name"}
          </div>
          <div className="profile-details my-3">
            <div className="profile-info-box flex flex-row align-middle justify-center mr-8">
              <div className="border-gray-200 border-1 w-8 h-8 flex flex-col justify-center rounded-full mr-2">
                <FontAwesomeIcon
                  icon={faUser}
                  fontSize="18px"
                  className="text-700"
                />
              </div>
              <div className="flex flex-col justify-center text-sm font-normal">
                {userInfo ? userInfo.name : "Full Name"}
              </div>
            </div>
            <div className="profile-info-box flex flex-row align-middle justify-center mr-8">
              <div className="border-gray-200 border-1 w-8 h-8 flex flex-col justify-center rounded-full mr-2">
                <FontAwesomeIcon
                  icon={faCity}
                  fontSize="18px"
                  className="text-700"
                />
              </div>
              <div className="flex flex-col justify-center text-sm font-normal">
                {getOrgDistrict()}
              </div>
            </div>
            <div className="profile-info-box flex flex-row align-middle justify-center mr-8">
              <div className="border-gray-200 border-1 w-8 h-8 flex flex-col justify-center rounded-full mr-2">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  fontSize="18px"
                  className="text-700"
                />
              </div>
              <div className="flex flex-col justify-center text-sm font-normal">
                {userInfo ? userInfo.email : "john.doe@email.com"}
              </div>
            </div>
          </div>
          {/* <button
            type="button"
            className="modal-btn-custom btn-custom-primary"
            onClick={onChangePassword}
          >
            <FontAwesomeIcon icon={faKey} /> &nbsp; Change password
          </button> */}
        </div>

        <div className="profile__actionbuttons">
          <span
            title="Edit User I"
            className="profile__actionbuttons_item mr-2 border-1 border-gray-200 w-10 h-10 rounded-md"
            role="button"
            onClick={onEditUser}
          >
            <FontAwesomeIcon icon={faUserPen} className="text-700" />
          </span>
          <span
            className="profile__actionbuttons_item mr-2 border-1 border-gray-200 w-10 h-10 rounded-md"
            role="button"
            onClick={onChangePassword}
          >
            <FontAwesomeIcon icon={faKey} className="text-700" />
          </span>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <CropProfilePicture
          setIsModalOpen={setIsModalOpen}
          setUserInfo={setUserInfo}
        />
      </Modal>
    </>
  );
};

export default ProfileInfo;
