// import http from "../services/httpService";
import axios from "axios";
import config from "../config/config.json";
import { toast } from "react-toastify";

export const uploadFile = async (file) => {
  const fileUrls = [];
  if (!file) return console.log("Select diagam and try again!");

  const data = new FormData();
  data.append("file", file);
  data.append("upload_preset", "schoolfacilitypro");

  try {
    const { data: res_data } = await toast.promise(
      axios.post(config.cloudinaryUrlFile, data),
      {
        pending: `Uploading File: ${file.name}`,
        success: "Uploaded Successfully",
        error: "Uploading failed, please try again.",
      }
    );
    fileUrls.push(res_data.secure_url);
  } catch (error) {
    if (error.response) console.log(error.response.data.error.message);
  }
  return fileUrls;
};
