import React from "react";

const SchoolMapTextShape = ({ node, onClickShape, onDoubleClickShape }) => {
  return (
    <g
      key={node.name}
      className="node"
      id={node.name}
      style={{
        transform: `translate( ${node.x}  + ${node.y} )`,
        pointerEvents: "all",
      }}
    >
      {/* <rect x={node.x} y={node.y} width={72} height={72} fill={node?.color} /> */}

      <text
        x={node.x}
        y={node.y}
        width={72}
        dominantBaseline="middle"
        textAnchor="middle"
        onClick={() => {
          onClickShape(node);
        }}
        // onDoubleClick={() => onDoubleClickShape(node)}
        style={{ pointerEvents: "all", fontSize: node.fontSize }}
        fill={node.color}
      >
        {node.name}
      </text>
    </g>
  );
};

export default SchoolMapTextShape;
