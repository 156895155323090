import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

function Select({
  options,
  label,
  classes,
  icon,
  iconClasses,
  error,
  touched,
  addBtn,
  onClickAddBtn,
  disabled,
  value,
  smallinput = false,
  fullWidth = false,
  ...props
}) {
  return (
    <div className={`${classes} mx-4 mb-4`}>
      <div className="d-flex justify-content-between flex-column gap-2">
        <span className="input__label">{label}</span>

        <select
          className={`normalize-text custom-select ${
            smallinput ? "w-40" : "w-56"
          } `}
          // className={`${smallinput ? "w-40" : "w-56"} normalize-text ${
          //   showBtn ? "custom-input-1" : "custom-input"
          // } `}
          value={value ? value : label}
          autoComplete="off"
          disabled={disabled}
          {...props}
        >
          <option value={label} disabled>
            {label}
          </option>
          {options &&
            options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}

          {/* <option value="1">one</option> */}
        </select>

        {addBtn && (
          <div
            role="button"
            onClick={!!disabled ? () => {} : onClickAddBtn}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faPlus} />
          </div>
        )}
      </div>
      <div className="normalize-text text-danger px-2 error-text">
        {touched && error ? error : " "}
      </div>
    </div>
  );
}

export default Select;
