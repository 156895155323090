import React from "react";
import GridLayoutItem from "./GridLayoutItem";
import TableUtillButtons from "./TableUtillButtons";

const DataOnGridLayout = ({
  data,
  saveData,
  filtersStatus,
  filtersValue,
  setIsDeleteDialogOpen,
  setRecordTodelete,
  resourceToLoad,
  onEdit,
  setRecordToEdit,
  onGridItemClicked,
  setResourceToDelete,
}) => {
  return (
    <div
      style={{
        height: "77.4vh",
        overflow: "auto",
        margin: "0 4px",
      }}
    >
      <div
        style={{
          paddingLeft: "3%",
        }}
      >
        <TableUtillButtons
          saveData={saveData}
          filtersStatus={filtersStatus}
          filtersValue={filtersValue}
          featureData={data}
          resourceToLoad={resourceToLoad}
        />

        {Array.isArray(data) && data.length === 0 && !filtersValue.district && (
          <h4 className="m-4">No Districts</h4>
        )}

        {Array.isArray(data) && data.length === 0 && filtersValue.district && (
          <h4 className="m-4">No sites, in the selected district</h4>
        )}
      </div>
      <div className="data-on-grid-container">
        {Array.isArray(data) &&
          data.map((record) => (
            <GridLayoutItem
              key={record._id}
              record={record}
              onEdit={() => {
                setRecordToEdit(record);
                onEdit();
              }}
              onDelete={() => {
                setResourceToDelete(resourceToLoad);
                setRecordTodelete(record);
                setIsDeleteDialogOpen(true);
              }}
              onGridItemClicked={onGridItemClicked}
            />
          ))}
      </div>
    </div>
  );
};

export default DataOnGridLayout;
