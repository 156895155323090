import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileChartPie } from "@fortawesome/pro-regular-svg-icons";
import { getDistrictCapacity } from "../services/districtService";
import { DISTRICT_CAPACITY_COLUMNS } from "../data/districtCapacityColumn";
import Table from "./common/Table";

const DistrictCapacityReport = ({ districtID }) => {
  const [capacities, setCapacity] = useState([]);
  useEffect(() => {
    const fetchDistrictCapacity = async () => {
      const { data } = await getDistrictCapacity(districtID);
      setCapacity(data.data);
    };
    fetchDistrictCapacity();
  }, [districtID]);
  return (
    <div className="d-flex flex-column" style={{ overflowY: "hidden" }}>
      {/* <Navbar user={user} /> */}
      <div className="mx-8 mb-4">
        <div className="flex flex-row justify-between text-center my-4 ml-2">
          {/* <span className="w-40">{rooms[0]?.schoolName}</span> */}
          <span className="font-Rubik font-semibold">
            Pixley Union Capacity Summary Report
          </span>
          <span>
            <button
              className="flex justify-center bg-700 text-white p-2 px-3 rounded-md ml-2 text-sm"
              // onClick={onPrint}
            >
              <span>
                <FontAwesomeIcon icon={faFileChartPie} fontSize="18px" />
              </span>
              <span className="ml-2">Generate Report</span>
            </button>
          </span>
        </div>
        <Table
          tableData={capacities}
          tableColumns={DISTRICT_CAPACITY_COLUMNS}
        />
      </div>
    </div>
  );
};

export default DistrictCapacityReport;
