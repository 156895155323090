import { faMars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const DashboardInfoBlock = ({
  label,
  value,
  icon,
  isLastItem,
  iconColor,
  iconBackground,
}) => {
  const style = {};

  if (iconColor) style.color = iconColor;

  if (iconBackground) style.backgroundColor = iconBackground;

  return (
    <div className={`infoBlock ${!isLastItem ? "seprator" : ""}`}>
      <div className="infoBlock__iconContainer" style={style}>
        <FontAwesomeIcon icon={icon ? icon : faMars} />
      </div>
      <div className="infoBlock__details">
        <h6>{label}</h6>
        <span>{value}</span>
      </div>
    </div>
  );
};

export default DashboardInfoBlock;
