import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Select from "../common/Select";
import Editor from "../Editor";
import FileInput from "../common/FileInput";
import Input from "../common/Input";
import { uploadImage } from "../../utils/uploadImage";
import { BUILDING_PRIORITY_OPTIONS } from "../../data/buildingDetailPriority";
import {
  handleCostChange,
  handleSoftCostChange,
} from "../../utils/commonUtils";
import PhotoPreviewForForms from "../common/PhotoPreviewForForms";

// Validation Schema
const validate = Yup.object({
  system: Yup.string()
    .min(2, "Must be 2 characters or more")
    .required("Required"),
  // subSystem: Yup.string().required("Required"),
  yearBuild: Yup.number()
    .min(1900, "Should not be less than 1900")
    .max(3000, "Should not be more than 3000"),
  areaUnits: Yup.number(),
});

const initialValues = {
  system: "",
  subSystem: "",
  note: "",
  constructionCost: "",
  softCost: "",
  areaUnits: "",
  priority: "",
};

const RoomDetailForm = ({ data, preloadData, onAddDetail }) => {
  const [note, setNote] = useState(data ? data.note : "");
  const [totalCost, setTotalCost] = useState(data ? data.totalCost : "");
  const [images, setImages] = useState(data ? data.imgUrls : []);

  const formik = useFormik({
    initialValues: data ? data : initialValues,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit,
  });

  function onSubmit(values) {
    values.note = note;
    values.totalCost = totalCost;
    values.imgUrls = images;

    onAddDetail(values);
  }

  const onChooseFile = async (e) => {
    const images = await uploadImage(e.target.files);
    setImages(images);
  };

  // eslint-disable-next-line no-unused-vars
  function getOptions(parentValue, childValues) {
    if (parentValue === "" || childValues.length === 0) return [];

    const result = childValues.find(
      (c) =>
        c?.parent === parentValue ||
        parentValue.toLowerCase().includes(c?.parent)
    );
    return result?.value;
  }

  const printTotalCost = () => {
    // return totalCost;
    const newTotalCost = totalCost + "";
    if (newTotalCost.length > 0)
      return (
        "$" + parseInt(newTotalCost.replace(/\D/g, ""), 10).toLocaleString()
      );

    return "$" + newTotalCost;
  };

  return (
    <>
      <h4 className="mb-3">Task</h4>
      <form onSubmit={formik.handleSubmit}>
        <Editor data={note} onChange={setNote} />
        <div className="w-full h-full flex flex-col justify-center align-middle">
          <div className="flex flex-col border-2 border-200 mb-2 rounded-lg pt-4 w-full h-full">
            <div className="flex flex-col flex-grow w-full justify-center px-2">
              <div className="flex flex-row w-full justify-between">
                <Select
                  options={preloadData.system}
                  label={"System"}
                  name="system"
                  classes="mb-custom-3"
                  error={formik.errors.system}
                  touched={formik.touched.system}
                  value={formik.values.system}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={preloadData.system.length === 0}
                  // addBtn={true}
                  // onClickAddBtn={onAddSystem}
                />

                {/* <Select
          options={
            formik.values.system &&
            getOptions(formik.values.system, preloadData.subSystem)
          }
          label={"Sub System"}
          name="subSystem"
          classes="mb-custom-3"
          error={formik.errors.subSystem}
          touched={formik.touched.subSystem}
          value={formik.values.subSystem}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={!formik.values.system}
          addBtn={true}
          onClickAddBtn={onAddSubSystem}
        /> */}

                <Input
                  label={"Construction Cost"}
                  name="constructionCost"
                  error={formik.errors.constructionCost}
                  touched={formik.touched.constructionCost}
                  value={formik.values.constructionCost}
                  onChange={(e) => handleCostChange(e, formik, setTotalCost)}
                  onBlur={formik.handleBlur}
                />

                <Input
                  label={"Soft Cost"}
                  name="softCost"
                  error={formik.errors.softCost}
                  touched={formik.touched.softCost}
                  value={formik.values.softCost}
                  onChange={(e) =>
                    handleSoftCostChange(e, formik, setTotalCost)
                  }
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
              <div className="flex flex-row w-full justify-between">
                <Input
                  label={"Total Cost"}
                  name="totalCost"
                  value={printTotalCost()}
                  readOnly
                />

                <Select
                  options={BUILDING_PRIORITY_OPTIONS}
                  label={"Priority"}
                  name="priority"
                  classes="mb-custom-3"
                  error={formik.errors.priority}
                  touched={formik.touched.priority}
                  value={formik.values.priority}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // disabled={preloadData.priority.length === 0}
                />

                <Input
                  label={"Area/Units"}
                  name="areaUnits"
                  error={formik.errors.areaUnits}
                  touched={formik.touched.areaUnits}
                  value={formik.values.areaUnits}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  min={0}
                />
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
            </div>
            <div className="flex flex-row justify-start mb-2">
              <FileInput
                label={"Images"}
                showLabel={true}
                name="images"
                type="file"
                onChange={onChooseFile}
              />
              <PhotoPreviewForForms data={images} setData={setImages} />
            </div>
          </div>

          <div className="flex flex-row justify-end  flex-nowrap max-w-5xl">
            <button
              className="w-3/5 bg-700 text-white h-10 rounded-md font-medium"
              type="submit"
            >
              {data ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RoomDetailForm;
