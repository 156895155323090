const LoadingDots = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: "100vw", height: "85vh" }}
    >
      <div className="loading__dots"></div>
    </div>
  );
};

export default LoadingDots;
