import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../common/Select";

import { uploadImage } from "../../utils/uploadImage";
import Input from "../common/Input";
import FileInput from "../common/FileInput";
import auth from "../../services/authService";
import { addressToLatLng } from "../../utils/geocode";
// import { uploadFile } from "../../utils/uploadFile";
import PhotoPreviewForForms from "../common/PhotoPreviewForForms";

const grade_levels = ["Elementary", "Middle", "Junior", "High", "Others"];

// Validation Schema
const validate = Yup.object({
  schoolName: Yup.string()
    .max(40, "Must be 40 characters or less")
    .min(2, "Must be 2 characters or more")
    .required("Required"),
  gradeLevel: Yup.string()
    .max(40, "Must be 40 characters or less")
    .min(2, "Must be 2 characters or more")
    .required("Required"),
  yearBuild: Yup.number()
    .min(1900, "Should not be less than 1900")
    .max(3000, "Should not be more than 3000"),
});

const initialValues = {
  schoolName: "",
  address: "",
  yearBuild: "",
  telephone: "",
  web: "",
  gradeLevel: "",
};

const AddSite = ({
  filters,
  formId,
  onRequestClose,
  feature,
  saveData,
  overlay,
  data,
}) => {
  const [places, setPlaces] = useState([]);
  const [addresss, setAddresss] = useState(
    data && data.address ? data.address : ""
  );
  const [districtCoord, setDistrictCoord] = useState(
    data && data.coord ? data.coord : null
  );

  const [images, setImages] = useState(data ? data.imgUrls : []);

  // const [diagram1, setDiagram1] = useState(data ? data.diagram1 : "");
  // const [diagram2, setDiagram2] = useState(data ? data.diagram2 : "");

  const formik = useFormik({
    initialValues: data ? data : initialValues,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit,
  });

  async function onSubmit(values) {
    // if (images.length === 0) return setImageErrors("Required");

    if (addresss) values.address = addresss;
    if (addresss && districtCoord) values.coord = districtCoord;

    values.imgUrls = images;
    values.districtID = filters.district;
    values.user = auth.getCurrentUser().id;
    if (feature) values.feature = feature;
    if (overlay) values.feature = overlay;
    // if (diagram1) values.diagram1 = diagram1;
    // if (diagram2) values.diagram2 = diagram2;

    // return console.log(values);

    saveData(values, "site", data ? data._id : null);

    onRequestClose();
  }

  const onChooseFile = async (e) => {
    const images = await uploadImage(e.target.files);
    setImages(images);
  };
  // const onChooseDiagram1 = async (e) => {
  //   const { data: diagram } = await uploadFile(e.target.files[0]);
  //   setDiagram1(diagram.secure_url);
  //   console.log(diagram.secure_url);
  // };
  // const onChooseDiagram2 = async (e) => {
  //   const { data: diagram } = await uploadFile(e.target.files[0]);
  //   setDiagram2(diagram.secure_url);
  //   console.log(diagram.secure_url);
  // };

  function handlePhoneNumberChange(e) {
    if (
      e.target.value &&
      e.target.value.length === 3 &&
      e.target.value[0] !== "("
    ) {
      e.target.value = `(${e.target.value})`;
    }

    if (
      e.target.value &&
      e.target.value.length === 4 &&
      e.target.value[3] !== ")"
    ) {
      e.target.value = `${e.target.value})`;
    }

    if (
      e.target.value &&
      e.target.value.length === 8 &&
      e.target.value[7] !== "-"
    ) {
      e.target.value = `${e.target.value}-`;
    }
    // e.target = newTarget;

    if (e.target.value && e.target.value.length > 13) return;

    formik.handleChange(e);
  }

  function handleAddressChange() {
    addressToLatLng(addresss, setPlaces);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} id={formId}>
        <div className="w-full h-full flex flex-col justify-center align-middle">
          <div className="flex flex-col border-2 border-200 mb-3 rounded-lg pt-4 w-full h-full">
            <div className="flex flex-col flex-grow w-full justify-center px-2">
              <div className="flex flex-row w-full justify-between">
                {" "}
                <Input
                  label={"School Name"}
                  name="schoolName"
                  error={formik.errors.schoolName}
                  touched={formik.touched.schoolName}
                  value={formik.values.schoolName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="position-relative">
                  <Input
                    label={"Address"}
                    name="address"
                    type="text"
                    autoComplete={"off"}
                    // error={formik.errors.address}
                    // touched={formik.touched.address}
                    value={addresss}
                    onChange={(e) => {
                      setAddresss(e.target.value);
                      // handleAddressChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    showBtn={true}
                    btnLabel="Geocode"
                    onBtnClick={handleAddressChange}
                  />

                  {places.length > 0 && (
                    <div className="places__list">
                      {places.map((place) => (
                        <div
                          key={place.place_id}
                          className="places__list__item"
                          onClick={() => {
                            setAddresss(place?.formatted_address);
                            setDistrictCoord([
                              +place.geometry.location.lat,
                              +place.geometry.location.lng,
                            ]);
                            setPlaces([]);
                          }}
                        >
                          <img
                            src="/placeholder.png"
                            alt="Placeholder"
                            style={{ width: 18, height: 18 }}
                          />
                          &nbsp;&nbsp;{place?.formatted_address}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <Input
                  label={"Current Enrollment"}
                  name="currentEnrollment"
                  error={formik.errors.currentEnrollment}
                  touched={formik.touched.currentEnrollment}
                  value={formik.values.currentEnrollment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                />
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
              <div className="flex flex-row w-full justify-between">
                <Input
                  label={"Telephone"}
                  name="telephone"
                  error={formik.errors.telephone}
                  touched={formik.touched.telephone}
                  value={formik.values.telephone}
                  onChange={handlePhoneNumberChange}
                  onBlur={formik.handleBlur}
                />
                <Input
                  label={"Web Address"}
                  name="web"
                  error={formik.errors.web}
                  touched={formik.touched.web}
                  value={formik.values.web}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Select
                  options={grade_levels}
                  label={"School Type"}
                  name="gradeLevel"
                  classes="mb-custom-3"
                  error={formik.errors.gradeLevel}
                  touched={formik.touched.gradeLevel}
                  value={formik.values.gradeLevel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
              <div className="flex flex-row justify-start mb-4">
                <FileInput
                  label={"Images"}
                  showLabel={true}
                  name="images"
                  type="file"
                  onChange={onChooseFile}
                />
                <PhotoPreviewForForms data={images} setData={setImages} />
              </div>
            </div>
            {/* <Input
              label={"Current enrollment"}
              name="yearBuild"
              error={formik.errors.yearBuild}
              touched={formik.touched.yearBuild}
              value={formik.values.yearBuild}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="number"
              min={1900}
            />
            <Input
              label={"Telephone"}
              name="telephone"
              error={formik.errors.telephone}
              touched={formik.touched.telephone}
              value={formik.values.telephone}
              onChange={handlePhoneNumberChange}
              onBlur={formik.handleBlur}
            />
            <Input
              label={"Web Address"}
              name="web"
              error={formik.errors.web}
              touched={formik.touched.web}
              value={formik.values.web}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Select
              options={grade_levels}
              label={"School Type"}
              name="gradeLevel"
              classes="mb-custom-3"
              error={formik.errors.gradeLevel}
              touched={formik.touched.gradeLevel}
              value={formik.values.gradeLevel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            <Input
              label={"Images"}
              type="file"
              onChange={onChooseFile}
              multiple
            />{" "}
            */}{" "}
          </div>
        </div>
        <div className="flex flex-row justify-between  flex-nowrap max-w-5xl space-x-2">
          <button
            onClick={onRequestClose}
            className="w-6/12 box-border border-1 rounded-md border-700 h-10 text-700 mb-4"
          >
            Cancel
          </button>
          <button
            type="submit"
            form={formId}
            className="w-6/12 bg-700 text-white h-10 rounded-md font-medium "
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default AddSite;
