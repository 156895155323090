import React from "react";
import logo from "../../assets/Icon_only_transparent.svg";

function Cockpit() {
  return (
    <>
      <div className="logo-container">
        <img src={logo} alt="" className="logo" />
      </div>
      <div className="cockpit d-flex flex-column justify-content-center align-items-center">
        <img
          className="feature-photo-container mb-5"
          id="signin__feature_photo"
          src={logo}
          alt=""
          style={{
            objectFit: "contain",
          }}
        />
        <div className="slogan">School Facility Pro</div>
      </div>
    </>
  );
}

export default Cockpit;
