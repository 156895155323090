import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

const apiEndPoint = config.apiEndPoint + "/rooms";

export const saveRoom = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(http.put(`${config.apiEndPoint1}/rooms/${id}`, data), {
      pending: "Updating Room...",
      success: "Room Updated Successfully!",
      error: "Failed, please try again.",
    });
  }

  return toast.promise(http.post(`${config.apiEndPoint1}/rooms`, data), {
    pending: "Adding Room...",
    success: "Room Added Successfully!",
    error: "Failed, please try again.",
  });
};

export const getRooms = () => {
  return http.get(apiEndPoint);
};

export const getRoomsByBuilding = (buildingID) => {
  return http.get(`${config.apiEndPoint1}/rooms/${buildingID}/allrooms`);
};

export const getRoomsBySite = (siteID) => { // only rooms types CR and LAB
  return http.get(`${config.apiEndPoint1}/rooms/${siteID}/allsiterooms`);
};
export const getRoomsCapacityBySite = (siteID) => {
  return http.get(`${config.apiEndPoint1}/rooms/capacity/${siteID}`);
};

export const getRoomById = (id) => {
  return http.get(`${config.apiEndPoint1}/rooms/${id}/`);
};

export const deleteRoom = (id) => {
  return toast.promise(http.delete(`${config.apiEndPoint1}/rooms/${id}`), {
    pending: "Deleting Room...",
    success: "Room Deleted Successfully!",
    error: "Room has already been deleted",
  });
};

export const getPreloadData = () => {
  return http.get(`${config.apiEndPoint1}/roompreload/roompreloads`);
};

export const updatePreload = (data, id) => {
  // return http.put(`${config.apiEndPoint1}/buildingpreload/${id}`);
  return toast.promise(
    http.put(`${config.apiEndPoint1}/roompreload/${id}`, data),
    {
      pending: "Updating Preload...",
      success: "Preload Updated Successfully!",
      error: "Failed, please try again.",
    }
  );
};

export const getRoomsCount = () => {
  return http.get(config.apiEndPoint1 + "/rooms/count");
};

const roomService = {
  saveRoom,
  getRooms,
  getRoomsByBuilding,
  deleteRoom,
  getPreloadData,
  updatePreload,
  getRoomsBySite,
  getRoomsCount,
  getRoomById,
};

export default roomService;
