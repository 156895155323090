import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

// const apiEndPoint = config.apiEndPoint + "/buildings";

export const saveBuilding = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(
      http.put(`${config.apiEndPoint1}/buildings/${id}`, data),
      {
        pending: "Updating Building...",
        success: "Building Updated Successfully!",
        error: "Failed, please try again.",
      }
    );
  }

  return toast.promise(http.post(`${config.apiEndPoint1}/buildings`, data), {
    pending: "Adding Building...",
    success: "Building Added Successfully!",
    error: "Failed, please try again.",
  });
};
export const getBuildings = () => {
  return http.get(config.apiEndPoint1 + "/buildings");
};

export const getBuildingStatistic = (siteID) => {
  return http.get(`${config.apiEndPoint1}/buildings/statistic/${siteID}`);
};
export const getBuildingOPSCReport = (siteID) => {
  return http.get(`${config.apiEndPoint1}/buildings/opsccode/${siteID}`);
};

export const getBuildingsBySite = (siteID) => {
  return http.get(`${config.apiEndPoint1}/buildings/${siteID}/allbuildings`);
};

export const deleteBuilding = (id) => {
  return toast.promise(http.delete(`${config.apiEndPoint1}/buildings/${id}`), {
    pending: "Deleting Building...",
    success: "Building Deleted Successfully!",
    error: "Building has already been deleted",
  });
};

export const getBuildingsByID = (id) => {
  return http.get(`${config.apiEndPoint1}/buildings/${id}`);
};

export const getPreloadData = () => {
  return http.get(`${config.apiEndPoint1}/buildingpreload/buildingpreloads`);
};

export const updatePreload = (data, id) => {
  // return http.put(`${config.apiEndPoint1}/buildingpreload/${id}`);
  return toast.promise(
    http.put(`${config.apiEndPoint1}/buildingpreload/${id}`, data),
    {
      pending: "Updating Preload...",
      success: "Preload Updated Successfully!",
      error: "Failed, please try again.",
    }
  );
};

export const getBuildingsCount = () => {
  return http.get(config.apiEndPoint1 + "/buildings/count");
};

const buildingService = {
  saveBuilding,
  getBuildings,
  deleteBuilding,
  getBuildingsBySite,
  getBuildingsByID,
  getPreloadData,
  updatePreload,
  getBuildingsCount,
  getBuildingStatistic,
  getBuildingOPSCReport,
};

export default buildingService;
