import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import GlobalFilter from "../GlobalFilter";
import TableColumnsDropdown from "../TableColumnsDropdown";

const TableControls = ({
  tableColumns,
  globalFilter,
  setGlobalFilter,
  pageSize,
  setPageSize,
  pageIndex,
  pageOptions,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  gotoPage,
  pageCount,
}) => {
  return (
    // <div className="d-flex justify-content-between normalize-text my-3 align-items-center">
    //   <div>
    //     <button className="modal-btn-custom btn-custom-primary">
    //       <FontAwesomeIcon icon={faPlus} className="normalize-text" /> &nbsp;
    //       New Item
    //     </button>
    //   </div>

    <div>
      {/* Show current and total Pages */}
      <span className="ml-custom-2 text-base text-700 mx-2">
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>

      {/* Chose Columns to Show */}
      <TableColumnsDropdown label="Columns" columns={tableColumns} />

      {/* Choose page Size */}
      <select
        className="focus:border-700 border-2 border-tb-color rounded-md px-2 py-1 text-base text-700 mx-2"
        value={pageSize}
        onChange={(e) => setPageSize(e.target.value)}
      >
        {[20, 30, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>

      <div className="d-inline-block my-2 mx-6">
        {/* Go to first page */}
        <button
          className="w-8 h-8 rounded-full bg-gray-300 text-700 mx-1"
          disabled={!canPreviousPage}
          onClick={() => gotoPage(0)}
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>
        {/* Go to previous Page */}
        <button
          className="w-8 h-8 rounded-full bg-gray-300 text-700 mx-1"
          disabled={!canPreviousPage}
          onClick={previousPage}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        {/* Current Page */}
        <button className="w-8 h-8 rounded-full bg-700 text-white mx-2">
          {pageIndex + 1}
        </button>
        {/* Go to next page */}
        <button
          className="w-8 h-8 rounded-full bg-gray-300 text-700 mx-1"
          disabled={!canNextPage}
          onClick={nextPage}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
        {/* Go to last page */}
        <button
          className="w-8 h-8 rounded-full bg-gray-300 text-700 mx-1"
          disabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        >
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </div>

      {/* Global Filter */}
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
    </div>
    // </div>
  );
};

export default TableControls;
