import React from "react";
import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";

const Sidebar = ({ isOpen, onRequestClose, width, children, showBackdrop }) => {
  if (isOpen) {
    return ReactDOM.createPortal(
      <AnimatePresence>
        <motion.div className={"sidebar-wrapper"}>
          {showBackdrop && (
            <motion.div
              className="sidebar-backdrop"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 0.3,
                transition: {
                  duration: 0.3,
                },
              }}
              onClick={onRequestClose}
            />
          )}

          <motion.div
            initial={{
              width: 0,
            }}
            animate={{
              width: width ? width : 400,
              transition: {
                duration: 0.3,
              },
            }}
            className={"sidebar-box"}
          >
            <div className="sidebar-container">
              <div
                className="px-3"
                style={{
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={onRequestClose}
              >
                &times;
              </div>

              {children}
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>,
      document.getElementById("modal-root")
    );
  }

  return null;
};

export default Sidebar;
