import React, { PureComponent } from "react";
import Table from "../common/Table";
import { BUILDING_COLUMNS_DASHBOARD } from "../../data/buildingColumns";
// import { BUILDING_INVENTORY_SUMMARY_COLUMNS } from "../data/buildingInventorySummaryColumns";

export default class BuildingInventoryPrintAble extends PureComponent {
  render() {
    const buildings = this.props.buildings;
    const getSummaryTableData = this.props.getSummaryTableData;

    return (
      <div className="p-4 mx-3">
        <Table
          tableColumns={BUILDING_COLUMNS_DASHBOARD}
          tableData={buildings}
          onRowClicked={() => {}}
        />

        <div className="table-divider"></div>

        <table>
          <thead style={{ fontSize: "13px", fontWeight: "bolder" }}>
            <tr>
              <th>Building Type</th>
              <th>Area</th>
              <th>Classroom Count</th>
              <th>Average Age</th>
            </tr>
          </thead>

          <tbody style={{ fontWeight: "bold" }}>
            {getSummaryTableData()?.map((record) => {
              return (
                <tr key={record.buildingType}>
                  <td>{record.buildingType}</td>
                  <td>{record.area}</td>
                  <td>{record.classRoomCount}</td>
                  <td>
                    {!record.buildingCount || !record.totalAge
                      ? ""
                      : record.totalAge / record.buildingCount}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* <Table
          tableColumns={BUILDING_INVENTORY_SUMMARY_COLUMNS}
          tableData={getSummaryTableData()}
          onRowClicked={() => {}}
        /> */}
      </div>
    );
  }
}
