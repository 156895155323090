import React, { useState } from "react";
import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faTrashCan } from "@fortawesome/pro-regular-svg-icons";

const DeleteDialog = ({ isOpen, message, onRequestClose, onDelete }) => {
  const [confirmDelete, setConfirmDelete] = useState("");

  const proceedDelete = (e) => {
    onDelete();
    setConfirmDelete("");
  };

  if (isOpen) {
    return ReactDOM.createPortal(
      <AnimatePresence>
        <motion.div className={"dialog-wrapper"}>
          <motion.div
            className="dialog-backdrop"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 0.3,
              transition: {
                duration: 0.4,
              },
            }}
            onClick={() => {
              setConfirmDelete("");
              onRequestClose();
            }}
          />
          <motion.div
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
              transition: {
                duration: 0.4,
              },
            }}
            className={"dialog-box"}
          >
            <motion.div className="normalize-text">
              <div
                className="d-flex align-items-center flex-column py-2 normalize-text"
                style={{ height: "20rem" }}
              >
                <div>
                  <h6 className="dialog-header px-3">
                    Are you absolutely sure?
                  </h6>

                  <div className="delete-dialoge-warning px-3">
                    Unexpected bad things will happen if you don’t read this!
                  </div>

                  <p className="dialog-message px-3 py-3 my-0">{message}</p>

                  <div className="px-3 mb-4">
                    <div className="my-2">
                      Please type{" "}
                      <strong style={{ fontWeight: "900" }}>delete</strong> in
                      the box below, to confirm
                    </div>

                    <input
                      id="confirm-delete"
                      className="dialog-input"
                      type="text"
                      placeholder="Type delete here"
                      value={confirmDelete}
                      onChange={(e) => setConfirmDelete(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div>
                  <button
                    className="btn-delete-dialog btn-cancel py-2 px-4"
                    style={{
                      color: "#555",
                    }}
                    onClick={() => {
                      setConfirmDelete("");
                      onRequestClose();
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                    &nbsp; &nbsp; Cancel
                  </button>
                  <button
                    disabled={confirmDelete.toLowerCase() !== "delete"}
                    className="btn-delete-dialog btn-delete ml-custom-2 py-2 px-4"
                    onClick={
                      confirmDelete.toLowerCase() === "delete"
                        ? proceedDelete
                        : () => {}
                    }
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                    &nbsp; &nbsp; Delete
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>,
      document.getElementById("modal-root")
    );
  }

  return null;
};

export default DeleteDialog;
