import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SCHOOL_FOR_PUBLIC_COLUMNS = [
  {
    Header: "School",
    accessor: "schoolName",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Contact",
    accessor: "telephone",
  },
  {
    Header: "Diagram 1",
    accessor: (row) => {
      if (row.diagram1)
        return (
          <a href={row.diagram1} download className="download__link">
            <FontAwesomeIcon icon={faDownload} />
          </a>
        );

      return "";
    },
  },
  {
    Header: "Diagram 2",
    accessor: (row) => {
      if (row.diagram2)
        return (
          <a href={row.diagram2} download className="download__link">
            <FontAwesomeIcon icon={faDownload} />
          </a>
        );

      return;
    },
  },
];
