import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
import auth from "../../services/authService";
import jwt_decode from "jwt-decode";

const Protected = ({ children }) => {
  const navigate = useNavigate();

  // const token = auth.getJwt();
  // const { isAuthenticated, isTokenExpired } = useAuth(token);

  // useEffect(() => {
  // if (!isAuthenticated || isTokenExpired) {
  //   navigate("/signin");
  // }

  // if (!auth.getCurrentUser()) {
  //   navigate("/signin");
  // }
  // }, [navigate]);

  useEffect(() => {
    const token = auth.getJwt();

    if (token) {
      const decodedToken = jwt_decode(token);
      const expirationDate = new Date(decodedToken.exp * 1000);

      if (expirationDate <= new Date()) {
        // token is expired
        navigate("/signin");
        auth.logout();
      }

      return;
    }

    navigate("/signin");
  }, [navigate]);

  return children;
};

export default Protected;
