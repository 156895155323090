import { useState } from "react";
import SignUp from "./components/Auth/SignUp";
import SignIn from "./components/Auth/SignIn";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./components/Home";
import Profile from "./components/Profile";
import { useEffect } from "react";
import auth from "./services/authService";
import Logout from "./components/Logout";
import Protected from "./components/common/Protected";
import NotFound from "./components/NotFound";
import ResetPassword from "./components/Auth/ResetPassword";
import DistrictUserDashboard from "./components/Dashboard/DistrictUserDashboard";
import DistrictSchoolPublic from "./components/DistrictSchoolPublic";
import SchoolMap from "./components/SchoolMap";
import "react-rangeslider/lib/index.css";
import "./styles/style.css";
import { NoteContext } from "./context/NoteContext";
import Sidebar from "./components/common/Sidebar";
import Test from "./components/test";
import Pdfviewer from "./components/pdfviewer";
import CapacityReport from "./components/CapacityReport";
import EligibleBuildings from "./components/EligibleBuildings";
import DistrictCapacityReport from "./components/DistrictCapacityReport";
import DistrictAreaReport from "./components/DistrictAreaReport";
import Modal from "react-modal";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function App() {
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentNote, setCurrentNote] = useState("");
  const [isNoteOpen, setIsNoteOpen] = useState(false);

  const [user, setUser] = useState(null);

  async function loadUser() {
    const { data: res } = await auth.me();
    setUser(res.data);
  }

  useEffect(() => {
    const user = auth.getCurrentUser();

    if (user) {
      setCurrentUserId(user.id);
      loadUser();
    }
  }, []);

  return (
    <>
      <NoteContext.Provider
        value={{
          currentNote,
          setCurrentNote,
          isNoteOpen,
          setIsNoteOpen,
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate replace to="/signin" />} />
          <Route
            path="/signin"
            element={
              !currentUserId ? <SignIn /> : <Navigate replace to="/dashboard" />
            }
          />
          <Route
            path="/signup"
            element={
              !currentUserId ? <SignUp /> : <Navigate replace to="/districts" />
            }
          />

          <Route
            path="/district-schools-for-public/:districtID"
            element={<DistrictSchoolPublic />}
          />
          <Route path="/test" element={<Test />} />

          <Route
            path="/districts/*"
            element={
              <Protected>
                <Home user={user} />
              </Protected>
            }
          />

          <Route
            path="/profile"
            element={
              <Protected>
                <Profile user={user} onChangeUserInfo={setUser} />
              </Protected>
            }
          />
          <Route
            path="/pdfviewer"
            element={
              <Protected>
                <Pdfviewer />
              </Protected>
            }
          />
          <Route
            path="/capacityreport/:siteID"
            element={
              <Protected>
                <CapacityReport user={user} />
              </Protected>
            }
          />
          <Route
            path="/eligiblebuildings/:siteID"
            element={
              <Protected>
                <EligibleBuildings user={user} />
              </Protected>
            }
          />
          <Route
            path="/districtcapacity/:districtID"
            element={
              <Protected>
                <DistrictCapacityReport user={user} />
              </Protected>
            }
          />
          <Route
            path="/districtarea/:districtID"
            element={
              <Protected>
                <DistrictAreaReport user={user} />
              </Protected>
            }
          />

          <Route
            path="/dashboard/*"
            element={
              <Protected>
                {user && user.types === "organization" && (
                  <Navigate to={"/districts"} replace />
                )}

                {user && user.types === "district" && (
                  <DistrictUserDashboard user={user} />
                )}
              </Protected>
            }
          />
          <Route
            path="/school-map/:siteID"
            element={
              <Protected>
                <SchoolMap user={user} />
              </Protected>
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </NoteContext.Provider>
      <ToastContainer autoClose={1500} />
      <Sidebar
        isOpen={isNoteOpen}
        onRequestClose={() => setIsNoteOpen(false)}
        showBackdrop={true}
      >
        <h1 className="py-2 text-center">Note</h1>
        <div
          className="p-2"
          dangerouslySetInnerHTML={{
            __html: currentNote?.replaceAll("&lt;", "<"),
          }}
        ></div>
      </Sidebar>
    </>
  );
}

export default App;
