import React, { useState, useEffect } from "react";
import {
  faEdit,
  faTrashCan,
  faUserPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import image from "../assets/img-placeholder.jpg";
import districtService from "./../services/districtService";

const ProfileUsersList = ({
  onAddUser,
  usersList,
  setRecordToEdit,
  onDelete,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [districts, setDistricts] = useState([]);

  const loadResource = async () => {
    try {
      const { data: res } = await districtService.getDistricts();
      setDistricts(res.data);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    loadResource();
  }, []);

  const getDistrictName = (id) => {
    if (isLoading || districts.length === 0) return "";

    return districts.find((d) => d._id === id)?.name;
  };

  return (
    <>
      <div className="profile-secton-title">
        <div className="flex flex-row">
          <div className="mr-2 text-700 font-rubik font-normal text-base">
            Users
          </div>
          <div className="bg-700 w-6 h-6 rounded-full text-white text-center">
            {usersList ? usersList.length : 0}
          </div>
        </div>

        <button
          type="button"
          className="modal-btn-custom btn-custom-primary"
          onClick={onAddUser}
        >
          <FontAwesomeIcon icon={faUserPlus} /> &nbsp; Add User
        </button>
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="bg-700 text-white text-center font-normal text-base font-rubik">
                Photo
              </th>
              <th className="bg-700 text-white text-center font-normal text-base font-rubik">
                Full Name
              </th>
              <th className="bg-700 text-white text-center font-normal text-base font-rubik">
                Email
              </th>
              <th className="bg-700 text-white text-center font-normal text-base font-rubik">
                District
              </th>
              <th className="bg-700 text-white text-center font-normal text-base font-rubik">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {usersList &&
              usersList.map((user) => (
                <tr key={user._id} className="h-6 text-sm">
                  <td className="border border-tb-color text-center h-8 p-0">
                    <span className="profile-user-item-avatar w-12 h-12">
                      <img src={user.image ? user.image : image} alt="" />
                    </span>
                  </td>
                  <td className="border border-tb-color text-center p-0">
                    {user.name}
                  </td>
                  <td className="border border-tb-color text-center p-0">
                    {user.email}
                  </td>
                  <td className="border border-tb-color text-center p-0">
                    {getDistrictName(user.districtID)}
                  </td>
                  <td className="border border-tb-color text-center p-0">
                    <span>
                      <span
                        onClick={() => {
                          setRecordToEdit(user);
                          onAddUser();
                        }}
                        role="button"
                        className="mr-4"
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          fontSize={18}
                          className="text-700"
                        />
                      </span>
                      &nbsp; &nbsp;
                      <span
                        onClick={() => {
                          onDelete(user);
                        }}
                        role="button"
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          fontSize={18}
                          className="text-700"
                        />
                      </span>
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProfileUsersList;
