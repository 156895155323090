import React from "react";
// eslint-disable-next-line no-unused-vars
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ labels, data, backgroundColor, title }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  // [
  //   "rgba(255, 99, 132, 1)",
  //   "rgba(255, 205, 86, 1)",
  //   "rgba(54, 162, 235, 1)",
  //   "rgba(255, 159, 64, 1)",
  //   "rgba(153, 102, 255, 1)",
  // ]

  const chart_data = {
    labels,
    datasets: [
      {
        label: title,
        data,
        backgroundColor,
      },
    ],
  };

  return (
    <div
      style={{
        // width: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <div style={{ width: "300px" }}> */}
      <Doughnut data={chart_data} options={options} />
      {/* </div> */}
    </div>
  );
};

export default DoughnutChart;
