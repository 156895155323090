import React, { useEffect, useState, useCallback } from "react";
import img_placeHolder from "../assets/img-placeholder.jpg";

import img1 from "../assets/school_01.jpg";
import img2 from "../assets/school_02.jpg";
import img3 from "../assets/school_03.jpg";
import img4 from "../assets/school_04.jpg";
import basketball_court_1 from "../assets/basketball_1.jpg";
import basketball_court_2 from "../assets/basketball_2.jpg";
import basketball_court_3 from "../assets/basketball_3.jpg";
import basketball_court_4 from "../assets/basketball_4.jpg";
import buildingService from "../services/buildingService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";
import Modal from "./common/Modal";
import ModalHeaderForForms from "./ModalHeaderForForms";
import BuildingFiles from "./buildingFiles";
import RoomFiles from "./roomFiles";
import { useStore } from "../store";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPrint } from "@fortawesome/pro-regular-svg-icons";

const data = {
  school_01: {
    img: img1,
    title: "Test Data 1",
    descripion:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
  },
  school_02: {
    img: img2,
    title: "Test Data 2",
    descripion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum a arcu cursus vitae. Hac habitasse platea dictumst vestibulum rhoncus.",
  },
  school_03: {
    img: img3,
    title: "Test Data 3",
    descripion:
      "Nibh sed pulvinar proin gravida hendrerit. Lacus sed turpis tincidunt id aliquet risus feugiat in. Dictum at tempor commodo ullamcorper a lacus vestibulum sed arcu.",
  },
  school_04: {
    img: img4,
    title: "Test Data 4",
    descripion:
      "Pellentesque adipiscing commodo elit at imperdiet dui accumsan. Malesuada bibendum arcu vitae elementum curabitur vitae nunc sed velit.",
  },
  school_05: {
    img: "",
    title: "Test Data 5",
    descripion:
      "Netus et malesuada fames ac turpis egestas maecenas pharetra convallis. Ipsum dolor sit amet consectetur. In metus vulputate eu scelerisque felis imperdiet proin fermentum.",
  },
  school_06: {
    img: "",
    title: "Test Data 6",
    descripion:
      "Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Mollis nunc sed id semper risus in. Donec et odio pellentesque diam.",
  },
  school_07: {
    img: "",
    title: "Test Data 7",
    descripion:
      "Tincidunt tortor aliquam nulla facilisi. Non sodales neque sodales ut etiam sit amet. Commodo quis imperdiet massa tincidunt nunc.",
  },
  school_08: {
    img: "",
    title: "Test Data 8",
    descripion:
      "Est lorem ipsum dolor sit amet consectetur. Dui id ornare arcu odio ut sem. Euismod nisi porta lorem mollis. Enim neque volutpat ac tincidunt vitae semper quis.",
  },
  school_09: {
    img: "",
    title: "Test Data 9",
    descripion:
      "Est lorem ipsum dolor sit amet consectetur. Dui id ornare arcu odio ut sem. Euismod nisi porta lorem mollis. Enim neque volutpat ac tincidunt vitae semper quis.",
  },
  school_10: {
    img: "",
    title: "Test Data 10",
    descripion:
      "Est lorem ipsum dolor sit amet consectetur. Dui id ornare arcu odio ut sem. Euismod nisi porta lorem mollis. Enim neque volutpat ac tincidunt vitae semper quis.",
  },
  school_11: {
    img: "",
    title: "Parking",
    descripion:
      "Tsdt ulad asdkljf dfed opa sdfsdf consectetur. Dui id ornare arcu odio ut sem. Euismod nisi porta lorem mollis. Enim neque volutpat ac tincidunt vitae semper quis.",
  },
  school_12: {
    img: basketball_court_1,
    title: "Basketball Courts 1",
    descripion:
      "Tsdt ulad asdkljf dfed opa sdfsdf consectetur. Dui id ornare arcu odio ut sem. Euismod nisi porta lorem mollis. Enim neque volutpat ac tincidunt vitae semper quis.",
  },
  school_13: {
    img: basketball_court_2,
    title: "Basketball Court 2",
    descripion:
      "Tsdt ulad asdkljf dfed opa sdfsdf consectetur. Dui id ornare arcu odio ut sem. Euismod nisi porta lorem mollis. Enim neque volutpat ac tincidunt vitae semper quis.",
  },
  school_14: {
    img: basketball_court_3,
    title: "Basketball Court 3",
    descripion:
      "Tsdt ulad asdkljf dfed opa sdfsdf consectetur. Dui id ornare arcu odio ut sem. Euismod nisi porta lorem mollis. Enim neque volutpat ac tincidunt vitae semper quis.",
  },
  school_15: {
    img: basketball_court_4,
    title: "Basketball Court 4",
    descripion:
      "Tsdt ulad asdkljf dfed opa sdfsdf consectetur. Dui id ornare arcu odio ut sem. Euismod nisi porta lorem mollis. Enim neque volutpat ac tincidunt vitae semper quis.",
  },
};

const SVGMapInfoBlock = ({
  dataId,
  clickedRectDetail,
  clickedBuildingInteriorArea,
  onPrint,
}) => {
  const currentBuilding = useStore((state) => state.currentBuilding);
  const currentRoom = useStore((state) => state.currentRoom);
  const buildingRoofArea = useStore((state) => state.buildingRoofArea);
  const buildingInteriorArea = useStore((state) => state.buildingInteriorArea);
  const roomArea = useStore((state) => state.roomArea);
  console.log(currentBuilding);
  const [buildingInfo, setBuildingInfo] = useState({});
  // buillding files models
  const [filesModal, setFileModal] = useState(false);
  // room files models
  const [roomFilesModal, setRoomFileModal] = useState(false);

  const loadBuildingInfo = useCallback(async () => {
    if (dataId?.buildingID) {
      const { data: res } = await buildingService.getBuildingsByID(
        dataId.buildingID
      );
      if (res.success) setBuildingInfo(res.data);
    } else if (dataId?.id && !dataId.buildingID)
      setBuildingInfo(data[dataId.id]);
  }, [dataId]);

  const getEligibleDate = (buildingType, yearBuild, sfpModDate) => {
    if (
      buildingType === "Permanent" &&
      (sfpModDate === 0 || sfpModDate === "")
    ) {
      return Number(yearBuild) + 25;
    }
    if (
      buildingType === "Permanent" &&
      (sfpModDate !== 0 || sfpModDate !== "")
    ) {
      return Number(sfpModDate) + 25;
    }
    if (
      buildingType === "Portable" &&
      (sfpModDate === 0 || sfpModDate === "")
    ) {
      return Number(yearBuild) + 20;
    }
    if (
      buildingType === "Portable" &&
      (sfpModDate !== 0 || sfpModDate !== "")
    ) {
      return Number(sfpModDate) + 20;
    }
    return 0;
  };

  // give me the current date in year
  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  useEffect(() => {
    loadBuildingInfo();
  }, [loadBuildingInfo]);
  // const printBtn = (
  //   <div className="mb-2">
  //     <div className="schoolMap__printBtn" onClick={onPrint}>
  //       <FontAwesomeIcon icon={faPrint} fontSize={16} color="#5d6475" />
  //     </div>
  //   </div>
  // );

  if ((!dataId || !dataId.id) && !clickedRectDetail)
    return <div className="svg-map__info-block"></div>;

  function getPhoto() {
    if (dataId && dataId.id) {
      if (buildingInfo.img) return buildingInfo.img;
      else if (buildingInfo.imgUrls && buildingInfo.imgUrls[0])
        return buildingInfo.imgUrls[0];
    }

    if (clickedRectDetail && clickedRectDetail?.imgUrls?.length > 0) {
      return clickedRectDetail?.imgUrls[0];
    }

    return img_placeHolder;
  }

  // console.log(clickedRectDetail);
  console.log(currentBuilding);
  console.log(currentRoom);
  let isBuildingObjectEmpty = Object.keys(currentBuilding).length === 0;
  return (
    <div className="svg-map__info-block overflow-scroll">
      {currentRoom && (
        <>
          <img
            src={currentRoom.imgUrls[0] || img_placeHolder}
            alt=""
            width={300}
            className="mb-1"
          />{" "}
          <div className="info-block__description pt-1">
            <div className="flex flex-col justify-center text-center text-700 font-rubik h-10 mb-2 w-full rounded-md bg-200">
              {currentRoom.type} Info
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Room Number</span>
              {currentRoom.roomNumber || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Room Usage</span>
              {currentRoom.roomUsage || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">OPSC Code</span>
              {currentRoom.OPSCCode || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Grade Level</span>
              {currentRoom.gradeLevel || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Room Area</span>
              {Number(roomArea).toLocaleString() + " sqft" || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">State Loading</span>
              {currentRoom.stateLoading || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">District Loading</span>
              {currentRoom.districtLoading || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Exclude from Capacity</span>
              {currentRoom.exclude ? "Yes" : "No"}
            </div>
            {currentRoom.type === "Room" && (
              <div
                onClick={() => setRoomFileModal(true)}
                className=" cursor-pointer flex flex-row space-x-3 justify-center bg-200 rounded-sm py-1"
              >
                <span>
                  <FontAwesomeIcon icon={faFilePdf} fontSize={16} />
                </span>
                {/* {currentRoom.files[0]} */}
                <span className="text-baseline">Room Files</span>
              </div>
            )}
          </div>
        </>
      )}
      {!isBuildingObjectEmpty && (
        <>
          {" "}
          <img
            src={currentBuilding.imgUrls[0] || img_placeHolder}
            alt=""
            width={300}
            className="mb-1 mt-2"
          />{" "}
          <div className="info-block__description pt-1">
            <div className="flex flex-col justify-center text-center text-700 font-rubik h-10 mb-2 w-full rounded-md bg-200">
              Building Info
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Building Name</span>
              {currentBuilding.buildingName || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Building Type</span>
              {currentBuilding.buildingType || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Classrooms</span>
              {currentBuilding.classRooms || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Date Constructed</span>
              {currentBuilding.yearBuild || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Age</span>
              {getCurrentYear() - currentBuilding.yearBuild || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Modernization Date</span>
              {currentBuilding.sfpModDate || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Eligible Date</span>
              {getEligibleDate(
                currentBuilding.buildingType,
                currentBuilding.yearBuild,
                currentBuilding.sfpModDate
              ) || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Building Area </span>
              {Number(buildingInteriorArea).toLocaleString() + " sqft" || "N/A"}
            </div>
            <div className="SVGMapInfo__infoPiece__container">
              <span className="SVGMapInfo__label">Roof Area </span>
              {Number(buildingRoofArea).toLocaleString() + " sqft" || "N/A"}
            </div>
            {currentBuilding.type === "Building" && (
              <div
                onClick={() => setFileModal(true)}
                className=" cursor-pointer flex flex-row space-x-3 justify-center bg-200 rounded-sm py-1"
              >
                <span>
                  <FontAwesomeIcon icon={faFilePdf} fontSize={16} />
                </span>
                {/* {currentRoom.files[0]} */}
                <span className="text-baseline">Building Files</span>
              </div>
            )}
          </div>
        </>
      )}
      <Modal
        isOpen={filesModal}
        onRequestClose={() => setFileModal(false)}
        width="40rem"
      >
        <ModalHeaderForForms
          onRequestClose={() => setFileModal(false)}
          title="Building Files"
          icon={faFilePdf}
        />
        <div className="form-on-modal-container w-full h-full flex flex-col justify-center">
          <BuildingFiles building={currentBuilding} />
        </div>
      </Modal>
      <Modal
        isOpen={roomFilesModal}
        onRequestClose={() => setRoomFileModal(false)}
        width="40rem"
      >
        <ModalHeaderForForms
          onRequestClose={() => setRoomFileModal(false)}
          title="Room Files"
          icon={faFilePdf}
        />
        <div className="form-on-modal-container w-full h-full flex flex-col justify-center">
          <RoomFiles room={currentRoom} />
        </div>
      </Modal>
    </div>
    // <div className="svg-map__info-block overflow-scroll">
    //   {/* {printBtn} */}

    //   <img
    //     src={getPhoto()}
    //     alt=""
    //     // width={300}
    //     // className="mb-3"
    //   />
    //   <div className="info-block__description pt-2">
    //     {/* {buildingInfo && !clickedRectDetail && (
    //       <>
    //         <h6>{buildingInfo.title || buildingInfo.buildingName}</h6>
    //         <div>
    //           {buildingInfo.descripion && (
    //             <div> {buildingInfo.descripion} </div>
    //           )}
    //           {buildingInfo.buildingID && (
    //             <div>ID: {buildingInfo.buildingID} </div>
    //           )}
    //           {buildingInfo.buildingType && (
    //             <div>Type: {buildingInfo.buildingType} </div>
    //           )}
    //           {buildingInfo.classRooms && (
    //             <div className="SVGMapInfo__infoPiece__container">
    //               <span className="SVGMapInfo__label"> Classrooms </span>{" "}
    //               {buildingInfo.classRooms}
    //             </div>
    //           )}
    //           {buildingInfo.yearBuild && (
    //             <div>Year build: {buildingInfo.yearBuild} </div>
    //           )}
    //           {buildingInfo.areaUnits && (
    //             <div>
    //               Area: {buildingInfo.areaUnits + " " + buildingInfo.unit}{" "}
    //             </div>
    //           )}
    //         </div>
    //       </>
    //     )} */}

    //     {clickedRectDetail && (
    //       <>
    //         <div className="flex flex-col justify-center text-center text-700 font-rubik h-10 mb-2 w-full rounded-md bg-200">
    //           {clickedRectDetail.type} Info
    //         </div>

    //         {/* {clickedRectDetail.schoolName && !clickedRectDetail.roomNumber && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">School Name </span>
    //             {clickedRectDetail.schoolName}
    //           </div>
    //         )} */}
    //         {clickedRectDetail.buildingName &&
    //           !clickedRectDetail.roomNumber && (
    //             <div className="SVGMapInfo__infoPiece__container">
    //               <span className="SVGMapInfo__label">Building Name</span>
    //               {clickedRectDetail.buildingName}
    //             </div>
    //           )}

    //         {clickedRectDetail.buildingID && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Building ID </span>
    //             {clickedRectDetail.buildingID}
    //           </div>
    //         )}

    //         {clickedRectDetail.buildingType && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Building Type</span>
    //             {clickedRectDetail.buildingType}
    //           </div>
    //         )}
    //         {clickedRectDetail.classRooms && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Classrooms</span>
    //             {clickedRectDetail.classRooms}
    //           </div>
    //         )}

    //         {clickedRectDetail.yearBuild && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Date Constructed</span>
    //             {clickedRectDetail.yearBuild}
    //           </div>
    //         )}
    //         {clickedRectDetail.yearBuild && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Age</span>
    //             {getCurrentYear() - clickedRectDetail.yearBuild}
    //           </div>
    //         )}
    //         {clickedRectDetail.yearBuild && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Modernization Date</span>
    //             {clickedRectDetail.sfpModDate}
    //           </div>
    //         )}
    //         {clickedRectDetail.yearBuild && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Eligible Date</span>
    //             {getEligibleDate(
    //               clickedRectDetail.buildingType,
    //               clickedRectDetail.yearBuild,
    //               clickedRectDetail.sfpModDate
    //             )}
    //           </div>
    //         )}
    //         {clickedRectDetail.areaUnits && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Roof Area</span>
    //             {Number(clickedRectDetail.areaUnits).toLocaleString() + " sqft"}
    //           </div>
    //         )}
    //         {clickedRectDetail.areaUnits && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Building Area</span>
    //             {Number(clickedBuildingInteriorArea).toLocaleString() + " sqft"}
    //           </div>
    //         )}
    //         {clickedRectDetail.type === "Building" && (
    //           <div
    //             onClick={() => setFileModal(true)}
    //             className=" cursor-pointer flex flex-row space-x-3 justify-center bg-200 rounded-sm py-1"
    //           >
    //             <span>
    //               <FontAwesomeIcon icon={faFilePdf} fontSize={16} />
    //             </span>
    //             {/* {clickedRectDetail.files[0]} */}
    //             <span className="text-baseline">Building Files</span>
    //           </div>
    //         )}
    //         {/* ////////////// */}
    //         {clickedRectDetail.constructionCost && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Cost</span>
    //             {clickedRectDetail.constructionCost}
    //           </div>
    //         )}

    //         {clickedRectDetail.softCost && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Soft Cost</span>
    //             {clickedRectDetail.softCost}
    //           </div>
    //         )}

    //         {clickedRectDetail.totalCost && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Soft Cost </span>
    //             {clickedRectDetail.totalCost}
    //           </div>
    //         )}

    //         {clickedRectDetail.roomNumber && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Room Number </span>
    //             {clickedRectDetail.roomNumber}{" "}
    //           </div>
    //         )}
    //         {clickedRectDetail.roomUsage && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Room Usage </span>
    //             {clickedRectDetail.roomUsage}
    //           </div>
    //         )}
    //         {clickedRectDetail.OPSCCode && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">OPSC Code </span>
    //             {clickedRectDetail.OPSCCode}
    //           </div>
    //         )}
    //         {clickedRectDetail.gradeLevel && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Grade Level</span>
    //             {clickedRectDetail.gradeLevel}
    //           </div>
    //         )}
    //         {clickedRectDetail.interiorSquarFeet && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Interior Area</span>
    //             {Number(clickedRectDetail.interiorSquarFeet).toLocaleString() +
    //               " sqft"}
    //           </div>
    //         )}
    //         {clickedRectDetail.stateLoading && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">State Loading</span>
    //             {clickedRectDetail.stateLoading}
    //           </div>
    //         )}
    //         {clickedRectDetail.districtLoading && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">District Loading</span>
    //             {clickedRectDetail.districtLoading}
    //           </div>
    //         )}
    //         {clickedRectDetail.districtLoading && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">
    //               Excluded From Capacity
    //             </span>
    //             {clickedRectDetail.exclude ? "Yes" : "No"}
    //           </div>
    //         )}
    //         {clickedRectDetail.name && (
    //           <div className="SVGMapInfo__infoPiece__container">
    //             <span className="SVGMapInfo__label">Name </span>
    //             {clickedRectDetail.name}
    //           </div>
    //         )}
    //         {clickedRectDetail.type === "Room" && (
    //           <div
    //             onClick={() => setRoomFileModal(true)}
    //             className=" cursor-pointer flex flex-row space-x-3 justify-center bg-200 rounded-sm py-1"
    //           >
    //             <span>
    //               <FontAwesomeIcon icon={faFilePdf} fontSize={16} />
    //             </span>
    //             {/* {clickedRectDetail.files[0]} */}
    //             <span className="text-baseline">Room Files</span>
    //           </div>
    //         )}
    //         {/* {clickedRectDetail.details && (
    //           <div style={{ marginLeft: "7px" }}>
    //             <h6>Details:</h6>
    //             {clickedRectDetail?.details?.map((d) => (
    //               <div key={d._id} className="mb-2 border-bottom">
    //                 {d.system && (
    //                   <div className="SVGMapInfo__infoPiece__container">
    //                     <span className="SVGMapInfo__label">System </span>
    //                     {d.system}
    //                   </div>
    //                 )}

    //                 {d.subSystem && (
    //                   <div className="SVGMapInfo__infoPiece__container">
    //                     <span className="SVGMapInfo__label">Sub System </span>
    //                     {d.subSystem}
    //                   </div>
    //                 )}

    //                 {d.priority && (
    //                   <div className="SVGMapInfo__infoPiece__container">
    //                     <span className="SVGMapInfo__label">Priority </span>
    //                     {d.priority}
    //                   </div>
    //                 )}

    //                 {d.constructionCost && (
    //                   <div className="SVGMapInfo__infoPiece__container">
    //                     <span className="SVGMapInfo__label">
    //                       Construction Cost
    //                     </span>
    //                     {d.constructionCost}
    //                   </div>
    //                 )}

    //                 {d.softCost && (
    //                   <div className="SVGMapInfo__infoPiece__container">
    //                     <span className="SVGMapInfo__label">Soft Cost </span>
    //                     {d.softCost}
    //                   </div>
    //                 )}

    //                 {d.totalCost && (
    //                   <div className="SVGMapInfo__infoPiece__container">
    //                     <span className="SVGMapInfo__label">Total Cost </span>
    //                     {d.totalCost}
    //                   </div>
    //                 )}
    //                 {d.areaUnits && (
    //                   <div className="SVGMapInfo__infoPiece__container">
    //                     <span className="SVGMapInfo__label">Area/Units </span>
    //                     {d.areaUnits}
    //                   </div>
    //                 )}
    //               </div>
    //             ))}
    //           </div>
    //         )} */}
    //         <Modal
    //           isOpen={filesModal}
    //           onRequestClose={() => setFileModal(false)}
    //           width="40rem"
    //         >
    //           <ModalHeaderForForms
    //             onRequestClose={() => setFileModal(false)}
    //             title="Building Files"
    //             icon={faFilePdf}
    //           />
    //           <div className="form-on-modal-container w-full h-full flex flex-col justify-center">
    //             <BuildingFiles building={clickedRectDetail} />
    //           </div>
    //         </Modal>
    //         <Modal
    //           isOpen={roomFilesModal}
    //           onRequestClose={() => setRoomFileModal(false)}
    //           width="40rem"
    //         >
    //           <ModalHeaderForForms
    //             onRequestClose={() => setRoomFileModal(false)}
    //             title="Room Files"
    //             icon={faFilePdf}
    //           />
    //           <div className="form-on-modal-container w-full h-full flex flex-col justify-center">
    //             <RoomFiles room={clickedRectDetail} />
    //           </div>
    //         </Modal>
    //       </>
    //     )}
    //   </div>
    // </div>
  );
};

export default SVGMapInfoBlock;
