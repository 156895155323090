import React from "react";
import DashboardMainContentDetails from "./DashboardMainContentDetails";
import DashboardMainContentNumbers from "./DashboardMainContentNumbers";

const DashboardMainContent = ({ selectedSchoolObj, siteSummary }) => {
  return (
    <div className="dashboard__info__container">
      {/* header */}

      {/* <DashboardMainContentBasicInfo
        selectedSchoolObj={selectedSchoolObj}
        siteSummary={siteSummary}
      /> */}

      <DashboardMainContentNumbers siteSummary={siteSummary} />

      <DashboardMainContentDetails siteSummary={siteSummary} />
    </div>
  );
};

export default DashboardMainContent;
