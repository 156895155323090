import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useReactToPrint } from "react-to-print";

import TableControls from "./TableControls";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TableUtillButtons from "../TableUtillButtons";
import TableContentToPrint from "./TableContentToPrint";
import { HIDDEN_COLUMNS } from "../../data/hiddenColumns";
import districtService from "../../services/districtService";

const Table = ({
  tableData,
  tableColumns,
  saveData,
  filtersValue,
  filtersStatus,
  resourceToLoad,
  isDataSiteChildren,
  onRowClicked,
  total,
  tableType,
}) => {
  const [district, setDistrict] = useState(null);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);
  console.log(columns);

  const node = useRef();

  const loadSiteOrDistrict = useCallback(async () => {
    if (!filtersValue) return;

    if (filtersValue && filtersValue.district) {
      const { data: res } = await districtService.getDistrictById(
        filtersValue.district
      );

      setDistrict(res?.data);
    }
  }, [filtersValue]);

  useEffect(() => {
    loadSiteOrDistrict();
  }, [loadSiteOrDistrict]);

  const handlePrint = useReactToPrint({
    content: () => node.current,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    setGlobalFilter,
    prepareRow,
    allColumns,
  } = useTable(
    {
      data,
      columns,
      initialState: {
        hiddenColumns: saveData ? HIDDEN_COLUMNS : [],
        pageSize: saveData ? 20 : 100,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  if (state.globalFilter === undefined) state.globalFilter = "";

  const { globalFilter, pageIndex, pageSize } = state;
  console.log("saveData", saveData);
  return (
    <>
      {saveData && (
        <div className="d-flex justify-content-between normalize-text my-3 align-items-center">
          <TableUtillButtons
            filtersStatus={filtersStatus}
            filtersValue={filtersValue}
            saveData={saveData}
            isDataSiteChildren={isDataSiteChildren}
            resourceToLoad={resourceToLoad}
            onPrint={handlePrint}
          />

          <TableControls
            tableColumns={allColumns}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageCount={pageCount}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>
      )}

      <div className={saveData ? "table-container" : ""}>
        <table {...getTableProps()}>
          <TableHeader headerGroups={headerGroups} tableType={tableType} />
          <TableBody
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            onRowClicked={onRowClicked}
            total={total}
            tableType={tableType}
          />
        </table>
      </div>

      {Array.isArray(tableData) && tableData.length === 0 && (
        <h4 className="m-4">
          No{" "}
          {resourceToLoad === "building"
            ? "record, try to add using the add button."
            : resourceToLoad}
        </h4>
      )}
      {saveData && (
        <TableContentToPrint
          ref={node}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
          filters={filtersValue}
          district={district}
          resourceToLoad={resourceToLoad}
          // site={site}
        />
      )}
    </>
  );
};

export default Table;
