import React, { useEffect, useState, useCallback, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import siteService from "../../services/siteService";
import buildingService from "../../services/buildingService";
import AssessmentSummary from "./AssessmentSummary";
import { Route, Routes } from "react-router-dom";
import AssessmentDetail from "./AssessmentDetail";
import BuildingInventory from "./BuildingInventory";
import AssessmentSummaryPrint from "./AssessmentSummaryPrint";
import DistrictUserDashboardLayout from "./DistrictUserDashboardLayout";
import { useNavigate } from "react-router-dom";
import AssessmentDetailPrintAble from "./AssessmentDetailPrintAble";
import Sidebar from "../common/Sidebar";
import SideFeaturedImages from "../SideFeaturedImages";
import BuildingInventoryPrintAble from "./BuildingInventoryPrintAble";

const buildingSummaryInitial = {
  Permanent: {
    buildingType: "Permanent",
    area: 0,
    classRoomCount: 0,
    buildingCount: 0,
    totalAge: 0,
  },
  Portable: {
    buildingType: "Portable",
    area: 0,
    classRoomCount: 0,
    buildingCount: 0,
    totalAge: 0,
  },
  Other: {
    buildingType: "Other",
    area: 0,
    classRoomCount: 0,
    buildingCount: 0,
    totalAge: 0,
  },
};

const DistrictUserDashboard = ({ user }) => {
  const [sites, setSites] = useState(null);
  const [selected, setSelected] = useState("");
  const [selectedSchoolObj, setSelectedSchoolObj] = useState(null);
  const [siteSummary, setSiteSummary] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarImages, setSidebarImages] = useState(null);
  const [buildings, setBuildings] = useState(null);
  const [buildingSummary, setBuildingSummary] = useState(null);

  const assessmentDetail = useRef(null);
  const buildingInventory = useRef(null);

  const handleAssessmentDetailPrint = useReactToPrint({
    content: () => assessmentDetail.current,
  });

  const handleBuildingInventoryPrint = useReactToPrint({
    content: () => buildingInventory.current,
  });

  const navigate = useNavigate();

  const loadResource = useCallback(
    async function () {
      const { data: res } = await siteService.getSitesByDistrict(
        user.districtID
      );
      setSites(res.data);
    },
    [user]
  );

  useEffect(() => {
    loadResource();
  }, [loadResource]);

  const loadSummary = async (siteID) => {
    const { data: res } = await siteService.getSiteSummary(siteID);
    setSiteSummary(res.data);
  };

  const handleOnSelect = async (site, option) => {
    setSelected(site);
    setSelectedSchoolObj(option);
    loadSummary(site);
  };

  const loadBuildings = useCallback(async () => {
    if (!selectedSchoolObj) return;

    try {
      const { data: res } = await buildingService.getBuildingsBySite(
        selectedSchoolObj._id
      );
      setBuildings(res.data);

      setBuildingSummary(Object.values(getBuildingSummary(res.data)));
    } catch (error) {}
  }, [selectedSchoolObj]);

  useEffect(() => {
    loadBuildings();
  }, [loadBuildings]);

  function getBuildingSummary(buildingData) {
    return buildingData?.reduce((p, c) => {
      if (!c.buildingType) return p;

      const newP = { ...p };
      const data = { ...newP[c.buildingType] };
      if (c.areaUnits && !isNaN(+c.areaUnits)) data.area += +c.areaUnits;
      if (c.classRooms) data.classRoomCount += +c.classRooms;
      if (c.yearBuild) {
        data.buildingCount += 1;
        data.totalAge += new Date().getFullYear() - c.yearBuild;
      }

      newP[c.buildingType] = data;
      return newP;
    }, buildingSummaryInitial);
  }

  const getSummaryTableData = () => {
    const newBuildingSummary = [...buildingSummary];
    const total = {
      buildingType: "Total",
      area: newBuildingSummary.reduce((p, c) => p + c.area, 0),
      classRoomCount: newBuildingSummary.reduce(
        (p, c) => p + c.classRoomCount,
        0
      ),
      buildingCount: 0,
      totalAge: 0,
    };
    newBuildingSummary.push(total);

    return newBuildingSummary;
  };

  if (selected === "" && sites && sites.length > 0)
    handleOnSelect(sites[0]._id, sites[0]);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <DistrictUserDashboardLayout
              handleOnSelect={handleOnSelect}
              selected={selected}
              sites={sites}
              selectedSchoolObj={selectedSchoolObj}
              user={user}
              onCreateReport={() =>
                navigate("/dashboard/assessment-summary/print")
              }
            >
              <AssessmentSummary
                selectedSchoolObj={selectedSchoolObj}
                siteSummary={siteSummary}
              />
            </DistrictUserDashboardLayout>
          }
        />

        <Route
          path="assessment-summary/print"
          element={
            <AssessmentSummaryPrint
              selectedSchoolObj={selectedSchoolObj}
              siteSummary={siteSummary}
            />
          }
        />

        <Route
          path="assessment-details"
          element={
            <DistrictUserDashboardLayout
              handleOnSelect={handleOnSelect}
              selected={selected}
              sites={sites}
              selectedSchoolObj={selectedSchoolObj}
              user={user}
              onCreateReport={handleAssessmentDetailPrint}
            >
              <AssessmentDetail siteSummary={siteSummary}>
                {siteSummary ? (
                  <AssessmentDetailPrintAble
                    ref={assessmentDetail}
                    siteDetailsSystems={{ ...siteSummary.siteDetailsSystems }}
                    setSidebarImages={setSidebarImages}
                    setIsSidebarOpen={setIsSidebarOpen}
                  />
                ) : (
                  ""
                )}
              </AssessmentDetail>
            </DistrictUserDashboardLayout>
          }
        />
        <Route
          path="building-inventory"
          element={
            <DistrictUserDashboardLayout
              handleOnSelect={handleOnSelect}
              selected={selected}
              sites={sites}
              selectedSchoolObj={selectedSchoolObj}
              user={user}
              onCreateReport={handleBuildingInventoryPrint}
            >
              <BuildingInventory
                site={selectedSchoolObj}
                buildings={buildings}
                buildingSummary={buildingSummary}
              >
                {selectedSchoolObj && buildings && buildingSummary ? (
                  <BuildingInventoryPrintAble
                    ref={buildingInventory}
                    buildings={buildings}
                    getSummaryTableData={getSummaryTableData}
                  />
                ) : (
                  ""
                )}
              </BuildingInventory>
            </DistrictUserDashboardLayout>
          }
        />
      </Routes>

      <Sidebar
        isOpen={isSidebarOpen}
        onRequestClose={() => setIsSidebarOpen(false)}
        showBackdrop={true}
      >
        <SideFeaturedImages images={sidebarImages} />
      </Sidebar>
      {/* </div>
      )} */}
    </>
  );
};

export default DistrictUserDashboard;
