import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

// const apiEndPoint = config.apiEndPoint + "/sitedetails";

export const saveSiteDetail = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(
      http.put(`${config.apiEndPoint1}/sitedetails/${id}`, data),
      {
        pending: "Updating Site Detail...",
        success: "Site Detail Updated Successfully!",
        error: "Failed, please try again.",
      }
    );
  }

  return toast.promise(http.post(config.apiEndPoint1 + "/sitedetails", data), {
    pending: "Adding Site...",
    success: "Site detail Added Successfully!",
    error: "Failed, please try again.",
  });
};

export const getSiteDetails = () => {
  return http.get(config.apiEndPoint1 + "/sitedetails");
};
export const getSiteDetailsBySite = (siteID) => {
  return http.get(
    `${config.apiEndPoint1}/sitedetails/${siteID}/allsitedetails`
  );
};

export const deleteSiteDetail = (id) => {
  return toast.promise(
    http.delete(`${config.apiEndPoint1}/sitedetails/${id}`),
    {
      pending: "Deleting site detail...",
      success: "site detail Deleted Successfully!",
      error: "site detail has already been deleted",
    }
  );
};

export const getSiteDetailById = (id) => {
  return http.get(`${config.apiEndPoint1}/sitedetails/${id}`);
};

const siteDetailService = {
  saveSiteDetail,
  getSiteDetails,
  getSiteDetailsBySite,
  deleteSiteDetail,
  getSiteDetailById,
};

export default siteDetailService;
