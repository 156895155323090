import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFileChartPie } from "@fortawesome/pro-regular-svg-icons";
import {
  getBuildingsBySite,
  getBuildingStatistic,
} from "../services/buildingService";
import { BUILDING_COLUMNS } from "../data/buildingColumns";
import Table from "./common/Table";
import StatisticReport from "./common/StatisticReport";

const InventoryReport = ({ siteID }) => {
  const [buildings, setBuildings] = useState([]);
  const [statistics, setStatistics] = useState([]);
  useEffect(() => {
    const fetchBuildings = async () => {
      const { data } = await getBuildingsBySite(siteID);
      setBuildings(data.data);
    };
    const fetchStatistics = async () => {
      const { data } = await getBuildingStatistic(siteID);
      setStatistics(data.data);
    };

    fetchBuildings();
    fetchStatistics();
  }, [siteID]);
  return (
    <div className="d-flex flex-column" style={{ overflowY: "hidden" }}>
      <div className="mx-8 mb-4">
        <div className="flex flex-row justify-between text-center my-4 ml-2">
          <span className="w-40">{buildings[0]?.schoolName}</span>

          <span>
            {/* <button
              className="flex justify-center bg-700 text-white p-2 px-3 rounded-md ml-2 text-sm"
              // onClick={onPrint}
            >
              <span>
                <FontAwesomeIcon icon={faFileChartPie} fontSize="18px" />
              </span>
              <span className="ml-2">Generate Report</span>
            </button> */}
          </span>
        </div>
        <Table tableData={buildings} tableColumns={BUILDING_COLUMNS} />

        <StatisticReport buildingStatistic={statistics} />
      </div>
    </div>
  );
};

export default InventoryReport;
