import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";
import { getCurrentUser } from "./authService";

// const apiEndPoint = config.apiEndPoint + "/districts";
const apiEndPoint1 = config.apiEndPoint1 + "/districts";

export const saveDistrict = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(http.put(`${apiEndPoint1}/${id}`, data), {
      pending: "Updating District...",
      success: "District Updated Successfully!",
      error: "Failed, please try again.",
    });
  }

  return toast.promise(http.post(apiEndPoint1, data), {
    pending: "Adding District...",
    success: "District Added Successfully!",
    error: "Failed, please try again.",
  });
};

export const getDistricts = () => {
  const user = getCurrentUser();
  // return http.get(`${apiEndPoint1}/${user.id}/alldistricts`);
  return http.get(apiEndPoint1 + "/" + user.id + "/alldistricts");
};

export const deleteDistrict = (id) => {
  return toast.promise(http.delete(`${apiEndPoint1}/${id}`), {
    pending: "Deleting District...",
    success: "District Deleted Successfully!",
    error: "District has already been deleted",
  });
};

export const getDistrictById = (districtID) => {
  return http.get(`${config.apiEndPoint1}/districts/${districtID}`);
};

export const getDistrictCapacity = (districtID) => {
  return http.get(
    `${config.apiEndPoint1}/districts/districtcapacity/${districtID}`
  );
};
export const getDistrictArea = (districtID) => {
  return http.get(
    `${config.apiEndPoint1}/districts/districtarea/${districtID}`
  );
};

const districtService = {
  saveDistrict,
  getDistricts,
  deleteDistrict,
  getDistrictById,
};

export default districtService;
