import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

const PhotoPreviewCreator = ({ data }) => {
  return (
    <PhotoProvider>
      {data.map((item, index) => (
        <PhotoView key={item} src={item}>
          {index < 1 ? (
            <img
              src={item}
              alt=""
              style={{ width: "40px", borderRadius: "3px" }}
            />
          ) : undefined}
        </PhotoView>
      ))}
    </PhotoProvider>
  );
};

export default PhotoPreviewCreator;
