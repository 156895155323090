export const DISTRICT_AREA_COLUMNS = [
  {
    Header: "School Name",
    accessor: "siteName",
  },
  {
    Header: "Permanent Buildings sqft",
    accessor: "permanentBuildingsArea",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "Portable Buildings sqft",
    accessor: "portableBuildingsArea",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "Total Buildings sqft",
    accessor: "totalBuildingsArea",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "CR",
    accessor: "cr",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "LAB",
    accessor: "lab",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "AD",
    accessor: "ad",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "LIB",
    accessor: "lib",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "MU",
    accessor: "mu",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "KT",
    accessor: "kt",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "GYM",
    accessor: "gym",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "SL",
    accessor: "sl",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "RR",
    accessor: "rr",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "ST",
    accessor: "st",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "WW",
    accessor: "ww",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "WWW",
    accessor: "www",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "OCWW",
    accessor: "ocww",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "SG",
    accessor: "sg",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "COMP",
    accessor: "comp",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "AUD",
    accessor: "aud",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "MAINT",
    accessor: "maint",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "AG",
    accessor: "ag",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: "OTHER",
    accessor: "other",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
];
