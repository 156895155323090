export const BUILDING_PRIORITY_OPTIONS = [
  "High",
  "Medium",
  "Low",
  // "Health and safety",
  // "Major building modernization",
  // "Basic building modernization",
  // "Site improvement",
  // "New additions",
];
