import React from "react";

const BuildingInventory = ({ site, children, buildings, buildingSummary }) => {
  if (!site || !buildings || !buildingSummary)
    return <div className="m-5">Loading...</div>;

  return (
    <div
      className="w-80 text-custom-secondary"
      style={{
        height: "90vh",
        overflowY: "auto",
        width: "80vw",
        flexGrow: 10,
        backgroundColor: "#fff",
        borderLeft: "1px solid #efefef",
        // borderRadius: "5px",
      }}
    >
      {children}
      {/* <div style={{ textAlign: "right" }} className="p-3">
        <button
          className="modal-btn-custom btn-custom-primary ml-custom-1 mt-2"
          onClick={handlePrint}
        >
          <FontAwesomeIcon icon={faPrint} className="normalize-text" />
          &nbsp;&nbsp;Create Report
        </button>
      </div>

      <BuildingInventoryPrintAble
        ref={node}
        buildings={buildings}
        getSummaryTableData={getSummaryTableData}
      /> */}

      {/* <Table
        tableColumns={BUILDING_COLUMNS_DASHBOARD}
        tableData={buildings}
        onRowClicked={() => {}}
      />
      <Table
        tableColumns={BUILDING_INVENTORY_SUMMARY_COLUMNS}
        tableData={getSummaryTableData()}
        onRowClicked={() => {}}
      /> */}
    </div>
  );
};

export default BuildingInventory;
