import React from "react";

const BuildingSystemsTable = ({ buildingSystems, setBuildingSystems }) => {
  const addRow = () => {
    setBuildingSystems((pre) => ({
      ...pre,
      systems: [
        ...pre?.systems,
        {
          systemName: "",
          manufacturer: "",
          serialID: "",
          materialUnitType: "",
          installYear: "",
          lifeCycleTimeline: "",
          endOfLifeCycleTimeline: "",
          yearsRemaining: "",
        },
      ],
    }));
  };

  const updateBuildingSystem = (index, updatedData, fieldName) => {
    const updatedBuildingSystems = buildingSystems.systems.map(
      (buildingSystem, idx) =>
        idx === index ? { ...buildingSystem, ...updatedData } : buildingSystem
    );

    const newSystem = { ...updatedBuildingSystems[index] };
    if (
      (fieldName === "installDate" || fieldName === "lifeCycleTimeline") &&
      newSystem.installDate &&
      newSystem.lifeCycleTimeline
    ) {
      newSystem.endOfLifeCycleDate =
        +newSystem.installDate + +newSystem.lifeCycleTimeline;
      updatedBuildingSystems[index] = newSystem;
    }

    setBuildingSystems((pre) => ({
      ...pre,
      systems: updatedBuildingSystems,
    }));
  };

  const getRemainingSystemAge = (buildingSystem) => {
    if (buildingSystem.installDate && buildingSystem.endOfLifeCycleDate) {
      const currentYear = new Date().getFullYear();

      return {
        age: +buildingSystem.endOfLifeCycleDate - currentYear,
        success: true,
      };
    }

    return { success: false };
  };

  const getStyleForPermRoofSystem = (age) => {
    if (age >= 11) {
      return {
        backgroundColor: "green",
        color: "white",
      };
    }

    if (age >= 6 && age <= 10) {
      return {
        backgroundColor: "yellow",
      };
    }

    if (age >= 2 && age <= 5) {
      return {
        backgroundColor: "orange",
        color: "white",
      };
    }

    if (age < 2) {
      return {
        backgroundColor: "red",
        color: "white",
      };
    }
  };

  const getRemainingFieldStyle = (buildingSystem) => {
    const { age, success } = getRemainingSystemAge(buildingSystem);
    if (success) {
      return getStyleForPermRoofSystem(age);
    }

    return {};
  };

  if (!buildingSystems) return <div>Loading...</div>;

  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-4">
        <h6>Building Systems</h6>
        <button
          type="button"
          className="btn btn-sm btn-custom-primary"
          onClick={addRow}
        >
          Add Row
        </button>
      </div>
      <div className="table-responsive">
        <table className="buildingSystemsTable">
          <thead>
            <tr className="rounded-sm h-4">
              {/* <th className=" justify-center bg-700 rounded-l">
                {" "}
                <FontAwesomeIcon icon={faGears} color="#fff" fontSize={18} />
                <span className="ml-2 text-white">System Name</span>
              </th>
              <th className=" justify-center bg-700 ">
                {" "}
                <FontAwesomeIcon icon={faIndustry} color="#fff" fontSize={18} />
                <span className="ml-2 text-white">Manufacturer</span>
              </th> */}
              <th className="h-4 bg-700 text-white">System Name</th>
              <th className="h-4 bg-700 text-white">Manufacturer</th>
              <th className="h-4 bg-700 text-white">Serial ID</th>
              <th className="h-4 bg-700 text-white">Material/Unit Type</th>
              <th className="h-4 bg-700 text-white">Install Year</th>
              <th className="h-4 bg-700 text-white">Life Cycle Timeline</th>
              <th className="h-4 bg-700 text-white">
                End of Life Cycle Timeline
              </th>
              <th className="h-4 bg-700 text-white">Years Remaining</th>
            </tr>
          </thead>
          <tbody>
            {buildingSystems?.systems?.map((buildingSystem, index) => (
              <tr key={index} className="h-full">
                <td className="h-8">
                  <input
                    className="w-full h-full text-center"
                    value={buildingSystem.systemName}
                    onChange={(e) =>
                      updateBuildingSystem(index, {
                        systemName: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="h-8">
                  <input
                    className="w-full h-full text-center"
                    value={buildingSystem.manufacturer}
                    onChange={(e) =>
                      updateBuildingSystem(index, {
                        manufacturer: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="h-8">
                  <input
                    className="w-full h-full text-center"
                    value={buildingSystem.serialID}
                    onChange={(e) =>
                      updateBuildingSystem(index, { serialID: e.target.value })
                    }
                  />
                </td>
                <td className="h-8">
                  <input
                    className="w-full h-full text-center"
                    value={buildingSystem.materialUnitType}
                    onChange={(e) =>
                      updateBuildingSystem(index, {
                        materialUnitType: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="h-8">
                  <input
                    type="number"
                    className="w-full h-full text-center"
                    value={buildingSystem.installDate}
                    onChange={(e) =>
                      updateBuildingSystem(
                        index,
                        {
                          installDate: e.target.value,
                        },
                        "installDate"
                      )
                    }
                  />
                </td>
                <td className="h-8">
                  <input
                    type="number"
                    className="w-full h-full text-center"
                    value={buildingSystem.lifeCycleTimeline}
                    onChange={(e) =>
                      updateBuildingSystem(
                        index,
                        {
                          lifeCycleTimeline: e.target.value,
                        },
                        "lifeCycleTimeline"
                      )
                    }
                  />
                </td>
                <td className="h-8 ">
                  <input
                    className="w-full h-full text-center"
                    value={buildingSystem.endOfLifeCycleDate}
                    readOnly
                    onChange={(e) =>
                      updateBuildingSystem(index, {
                        endOfLifeCycleDate: e.target.value,
                      })
                    }
                  />
                </td>
                <td
                  className="px-1 h-8 text-center"
                  style={getRemainingFieldStyle(buildingSystem)}
                >
                  {getRemainingSystemAge(buildingSystem)?.age}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BuildingSystemsTable;
