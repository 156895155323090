import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import {
  faMapMarked,
  faPeopleRoof,
  faPlusCircle,
  faCity,
  faFileChartPie,
} from "@fortawesome/pro-regular-svg-icons";
import Modal from "./common/Modal";
import AddDistrict from "./District/AddDistrict";
import ModalHeaderForForms from "./ModalHeaderForForms";
import AddSite from "./Site/AddSite";
import AddBuilding from "./Building/AddBuilding";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import auth from "../services/authService";
import AddRoom from "./Room/AddRoom";
import { faPieChart } from "@fortawesome/pro-light-svg-icons";
import * as Modal1 from "react-modal";
import DistrictCapacityReport from "./DistrictCapacityReport";
import DistrictAreaReport from "./DistrictAreaReport";

// Mock state to reduce time for calculating the state of every modal
const mockState = {
  addDistrict: false,
  addSite: false,
  addBuilding: false,
  addRoom: false,
};

const TableUtillButtons = ({
  saveData,
  filtersValue,
  filtersStatus,
  isDataSiteChildren,
  resourceToLoad,
  onPrint,
}) => {
  const { districtID } = useParams();
  console.log(districtID);
  const [isModalOpen, setIsModalOpen] = useState({
    addDistrict: false,
    addSite: false,
    addBuilding: false,
    addRoom: false,
  });

  // district capacity report modal
  const [isCapacityReportModalOpen, setIsCapacityReportModal] = useState(false);
  // district area report modal
  const [isAreaReportModalOpen, setIsAreaReportModal] = useState(false);

  function openModal(label) {
    const newIsModalOpen = { ...mockState, [label]: true };
    setIsModalOpen(newIsModalOpen);
  }

  function closeModal(label) {
    const newIsModalOpen = { ...mockState, [label]: false };
    setIsModalOpen(newIsModalOpen);
  }

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isSite = query.get("site");

  return (
    <>
      <div className="flex flex-row justify-between mt-2 mr-2">
        {filtersValue.district === "" &&
          auth.getCurrentUser()?.type === "organization" &&
          !isSite && (
            <button
              className="flex  modal-btn-custom btn-custom-primary"
              onClick={() => openModal("addDistrict")}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                // className="normalize-text"
                fontSize={18}
              />
              &nbsp; District
            </button>
          )}

        {filtersValue.site === "" && !filtersStatus.site && (
          <button
            className="flex justify-center bg-700 text-white p-2 px-3 rounded-md ml-2 text-sm"
            onClick={() => openModal("addSite")}
            // onClick={() => navigate("/districts/map")}
          >
            <span>
              {" "}
              <FontAwesomeIcon icon={faPlusCircle} fontSize={18} />
            </span>
            <span className="ml-2 text-sm">Site</span>
          </button>
        )}
        {filtersValue.site === "" && !filtersStatus.site && (
          <div className="flex flex-row space-x-2">
            <div
              onClick={() => setIsCapacityReportModal(true)}
              // to={`/districtcapacity/${filtersValue.district}`}
              className="cursor-pointer flex justify-center bg-200 hover:text-900 text-900 p-2 px-3 rounded-md ml-2 text-sm"
            >
              <span>
                {" "}
                <FontAwesomeIcon icon={faPieChart} fontSize={18} />
              </span>
              <span className="ml-2 text-sm">Capacity Report</span>
            </div>
            <div
              // to={`/districtarea/${filtersValue.district}`}
              onClick={() => setIsAreaReportModal(true)}
              className="cursor-pointer flex justify-center bg-200 hover:text-900 text-900 p-2 px-3 rounded-md ml-2 text-sm"
            >
              <span>
                {" "}
                <FontAwesomeIcon icon={faPieChart} fontSize={18} />
              </span>
              <span className="ml-2 text-sm">Area Report</span>
            </div>
          </div>
        )}

        {/* {filtersValue.building === "" &&
          !filtersStatus.building &&
          isDataSiteChildren &&
          isDataSiteChildren.building && (
            <button
              className="modal-btn-custom btn-custom-primary ml-custom-1"
              onClick={() => openModal("addBuilding")}
              // onClick={() => navigate("/districts/map")}
            >
              <FontAwesomeIcon icon={faPlus} className="normalize-text" />
              &nbsp; Building
            </button>
          )} */}

        {/* {filtersValue.building === "" &&
          !filtersStatus.building &&
          isDataSiteChildren &&
          isDataSiteChildren.newConstruction && (
            <button
              className="modal-btn-custom btn-custom-primary ml-custom-1"
              // onClick={() => openModal("addBuilding")}
              // onClick={() => navigate("/districts/map")}
              type="button"
            >
              <FontAwesomeIcon icon={faPlus} className="normalize-text" />
              &nbsp; New Construction
            </button>
          )} */}

        {/* {filtersValue.building === "" &&
          !filtersStatus.building &&
          isDataSiteChildren &&
          isDataSiteChildren.siteDetails && (
            <button
              className="modal-btn-custom btn-custom-primary ml-custom-1"
              onClick={() => openModal("siteSystem")}
              // onClick={() => navigate("/districts/map")}
            >
              <FontAwesomeIcon icon={faPlus} className="normalize-text" />
              &nbsp; Site System
            </button>
          )} */}

        {/* {filtersValue.building !== "" && (
          <button
            className="modal-btn-custom btn-custom-primary ml-custom-1"
            onClick={() => openModal("addRoom")}
            // onClick={() => navigate("/districts/map")}
          >
            <FontAwesomeIcon icon={faPlus} className="normalize-text" />
            &nbsp; Room
          </button>
        )} */}

        {/* Print button */}
        {resourceToLoad !== "district" && onPrint && (
          <button
            className="flex justify-center bg-700 text-white p-2 px-3 rounded-md ml-2 text-sm"
            onClick={onPrint}
          >
            <span>
              <FontAwesomeIcon icon={faFileChartPie} fontSize="18px" />
            </span>
            <span className="ml-2">Generate Report</span>
          </button>
        )}
      </div>

      <Modal
        isOpen={isModalOpen.addDistrict}
        onRequestClose={() => closeModal("addDistrict")}
      >
        <ModalHeaderForForms
          onRequestClose={() => closeModal("addDistrict")}
          title="Add District"
          icon={faCity}
          formId={"addDistrict"}
        />
        <div className="form-on-modal-container">
          <div className="modal-content">
            <AddDistrict
              formId={"addDistrict"}
              onRequestClose={() => closeModal("addDistrict")}
              saveData={saveData}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen.addSite}
        onRequestClose={() => closeModal("addSite")}
      >
        {/* {drawer(() => closeModal("addSite"))} */}

        <ModalHeaderForForms
          formId={"addSite"}
          onRequestClose={() => closeModal("addSite")}
          title="Add Site"
          icon={faMapMarked}
        />
        <div className="form-on-modal-container">
          <div className="modal-content">
            <AddSite
              filters={filtersValue}
              formId="addSite"
              onRequestClose={() => closeModal("addSite")}
              saveData={saveData}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen.addBuilding}
        onRequestClose={() => closeModal("addBuilding")}
      >
        {/* {drawer(() => closeModal("addBuilding"))} */}

        <ModalHeaderForForms
          formId={"addBuilding"}
          onRequestClose={() => closeModal("addBuilding")}
          title="Add Building"
          icon={faBuilding}
        />
        <div className="modal-content-1">
          <AddBuilding
            filters={filtersValue}
            formId="addBuilding"
            onRequestClose={() => closeModal("addBuilding")}
            saveData={saveData}
          />
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen.addRoom}
        onRequestClose={() => closeModal("addRoom")}
      >
        {/* {drawer(() => closeModal("addRoom"))} */}

        <ModalHeaderForForms
          formId={"addRoom"}
          onRequestClose={() => closeModal("addRoom")}
          title="Add Room"
          icon={faPeopleRoof}
        />
        <div className="form-on-modal-container">
          <div className="modal-content">
            <AddRoom
              filters={filtersValue}
              formId="addRoom"
              onRequestClose={() => closeModal("addRoom")}
              saveData={saveData}
            />
          </div>
        </div>
      </Modal>
      <Modal1
        isOpen={isCapacityReportModalOpen}
        onRequestClose={() => setIsCapacityReportModal(false)}
      >
        <ModalHeaderForForms
          onRequestClose={() => setIsCapacityReportModal(false)}
          title="District Capacity Report"
          icon={faPieChart}
        />
        <div className="modalContent">
          <DistrictCapacityReport districtID={filtersValue.district} />
        </div>
      </Modal1>
      <Modal1
        isOpen={isAreaReportModalOpen}
        onRequestClose={() => setIsAreaReportModal(false)}
      >
        <ModalHeaderForForms
          onRequestClose={() => setIsAreaReportModal(false)}
          title="District Area Report"
          icon={faPieChart}
        />
        <div className="modalContent">
          <DistrictAreaReport districtID={filtersValue.district} />
        </div>
      </Modal1>
    </>
  );
};

export default TableUtillButtons;
