import React from "react";
import Table from "./common/Table";
import { BUILDING_COLUMNS } from "./../data/buildingColumns";
import ActionBtn from "./common/ActionBtn";
import {
  faEdit,
  faMessage,
  faPenToSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { useContext } from "react";
import { NoteContext } from "../context/NoteContext";
import { SITE_DETAIL_COLUMNS } from "../data/siteDetailColumns";
import { ROOM_COLUMNS } from "../data/roomColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SchoolMapDataList = ({
  data,
  type,
  onEditSiteSystem,
  onEditBuilding,
}) => {
  const { setCurrentNote, setIsNoteOpen } = useContext(NoteContext);

  const getActionsButtons = (row) => {
    return (
      <>
        {type === "Building" && (
          <ActionBtn icon={faPenToSquare} onClick={() => onEditBuilding(row)} />
        )}

        <ActionBtn
          icon={faMessage}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentNote(row.note);
            setIsNoteOpen(true);
          }}
        />

        {/* <ActionBtn
          isDeleteBtn={true}
          icon={faTrash}
          onClick={(e) => {
            setDetailToEdit(row);
            setIsDeleteDialogOpen(true);
          }}
        /> */}
      </>
    );
  };

  const getTableColumns = () => {
    if (!type) return BUILDING_COLUMNS;

    if (type === "Building") {
      return [
        ...BUILDING_COLUMNS,
        {
          Header: "Actions",
          Footer: "",
          id: "actions",
          accessor: (row) => getActionsButtons(row),
        },
      ];
    }

    if (type === "Site System")
      return [
        ...SITE_DETAIL_COLUMNS,
        {
          Header: "Actions",
          Footer: "",
          id: "actions",
          accessor: (row) => getActionsButtons(row),
        },
      ];

    if (type === "Room")
      return [
        ...ROOM_COLUMNS,
        {
          Header: "Actions",
          Footer: "",
          id: "actions",
          accessor: (row) => getActionsButtons(row),
        },
      ];
  };

  const getTable = () => {
    if (type !== "Site System")
      return (
        <Table
          tableData={data ?? []}
          tableColumns={getTableColumns()}
          resourceToLoad={type}
        />
      );

    return data.map((siteDetail) => (
      <div className="my-3" key={siteDetail._id}>
        <div className="d-flex justify-content-between">
          <h6>{siteDetail.name}</h6>
          <h6
            role="button"
            className="text-custom-primary"
            onClick={() => onEditSiteSystem(siteDetail)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </h6>
        </div>
        <Table
          tableData={siteDetail.details}
          tableColumns={getTableColumns()}
          type={type}
        />
      </div>
    ));
  };

  return (
    <div
      className="py-3 px-5 m-1"
      style={{ height: "74vh", overflowY: "auto" }}
    >
      <h3 className="mb-2 text-lg">{type}s</h3>

      {getTable()}
    </div>
  );
};

export default SchoolMapDataList;
