import React, { useRef, useCallback, useEffect, useState } from "react";
import * as d3 from "d3";

import SchoolMapPlaygroundShapes from "./SchoolMapPlaygroundShapes";
import SchoolSVGRenderer from "./SchoolSVGRenderer";

const SchoolMapPlaygroundMain = ({
  nodes,
  nodesChangesTracker,
  setNodesChangesTracker,
  onMapDataChange,
  onClickShape,
  onDoubleClickShape,
  isClickOnMapEnabled,
  setIsClickOnMapEnabled,
  onAddShape,
  setClickedSVGPartID,
  onBuildingClickedForAddingInfo,
  isAddBuildingInfoEnabled,
  isAddRoomInfoEnabled,
  isAddSiteSystemEnabled,
  onSVGClickedForAddingSiteSystem,
}) => {
  const [translateScale, setTranslateScale] = useState({
    x: 0,
    y: 0,
    scale: 1,
  });

  const canvas = useRef();

  const updateNodes = useCallback(
    function (name, x, y) {
      const index = nodesChangesTracker.findIndex(
        (n) => n.name.toLowerCase() === name.toLowerCase()
      );
      if (index !== -1) {
        setNodesChangesTracker((preNodes) => {
          const newNodes = [...preNodes];
          const newNode = { ...newNodes[index] };
          newNode.x = x;
          newNode.y = y;
          newNodes[index] = newNode;
          return newNodes;
        });
      }
    },
    [nodesChangesTracker, setNodesChangesTracker]
  );

  function dragstarted() {
    d3.select(this).raise();
    // g.attr("cursor", "grabbing");
  }

  const dragged = useCallback(
    function dragged(event, d) {
      const container = d3
        .select(this)
        .style("transform", `translate(${event.x}, ${event.y})`);

      container
        .select("rect")
        .attr("x", event.x - 36)
        .attr("y", event.y - 36);

      container.select("text").attr("x", event.x).attr("y", event.y);

      container
        .select("image")
        .attr("x", event.x - 7)
        .attr("y", event.y - 7);

      updateNodes(this.id, event.x, event.y);
    },
    [updateNodes]
  );

  function dragended() {
    // g.attr("cursor", "grab");
  }

  useEffect(() => {
    const svg = d3.select(canvas.current);

    // const g = svg.select("#contentWrap").append("g").attr("cursor", "grab");
    // const g = svg.append("g").attr("cursor", "grab");
    const contentWrap = svg.select("#contentWrap");
    // contentWrap.attr("pointer-events", "none");

    const g = svg.select("#shapes");

    g.selectAll(".node").call(
      d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended)
    );

    // g.selectAll("circle")
    //   .data(nodes)
    //   .join("circle")
    //   .attr("cx", ({ x }) => x)
    //   .attr("cy", ({ y }) => y)
    //   .attr("r", 20)
    //   .attr("fill", (d, i) => d.color)
    //   .attr("pointer-events", "all")
    //   .call(
    //     d3
    //       .drag()
    //       .on("start", dragstarted)
    //       .on("drag", dragged)
    //       .on("end", dragended)
    //   );

    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 10])
      .translateExtent([
        [-1200, -500],
        [5000, 5000],
      ])
      .on("zoom", zoomed);

    function zoomed(e) {
      const { k, x, y } = e.transform;
      // This moves the map!
      contentWrap.attr("transform", `translate(${x},${y}) scale(${k})`);
      setTranslateScale({ x, y, scale: k });
    }

    svg.call(zoom);
    // svg.on("click", function (e) {
    //   const [x, y] = d3.pointer(e);

    //   setTestNodes((pre) => {
    //     return [
    //       ...pre,
    //       {
    //         x: (x - translateScale.x) / translateScale.scale,
    //         y: (y - translateScale.y) / translateScale.scale,
    //       },
    //     ];
    //   });
    // });

    // It's below the map because our circles from earlier need to be on top!
    // svg
    //   .append("rect")
    //   .attr("class", "mouse-capture")
    //   .attr("x", -5000)
    //   .attr("y", -5000)
    //   .attr("width", 15000)
    //   .attr("height", 15000)
    //   .style("fill", "white")
    //   .lower() // put it below the map
    //   .call(zoom);
  }, [dragged, translateScale]);

  function handleOnMouseOver(e, isBuilding) {
    e.stopPropagation();

    let ID = e.target.id;

    if (isBuilding) {
      ID = e.target.classList[1];
    }

    onMapDataChange(ID, isBuilding);
  }

  function handleOnClick(e) {
    const [x, y] = d3.pointer(e);
    const newNode = {
      name: "NEW",
      group: "",
      x: (x - translateScale.x) / translateScale.scale,
      y: (y - translateScale.y) / translateScale.scale,
      linkedEntitiy: "",
      linkedEntitiyType: "",
      color: "#000",
      rotation: 0,
    };

    onAddShape(newNode);

    setIsClickOnMapEnabled(false);
  }

  function handleOnSvgPartsClicked(e) {
    setClickedSVGPartID(e);
  }

  return (
    <div className="school-map-svg__container-1">
      <svg
        ref={canvas}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-500 0 3300 3200"
        onClick={isClickOnMapEnabled ? handleOnClick : () => {}}
        style={{ cursor: isClickOnMapEnabled && "crosshair" }}
      >
        <SchoolSVGRenderer
          handleOnMouseOver={handleOnMouseOver}
          handleOnSvgPartsClicked={handleOnSvgPartsClicked}
          onBuildingClickedForAddingInfo={onBuildingClickedForAddingInfo}
          isAddBuildingInfoEnabled={isAddBuildingInfoEnabled}
          isAddRoomInfoEnabled={isAddRoomInfoEnabled}
          isAddSiteSystemEnabled={isAddSiteSystemEnabled}
          onSVGClickedForAddingSiteSystem={onSVGClickedForAddingSiteSystem}
        >
          <SchoolMapPlaygroundShapes
            nodes={nodes}
            onClickShape={onClickShape}
            onDoubleClickShape={onDoubleClickShape}
          />
        </SchoolSVGRenderer>
      </svg>
    </div>
  );
};

export default SchoolMapPlaygroundMain;
