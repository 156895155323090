import React, { useState, useEffect, useCallback } from "react";
import Navbar from "./Navbar";
import SchoolMapPlayground from "./SchoolMapPlayground";
import SchoolMapToolbar from "./SchoolMapToolbar";
import Modal from "./common/Modal";
import buildingService, { saveBuilding } from "./../services/buildingService";
import { useParams } from "react-router-dom";
import schoolMapService from "../services/schoolMapService";
// import AddLayerForm from "./AddLayerForm";
import { hasHardCodedMap } from "../data/School Svgs/HardCodedMapsList";
import SchoolMapSelectEntityType from "./SchoolMapSelectEntityType";
import SchoolMapSelectEntity from "./SchoolMapSelectEntity";
import roomService, { saveRoom } from "../services/roomService";
import LoadingDots from "./common/LoadingDots";
import ModalHeaderForForms from "./ModalHeaderForForms";
import {
  faBarsStaggered,
  faBuilding,
  faMapMarked,
  faPeopleRoof,
} from "@fortawesome/pro-regular-svg-icons";
import AddBuilding from "./Building/AddBuilding";
import { log } from "../services/logService";
import { toast } from "react-toastify";
import AddRoom from "./Room/AddRoom";
import siteDetailService from "../services/siteDetailService";
import SchoolMapRoomsList from "./SchoolMapRoomsList";
import AddSiteDetail from "./Site/AddSiteDetail";
import SchoolMapDataList from "./SchoolMapDataList";
import { useStore } from "../store";

const saveMethods = {
  building: saveBuilding,
  room: saveRoom,
};

const SchoolMap = ({ user }) => {
  const setCurrentBuilding = useStore((state) => state.setCurrentBuilding);
  const setCurrentRoom = useStore((state) => state.setCurrentRoom);
  const [mapData, setMapData] = useState(null);
  const [activeLayer, setActiveLayer] = useState("");
  const [mapID, setMapID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shapes, setShapes] = useState([]);
  const [shapesToPrint, setshapesToPrint] = useState([]);

  const [mapImg, setMapImg] = useState(null);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [selectedShapes, setSelectedShapes] = useState([]);
  const [shapeLinkEntityType, setShapeLinkEntityType] = useState("Site System");
  const [buildings, setBuildings] = useState([]);
  const [activeLayerIndex, setActiveLayerIndex] = useState(0);
  const [isAddIconFormshown, setIsAddIconFormshown] = useState(false);
  const [isAddTextFormShown, setIsAddTextFormShown] = useState(false);
  const [lockedShapes, setLockedShapes] = useState([]);
  const [clickedShapeLinkedEntity, setClickedShapeLinkedEntity] =
    useState(null);
  const [dataId, setDataId] = useState({ id: "", buildingID: "" });
  const [isClickOnMapEnabled, setIsClickOnMapEnabled] = useState(false);
  const [typeOfShapeToAdd, setTypeOfShapeToAdd] = useState("");
  const [linksToSVGParts, setLinksToSVGParts] = useState({});
  const [isAddBuildingInfoEnabled, setIsAddBuildingInfoEnabled] =
    useState(false);
  const [isAddBuildingDetailOpen, setIsAddBuildingDetailOpen] = useState(false);
  const [clickedBuildingSVGPart, setClickedBuildingSVGPart] = useState(null);
  const [isAddRoomInfoEnabled, setIsAddRoomInfoEnabled] = useState(false);
  const [isAddRoomInfoOpen, setIsAddRoomInfoOpen] = useState(false);
  const [clickedRoomBuildingID, setClickedRoomBuildingID] = useState(null);
  const [clickedRoomIDToAddInfo, setClickedRoomIDToAddInfo] = useState(null);
  const [buildingToEdit, setBuildingToEdit] = useState(null);
  const [siteDetails, setSiteDetails] = useState([]);
  const [isListOfRoomsOpen, setIsListOfRoomsOpen] = useState(false);
  const [buildingToShowRooms, setBuildingToShowRooms] = useState(null);
  const [roomToEdit, setRoomToEdit] = useState(null);
  const [clickedRoomArea, setClickedRoomArea] = useState(null);
  const [isAddSiteSystemEnabled, setIsAddSiteSystemEnabled] = useState(false);
  const [siteSystemSVGPartID, setSiteSystemSVGPartID] = useState(null);
  const [isAddSiteSystemOpen, setIsAddSiteSystemOpen] = useState(false);
  const [siteSystemToEdit, setSiteSystemToEdit] = useState(null);
  const [clickedBuildingArea, setClickedBuildingArea] = useState(null);
  const [clickedBuildingInteriorArea, setClickedBuildingInteriorArea] =
    useState(0);
  const [isDataListModalOpen, setIsDataListModalOpen] = useState(false);
  const [dataListToLoad, setDataListToLoad] = useState("");
  // const [rooms, setRooms] = useState([]);

  const { siteID } = useParams();

  const loadResource = useCallback(
    async function () {
      // Load map if exists
      const { data: resMapData } = await schoolMapService.getSchoolmapBySite(
        siteID
      );
      if (resMapData && resMapData.success && resMapData.data.length > 0) {
        let mapData = resMapData.data[0];

        if (
          hasHardCodedMap(siteID) &&
          mapData.layers[0].name !== "Interactive"
        ) {
          const newMapData = { ...mapData };
          const newLayers = [...newMapData.layers];
          newLayers.unshift({
            name: "Interactive",
            shapes: [],
            groups: [],
            mapImg: "Interactive",
          });

          newMapData.layers = newLayers;
          mapData = newMapData;
        }

        setMapData(mapData);
        const currentLayer = mapData.layers[0];
        setActiveLayer(currentLayer.name);
        setMapImg(currentLayer.mapImg);
        setShapes(currentLayer.shapes);
        setshapesToPrint(currentLayer.shapes);
        setLinksToSVGParts(mapData.linksToSVGParts);

        setMapID(resMapData.data[0]?._id);
      } else if (
        resMapData &&
        resMapData.success &&
        resMapData.data.length === 0 &&
        hasHardCodedMap(siteID)
      ) {
        const newLayer = {
          name: "Interactive",
          mapImg: "Interactive",
          shapes: [],
          groups: [],
        };
        setMapData({
          layers: [newLayer],
          siteID,
        });
        setActiveLayer(newLayer.name);
        setMapImg(newLayer.mapImg);
        setShapes(newLayer.shapes);
        setshapesToPrint(newLayer.shapes);
      }

      setIsLoading(false);
    },
    [siteID]
  );

  useEffect(() => {
    loadResource();
  }, [loadResource]);

  useEffect(() => {
    const loadData = async () => {
      // load buildings
      const { data: res } = await buildingService.getBuildingsBySite(siteID);
      if (res && res.data && res.data.length && res.data.length !== 0)
        setBuildings(res.data);

      const { data: res1 } = await siteDetailService.getSiteDetailsBySite(
        siteID
      );

      if (res1 && res1.data && res1.data.length && res1.data.length !== 0)
        setSiteDetails(res1.data);
    };

    loadData();
  }, [siteID]);

  function handleSetRect(rects) {
    setShapes(rects);

    // setRectsToPrint(rects);
    // localStorage.setItem(`map__positions__brett`, JSON.stringify(rects));
  }

  function handleOnEntitySelect(id, shapeName) {
    const newRects = [...shapes];
    const shapeIndex = newRects.findIndex((rect) => rect.name === shapeName);
    if (shapeIndex !== -1) {
      const newShape = { ...newRects[shapeIndex] };
      newShape.linkedEntitiy = id;
      newShape.linkedEntitiyType = shapeLinkEntityType;
      newRects[shapeIndex] = newShape;
      setShapes(newRects);
      setshapesToPrint(newRects);
      setSelectedShapes([]);
      setShapeLinkEntityType("");
      setIsLinkModalOpen(false);

      // linkedEntitiy: ""
      // linkedEntitiyType: ""
    }
  }

  async function handleSaveMapData() {
    const newMapData = {
      ...mapData,
      layers: [...mapData.layers],
      siteID,
      linksToSVGParts,
    };

    newMapData.layers[activeLayerIndex] = {
      name: activeLayer,
      mapImg,
      shapes: shapes,
    };

    await schoolMapService.saveSchoolMap(newMapData, mapID);
  }

  const getClickedShapeDetail = async (shape) => {
    if (!shape || !shape.linkedEntitiy || !shape.linkedEntitiyType)
      return setClickedShapeLinkedEntity(null);

    if (shape.linkedEntitiyType === "building") {
      try {
        const { data: res } = await buildingService.getBuildingsByID(
          shape.linkedEntitiy
        );
        if (res.success) {
          setClickedShapeLinkedEntity({ ...res.data, type: "Building" });
          setDataId({ id: "", buildingID: "" });
        }
      } catch (error) {
        setClickedShapeLinkedEntity(null);
      }
    } else if (shape.linkedEntitiyType === "room" && shape?.linkedEntitiy) {
      try {
        const { data: res } = await roomService.getRoomById(
          shape.linkedEntitiy
        );
        if (res.success) {
          setClickedShapeLinkedEntity({ ...res.data, type: "Room" });
          // console.log(res.data?.buildingOrFacilityID);
          setDataId({ id: "", buildingID: "" });
          if (res.data?.buildingOrFacilityID !== null) {
            const { data: buildingSecond } =
              await buildingService.getBuildingsByID(
                res.data?.buildingOrFacilityID
              );
            setCurrentBuilding(buildingSecond?.data);
            // console.log(buildingSecond?.data);
          }
          if (res.data?.buildingOrFacilityID === "") {
            setCurrentBuilding({});
          }
        }
      } catch (error) {
        setClickedShapeLinkedEntity(null);
      }
    } else if (
      shape.linkedEntitiyType === "Site System" &&
      shape?.linkedEntitiy
    ) {
      try {
        const { data: res } = await siteDetailService.getSiteDetailById(
          shape.linkedEntitiy
        );
        if (res.success) {
          setClickedShapeLinkedEntity({ ...res.data, type: "Site System" });
          setDataId({ id: "", buildingID: "" });
        }
      } catch (error) {
        setClickedShapeLinkedEntity(null);
      }
    }
  };

  function handleOnSvgPartsHover(id) {
    if (!linksToSVGParts) return;

    const data = linksToSVGParts[id];
    if (data) {
      // if (data.linkType === "building") {
      //   const building = buildings.find(
      //     (record) => record._id === data.linkedEntitiyID
      //   );

      //   if (building) {
      //     setClickedShapeLinkedEntity({ ...building, type: "Building" });
      //     setDataId({ id: "", buildingID: "" });
      //     return;
      //   }
      // }

      getClickedShapeDetail({
        linkedEntitiyType: data.linkType,
        linkedEntitiy: data.linkedEntitiyID,
      });
    }
  }

  function handleAddEditShape(shape, oldName) {
    if (!oldName && shape.name === "NEW") {
      if (typeOfShapeToAdd === "text") shape.fontSize = 12;

      if (typeOfShapeToAdd === "icon") shape.icon = 5;

      setShapes([...shapes, shape]);
      setshapesToPrint([...shapes, shape]);
      return;
    } else if (!oldName && shape.name !== "NEW") {
      const newShapes = [...shapes];
      const newShape = { ...newShapes[newShapes.length - 1] };
      newShape.name = shape.name;
      newShape.color = shape.color;
      newShape.opacity = shape.opacity;
      newShape.group = shape.group;
      newShape.fontSize = shape.fontSize;
      newShape.text = shape.text;
      newShape.icon = shape.icon;
      newShape.type = shape?.type;
      newShape.SVGPartID = shape?.SVGPartID;
      newShapes[newShapes.length - 1] = newShape;
      setShapes(newShapes);
      setshapesToPrint(newShapes);
    } else {
      const newShapes = [...shapes];

      const index = newShapes.findIndex(
        (shape) => shape.name.toLowerCase() === oldName.toLowerCase()
      );
      const newShape = { ...newShapes[index] };
      newShape.name = shape.name;
      newShape.color = shape.color;
      newShape.opacity = shape.opacity;
      newShape.group = shape.group;
      newShape.fontSize = shape.fontSize;
      newShape.text = shape.text;
      newShape.icon = shape.icon;
      newShapes[index] = newShape;
      setShapes(newShapes);
      setshapesToPrint(newShapes);
    }

    handleSaveMapData();
  }

  function saveCurrentLayer() {
    const newMapData = { ...mapData };
    const newLayers = [...newMapData.layers];
    const newLayer = { ...newLayers[activeLayerIndex] };
    newLayer.name = activeLayer;
    newLayer.mapImg = mapImg;
    newLayer.shapes = shapes;
    newLayers[activeLayerIndex] = newLayer;
    newMapData.layers = newLayers;
    setMapData(newMapData);
  }

  function handleOnChangeLayer(index) {
    saveCurrentLayer();

    setActiveLayerIndex(index);
    const newActiveLayer = { ...mapData.layers[index] };
    setActiveLayer(newActiveLayer.name);
    setMapImg(newActiveLayer.mapImg);
    setShapes(newActiveLayer.shapes);
    setshapesToPrint(newActiveLayer.shapes);
  }

  function handleAddLayer(layerName) {
    const newLayer = {
      name: layerName,
      mapImg: "",
      shapes: [],
      groups: [],
    };

    if (!mapData)
      setMapData({
        layers: [newLayer],
        siteID,
      });
    else {
      const newActiveLayerIndex = mapData.layers.length;
      const newMapData = { ...mapData };
      const newLayers = [...newMapData.layers];

      // save changes to current layer before switching
      const layerToSave = { ...newLayers[activeLayerIndex] };
      layerToSave.name = activeLayer;
      layerToSave.mapImg = mapImg;
      layerToSave.shapes = shapes;
      newLayers[activeLayerIndex] = layerToSave;

      // Add new Layer
      newLayers.push(newLayer);
      newMapData.layers = newLayers;

      setMapData(newMapData);
      setActiveLayerIndex(newActiveLayerIndex);
    }

    setActiveLayer(layerName);
    setMapImg(null);
    setShapes([]);
  }

  // eslint-disable-next-line no-unused-vars
  function doDeleteShape(shapeObj) {
    const { name: shape } = shapeObj;

    const newRects = [...shapes];
    const shapeIndex = newRects.findIndex(
      (r) => r.name.toLowerCase() === shape.toLowerCase()
    );
    newRects.splice(shapeIndex, 1);
    setShapes(newRects);
    setshapesToPrint(newRects);

    handleSaveMapData();
  }

  function isLocked(name) {
    return lockedShapes.findIndex(
      (s) => s.toLowerCase() === name.toLowerCase()
    ) === -1
      ? false
      : true;
  }

  // eslint-disable-next-line no-unused-vars
  function handleOnToggleLockShape(name) {
    const index = lockedShapes.findIndex(
      (s) => s.toLowerCase() === name.toLowerCase()
    );

    if (index === -1) return setLockedShapes((pre) => [...pre, name]);

    const newLockedShapes = [...lockedShapes];
    newLockedShapes.splice(index, 1);
    setLockedShapes(newLockedShapes);
  }

  function onDoubleClickShape(shape) {
    setSelectedShapes([shape]);
    setIsLinkModalOpen(true);
  }

  function getEntityOptions(entityType) {
    if (entityType === "building" || entityType === "room") return buildings;

    if (entityType === "Site System") return siteDetails;

    return [];
  }

  function handleCancelAddShape() {
    const newShapes = [...shapes];
    const lastElement = { ...newShapes[newShapes.length - 1] };
    if (lastElement.name === "NEW") {
      newShapes.pop();
      setShapes(newShapes);
      setshapesToPrint(newShapes);
    }
  }

  async function onBuildingClickedForAddingInfo(e) {
    const SVGbuildingID = e.target.classList[1];
    console.log(e.target.attributes.dataarea?.nodeValue);
    const linkData = linksToSVGParts[SVGbuildingID];
    console.log(linkData);
    setClickedBuildingArea(e.target.attributes.dataarea?.nodeValue);
    setClickedBuildingInteriorArea(
      e.target.attributes.datainteriorarea?.nodeValue
    );
    if (linkData) {
      try {
        const { data: res } = await buildingService.getBuildingsByID(
          linkData.linkedEntitiyID
        );
        console.log(res);
        if (isAddBuildingInfoEnabled && res.success) {
          setBuildingToEdit(res.data);
          setClickedBuildingSVGPart(SVGbuildingID);
          setIsAddBuildingDetailOpen(true);
        } else {
          setClickedBuildingSVGPart(SVGbuildingID);
          setIsAddBuildingDetailOpen(true);
        }
      } catch (error) {
        // Checking if the error response is a 404
        if (error.response && error.response.status === 404) {
          setClickedBuildingSVGPart(SVGbuildingID);
          setIsAddBuildingDetailOpen(true); // This is a function you'd define to handle the 404 response
        }
      }
    } else {
      setClickedBuildingSVGPart(SVGbuildingID);
      setIsAddBuildingDetailOpen(true);
    }
    // if (linkData) {
    //   const { data: res } = await buildingService.getBuildingsByID(
    //     linkData.linkedEntitiyID
    //   );
    //   console.log(res);
    //   if (isAddBuildingInfoEnabled && res.success) {
    //     setBuildingToEdit(res.data);
    //     setClickedBuildingSVGPart(SVGbuildingID);
    //     setIsAddBuildingDetailOpen(true);
    //   } else {
    //     setClickedBuildingSVGPart(SVGbuildingID);
    //     setIsAddBuildingDetailOpen(true);
    //   }
    // }
  }

  async function handleSaveBuilding(formData, type, id) {
    try {
      let { data: groupRecord } = await saveMethods[type](formData);

      setIsAddBuildingInfoEnabled(false);
      // setIsAddBuildingDetailOpen(false);
      setLinksToSVGParts((pre) => ({
        ...pre,
        [clickedBuildingSVGPart]: {
          linkType: "building",
          linkedEntitiyID: groupRecord.data._id,
        },
      }));
      setBuildingToEdit(null);

      return groupRecord.data;
    } catch (error) {
      log(error);
      toast.error("Something went wrong");
    }
  }

  // adding room data -------
  // changes start
  async function handleSetClickedSVGPartID(e) {
    const SVGRoomID = e.target.id;
    const linkData = linksToSVGParts[SVGRoomID];
    const buildingID = e.target.classList[1];
    const data = linksToSVGParts[buildingID];
    console.log(data);
    if (linkData) {
      try {
        const { data: res } = await roomService.getRoomById(
          linkData.linkedEntitiyID
        );

        if (isAddRoomInfoEnabled && res.success) {
          setRoomToEdit(res.data);
          setClickedRoomIDToAddInfo(SVGRoomID);
          setIsAddRoomInfoOpen(true);
        } else {
          if (data && data.linkType === "building") {
            setClickedRoomBuildingID(data.linkedEntitiyID);
            setClickedRoomIDToAddInfo(e.target.id);
            setClickedRoomArea(e.target.attributes.dataarea?.nodeValue);
            setIsAddRoomInfoOpen(true);
          } else {
            toast.info(
              "Please add the building info then try to add the room info"
            );
          }
          setIsAddRoomInfoEnabled(false);
        }
      } catch (error) {
        console.error("Error fetching room data:", error);
        // Check if the error response is a 404
        if (error.response && error.response.status === 404) {
          console.log(error);
        }
      }
    } else if (data && data.linkType === "building") {
      setClickedRoomBuildingID(data.linkedEntitiyID);
      setClickedRoomIDToAddInfo(e.target.id);
      setClickedRoomArea(e.target.attributes.dataarea?.nodeValue);
      setIsAddRoomInfoOpen(true);
    } else {
      toast.info("Please add the building info then try to add the room info");
    }
    setIsAddRoomInfoEnabled(false);
  }

  async function handleSaveRoomInfo(formData, type) {
    try {
      let { data: newRecord } = await saveMethods[type](formData);
      const { data: roomData } = newRecord;
      handleAddEditShape({
        name: roomData.roomNumber,
        group: roomData.buildingName,
        type: "roomData",
        linkedEntitiy: "",
        linkedEntitiyType: "",
        SVGPartID: clickedRoomIDToAddInfo,
      });
      setIsAddRoomInfoEnabled(false);
      // setIsAddRoomInfoOpen(false);
      setClickedRoomBuildingID(null);

      setLinksToSVGParts((pre) => ({
        ...pre,
        [clickedRoomIDToAddInfo]: {
          linkType: "room",
          linkedEntitiyID: roomData._id,
        },
      }));

      return roomData;
    } catch (error) {
      log(error);
      toast.error("Something went wrong");
    }
  }

  async function onSVGClickedForAddingSiteSystem(e) {
    const clickedSVGID = e.target.id;
    if (isAddSiteSystemEnabled) {
      setSiteSystemSVGPartID(clickedSVGID);
      setIsAddSiteSystemOpen(true);

      return;
    }

    const linkData = linksToSVGParts[clickedSVGID];
    if (linkData) {
      const { data: res } = await siteDetailService.getSiteDetailById(
        linkData.linkedEntitiyID
      );
      if (res.success) {
        // The editing logic goes here
        setSiteSystemToEdit(res.data);
        setSiteSystemSVGPartID(clickedSVGID);
        setIsAddSiteSystemOpen(true);
      }
    }
  }

  function handleSaveSiteSystem(data, type, id) {
    const newID = data._id;
    if (newID) {
      setIsAddSiteSystemEnabled(false);
      setLinksToSVGParts((pre) => ({
        ...pre,
        [siteSystemSVGPartID]: {
          linkType: "Site System",
          linkedEntitiyID: newID,
        },
      }));

      handleSaveMapData();
    }
  }

  function onDataListClicked(dataToLoad) {
    setDataListToLoad(dataToLoad);
    setIsDataListModalOpen(true);
  }

  function getDataList() {
    if (dataListToLoad === "Building") {
      return buildings;
    } else if (dataListToLoad === "Room") {
      return [];
    } else if (dataListToLoad === "Site System") {
      return siteDetails;
    }
  }

  const onAddShapeClicked = (type) => {
    const methods = {
      icon: setIsAddIconFormshown,
      text: setIsAddTextFormShown,
    };

    methods[type](type);

    // setIsAddIconFormshown(true);
    // setIsAddTextFormShown(false);
    setTypeOfShapeToAdd(type);
  };

  const onAddBuildingInfo = () => {
    setIsAddBuildingInfoEnabled((isEnabled) => !isEnabled);
    setIsAddRoomInfoEnabled(false);
    setIsAddSiteSystemEnabled(false);
  };

  const onAddRoomInfo = () => {
    setIsAddRoomInfoEnabled((isEnabled) => !isEnabled);
    setIsAddBuildingInfoEnabled(false);
    setIsAddSiteSystemEnabled(false);
  };

  const onAddSiteSystem = () => {
    setIsAddSiteSystemEnabled((isEnabled) => !isEnabled);
    setIsAddBuildingInfoEnabled(false);
    setIsAddRoomInfoEnabled(false);
  };

  if (clickedShapeLinkedEntity?.type === "Building") {
    setCurrentBuilding(clickedShapeLinkedEntity);
    setCurrentRoom(null);
  } else if (clickedShapeLinkedEntity?.type === "Room") {
    setCurrentRoom(clickedShapeLinkedEntity);
    // setCurrentBuilding(null);
  }
  // else {
  //   setCurrentBuilding(null);
  //   setCurrentRoom(null);
  // }
  return (
    // <SchoolMapToPrint ref={node}>
    <div className="d-flex flex-column" style={{ overflowY: "hidden" }}>
      <Navbar user={user} />

      <LoadingDots isLoading={isLoading} />

      {!isLoading && !activeLayer && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100vw", height: "85vh" }}
        >
          No Map added yet!
        </div>
      )}

      {!isLoading && activeLayer && (
        <div className="school-map__container">
          <SchoolMapToolbar
            shapes={shapes}
            onAddShape={handleAddEditShape}
            handleSaveMapData={handleSaveMapData}
            isAddIconFormshown={isAddIconFormshown}
            setIsAddIconFormshown={setIsAddIconFormshown}
            isAddTextFormShown={isAddTextFormShown}
            setIsAddTextFormShown={setIsAddTextFormShown}
            onAddIconClicked={() => onAddShapeClicked("icon")}
            onAddTextClicked={() => onAddShapeClicked("text")}
            setIsClickOnMapEnabled={setIsClickOnMapEnabled}
            isClickOnMapEnabled={isClickOnMapEnabled}
            handleCancelAddShape={handleCancelAddShape}
            onAddBuildingInfo={onAddBuildingInfo}
            onAddRoomInfo={onAddRoomInfo}
            onAddSiteSystemInfo={onAddSiteSystem}
            setIsAddSiteSystemOpen={setIsAddSiteSystemOpen}
            onDataListClicked={onDataListClicked}
          />

          <SchoolMapPlayground
            rects={shapesToPrint}
            setRects={handleSetRect}
            mapImg={mapImg}
            setMapImg={setMapImg}
            onClickShape={getClickedShapeDetail}
            onClearMapImg={() => setMapImg(null)}
            isClearMapImgDisabled={!mapImg}
            layers={mapData && mapData.layers ? mapData.layers : []}
            activeLayerIndex={activeLayerIndex}
            handleOnChangeLayer={handleOnChangeLayer}
            activeLayer={activeLayer}
            setActiveLayer={setActiveLayer}
            onAddLayer={handleAddLayer}
            onAddIconClicked={() => {
              setIsAddIconFormshown(true);
              setIsAddTextFormShown(false);
              setTypeOfShapeToAdd("icon");
            }}
            isAddTextFormShown={isAddTextFormShown}
            onAddText={() => {
              setIsAddTextFormShown(true);
              setIsAddIconFormshown(false);
              setTypeOfShapeToAdd("text");
            }}
            isLocked={isLocked}
            onDoubleClickShape={onDoubleClickShape}
            rectsToPrint={shapesToPrint}
            dataId={dataId}
            onMapDataChange={(id, isBuilding) => {
              handleOnSvgPartsHover(id, isBuilding);
              setClickedShapeLinkedEntity(null);
            }}
            // onMapDataChange={(param) => {
            //   setDataId(param);
            //   setClickedShapeLinkedEntity(null);
            // }}
            isClickOnMapEnabled={isClickOnMapEnabled}
            setIsClickOnMapEnabled={setIsClickOnMapEnabled}
            onAddShape={handleAddEditShape}
            clickedRectDetail={clickedShapeLinkedEntity}
            setClickedSVGPartID={handleSetClickedSVGPartID}
            onBuildingClickedForAddingInfo={onBuildingClickedForAddingInfo}
            isAddBuildingInfoEnabled={isAddBuildingInfoEnabled}
            isAddRoomInfoEnabled={isAddRoomInfoEnabled}
            buildings={buildings}
            isAddSiteSystemEnabled={isAddSiteSystemEnabled}
            onSVGClickedForAddingSiteSystem={onSVGClickedForAddingSiteSystem}
            clickedBuildingInteriorArea={clickedBuildingInteriorArea}
          />
        </div>
      )}

      {/* <DeleteDialog
        isOpen={isDeleteDialogOpen}
        message="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum velit
      molestias doloribus quaerat."
        onRequestClose={() => setIsDeleteDialogOpen(false)}
        onDelete={() => doDeleteShape(shapeToDelete.name, shapeToDelete.group)}
      /> */}

      <Modal
        isOpen={isLinkModalOpen}
        onRequestClose={() => {
          setShapeLinkEntityType("");
          setIsLinkModalOpen(false);
          setSelectedShapes([]);
        }}
      >
        <SchoolMapSelectEntityType
          shapeLinkEntityType={shapeLinkEntityType}
          setShapeLinkEntityType={setShapeLinkEntityType}
          isForLink={true}
        />

        <SchoolMapSelectEntity
          shapeLinkEntityType={shapeLinkEntityType}
          options={getEntityOptions(shapeLinkEntityType)}
          handleOnEntitySelect={handleOnEntitySelect}
          selectedShapes={selectedShapes}
          title="Select a "
          idKey="_id"
          valueKey="name"
          secondaryValueKey="buildingName"
          // secondaryValueKey="buildingID"
        />
      </Modal>

      <Modal
        isOpen={isAddBuildingDetailOpen}
        onRequestClose={() => {
          setIsAddBuildingDetailOpen(false);
          setIsAddBuildingInfoEnabled(false);
          setBuildingToEdit(null);
          setClickedBuildingArea(null);
        }}
      >
        <ModalHeaderForForms
          formId={"addBuilding"}
          onRequestClose={() => {
            setIsAddBuildingDetailOpen(false);
            setIsAddBuildingInfoEnabled(false);
            setBuildingToEdit(null);
            setClickedBuildingArea(null);
          }}
          title={
            buildingToEdit && buildingToEdit.buildingName
              ? buildingToEdit.buildingName
              : "Add Building Detail"
          }
          icon={faBuilding}
        />
        <div className="form-on-modal-container">
          <div className="modal-content-1">
            <AddBuilding
              filters={{ site: siteID }}
              formId="addBuilding"
              onRequestClose={() => {
                setIsAddBuildingDetailOpen(false);
                setIsAddBuildingInfoEnabled(false);
                setBuildingToEdit(null);
                setClickedBuildingArea(null);
              }}
              saveData={handleSaveBuilding}
              data={buildingToEdit}
              buildingArea={clickedBuildingArea}
              clickedBuildingInteriorArea={clickedBuildingInteriorArea}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isAddRoomInfoOpen}
        onRequestClose={() => {
          setIsAddRoomInfoOpen(false);
          setIsAddRoomInfoEnabled(false);
          setRoomToEdit(null);
          setClickedRoomArea(null);
        }}
      >
        <ModalHeaderForForms
          onRequestClose={() => {
            setIsAddRoomInfoOpen(false);
            setIsAddRoomInfoEnabled(false);
            setRoomToEdit(null);
            setClickedRoomArea(null);
          }}
          title="Add Room"
          icon={faPeopleRoof}
          formId={"addRoom"}
        />
        <div className="form-on-modal-container">
          <div className="modal-content-1">
            <AddRoom
              filters={{ site: siteID, building: clickedRoomBuildingID }}
              formId={"addRoom"}
              onRequestClose={() => {
                setIsAddRoomInfoOpen(false);
                setIsAddRoomInfoEnabled(false);
                setRoomToEdit(null);
                setClickedRoomArea(null);
              }}
              saveData={handleSaveRoomInfo}
              data={roomToEdit}
              roomArea={clickedRoomArea}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isListOfRoomsOpen}
        onRequestClose={() => {
          setIsListOfRoomsOpen(false);
          setBuildingToShowRooms(null);
        }}
      >
        <SchoolMapRoomsList buildingID={buildingToShowRooms} />
      </Modal>
      <Modal
        isOpen={isAddSiteSystemOpen}
        onRequestClose={() => {
          setIsAddSiteSystemOpen(false);

          // setDetailFeature(null);
          // setDetails(null);
        }}
        // width="40rem"
      >
        <ModalHeaderForForms
          formId={"addSiteDetail"}
          onRequestClose={() => {
            setIsAddSiteSystemOpen(false);

            // setDetailsToEdit(null);
          }}
          title="Site Systems"
          icon={faMapMarked}
        />
        <div className="form-on-modal-container">
          <div className="modal-content-1">
            <AddSiteDetail
              filters={{ site: siteID }}
              formId="addSiteDetail"
              onRequestClose={() => {
                setIsAddSiteSystemOpen(false);

                // setDetailsToEdit(null);
              }}
              saveData={handleSaveSiteSystem}
              data={siteSystemToEdit}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isDataListModalOpen}
        onRequestClose={() => {
          setIsDataListModalOpen(false);
          setDataListToLoad("");
        }}
      >
        <ModalHeaderForForms
          hideSaveButton={true}
          onRequestClose={() => {
            setIsDataListModalOpen(false);
            setDataListToLoad("");
          }}
          title="List"
          icon={faBarsStaggered}
        />

        <SchoolMapDataList
          data={getDataList()}
          type={dataListToLoad}
          onEditBuilding={(b) => {
            setBuildingToEdit(b);
            setIsAddBuildingDetailOpen(true);
          }}
          onEditSiteSystem={(s) => {
            setSiteSystemToEdit(s);
            setIsAddSiteSystemOpen(true);
          }}
        />
      </Modal>
    </div>
    // </SchoolMapToPrint>
  );
};

export default SchoolMap;
