import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PhotoPreviewForForms = ({ data, setData }) => {
  return (
    <PhotoProvider>
      {data.map((item, index) => (
        <PhotoView key={item} src={item}>
          <span
            key={item}
            className="d-inline-block align-items-start position-relative mx-2"
          >
            <img
              src={item}
              alt=""
              className="h-full rounded-lg"
              width="100px"
            />
            <span
              role="button"
              className="text-danger"
              onClick={() => {
                const newImages = [...data];
                newImages.splice(index, 1);
                setData(newImages);
              }}
              style={{
                position: "absolute",
                top: -5,
                right: -5,
                // color: "tomato",
                // fontSize: "1rem",
              }}
            >
              <div className=" rounded-full w-8 h-8 bg-700 flex flex-col align-middle justify-center">
                <FontAwesomeIcon icon={faTrashCan} color="white" />
              </div>
            </span>
          </span>
        </PhotoView>
      ))}
    </PhotoProvider>
  );
};

export default PhotoPreviewForForms;
