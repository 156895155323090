import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileChartPie } from "@fortawesome/pro-regular-svg-icons";
import { getBuildingsBySite } from "../services/buildingService";
import { BUILDING_COLUMNS } from "../data/buildingColumns";
import Table from "./common/Table";

const EligibleBuildings = ({ siteID }) => {
  const getEligibleDate = (buildingType, yearBuild, modernizationDate) => {
    if (buildingType === "Permanent" && modernizationDate === "0") {
      return Number(yearBuild) + 25;
    }
    if (buildingType === "Permanent" && modernizationDate !== "0") {
      return Number(modernizationDate) + 25;
    }
    if (buildingType === "Portable" && modernizationDate === "0") {
      return Number(yearBuild) + 20;
    }
    if (buildingType === "Portable" && modernizationDate !== "0") {
      return Number(modernizationDate) + 20;
    }
    return 0;
  };
  const date = new Date();
  const year = date.getFullYear();
  const [buildings, setBuildings] = useState([]);
  useEffect(() => {
    const fetchBuildings = async () => {
      const { data } = await getBuildingsBySite(siteID);
      console.log(data.data);
      let filteredData = data.data.filter(
        (building) =>
          getEligibleDate(
            building.buildingType,
            building.yearBuild,
            building.sfpModDate
          ) <= parseInt(year)
      );
      setBuildings(filteredData);
    };
    fetchBuildings();
  }, [siteID]);
  console.log(buildings);
  return (
    <div className="d-flex flex-column" style={{ overflowY: "hidden" }}>
      {/* <Navbar user={user} /> */}
      <div className="mx-8 mb-4">
        <div className="flex flex-row justify-between text-center my-4 ml-2">
          <span className="w-40">{buildings[0]?.schoolName}</span>
          <span className="font-Rubik font-semibold">
            Eligible Buildings for Modernization
          </span>
          <span>
            <button
              className="flex justify-center bg-700 text-white p-2 px-3 rounded-md ml-2 text-sm"
              // onClick={onPrint}
            >
              <span>
                <FontAwesomeIcon icon={faFileChartPie} fontSize="18px" />
              </span>
              <span className="ml-2">Generate Report</span>
            </button>
          </span>
        </div>
        <Table tableData={buildings} tableColumns={BUILDING_COLUMNS} />
      </div>
    </div>
  );
};

export default EligibleBuildings;
