export const determineResourceToLoad = (filters) => {
  if (filters.building !== "") return "room";
  else if (filters.site !== "") return "building";
  else if (filters.district !== "") return "site";
  else return "district";
};

export const mergeSiteDetails = (siteDetails) => {
  return siteDetails?.reduce((a, c) => {
    return [...a, ...c.details];
  }, []);
};

export const stringToCommaSeparatedNum = (param) => {
  if (param.length > 0)
    return "$" + parseInt(param.replace(/\D/g, ""), 10).toLocaleString();

  return "";
};

export const handleCostChange = (e, formik, setTotalCost) => {
  let cost = e.target.value.replace(/[^0-9.]/g, "");
  // cost = cost.replace(/,/g, "");
  // cost = cost.replace("$", "");
  const soft_cost = formik.values.softCost.replace(/[^0-9.]/g, "");
  // const soft_cost = formik.values.softCost.replace(/%/g, "");

  calculateTotal(cost, soft_cost, setTotalCost);

  if (cost.length > 0) {
    const n = parseFloat(cost.replace(/\D/g, ""), 10).toLocaleString();

    e.target.value = "$" + n;
  }

  formik.handleChange(e);
};

export const handleSoftCostChange = (e, formik, setTotalCost) => {
  let cost = formik.values.constructionCost;
  cost = cost.replace(/,/g, "");
  cost = cost.replace("$", "");

  const soft_cost = e.target.value.replace(/%/g, "");

  calculateTotal(cost, soft_cost, setTotalCost);

  if (soft_cost.length > 0) e.target.value = soft_cost + "%";

  formik.handleChange(e);
};

export const calculateTotal = (constractionCost, softCost, setTotalCost) => {
  if (!constractionCost || !softCost || constractionCost <= 0 || softCost <= 0)
    setTotalCost(constractionCost);

  const softCostAmout = (+constractionCost * +softCost) / 100;
  const total = +softCostAmout + +constractionCost;
  if (isNaN(total)) return setTotalCost("");

  setTotalCost(total.toFixed(0));
  // else setTotalCost(constractionCost);
};
