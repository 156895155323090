import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  faEnvelope,
  faBuildings,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";

import IconInput from "../IconInput";

// Validation Schema
const validationSchema = Yup.object({
  name: Yup.string()
    .max(40, "Must be 15 characters or less")
    .min(3, "Must be 3 characters or more")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  orgName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .required("Required"),
});

const initialValues = {
  name: "",
  email: "",
  orgName: "",
};

const EditAdminUser = ({ formId, data, saveUser, onRequestClose }) => {
  const formik = useFormik({
    initialValues: data ? data : initialValues,
    validationSchema,
    onSubmit,
  });

  async function onSubmit(values) {
    try {
      await saveUser({
        name: values.name,
        email: values.email,
        orgName: values.orgName,
      });

      onRequestClose();
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      )
        toast.error(error.response.data.error);
      // console.log(error);
    }
  }

  return (
    <div className="mb-3">
      <form onSubmit={formik.handleSubmit} id={formId} className="space-y-4">
        <IconInput
          label="Full Name"
          icon={faUser}
          classes="mb-custom-3"
          error={formik.errors.name}
          touched={formik.touched.name}
          type="text"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <IconInput
          label="Work Email"
          icon={faEnvelope}
          classes="mb-custom-3"
          error={formik.errors.email}
          touched={formik.touched.email}
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <IconInput
          label="Organization"
          icon={faBuildings}
          classes="mb-custom-5"
          error={formik.errors.orgName}
          touched={formik.touched.orgName}
          type="text"
          name="orgName"
          value={formik.values.orgName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </form>
    </div>
  );
};

export default EditAdminUser;
