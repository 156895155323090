import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faLock,
  faEnvelope,
} from "@fortawesome/pro-regular-svg-icons";
import Button from "../Button";
import SignInUpHeader from "./SignInUpHeader";
import IconInput from "../IconInput";
import * as auth from "../../services/authService";
import { toast } from "react-toastify";
import logo from "../../assets/Icon_only_transparent.svg";
import PasswordField from "./../common/PasswordField";
import Cockpit from "./Cockpit";
// import { Link, useNavigate } from "react-router-dom";
// import IconButton from "../components/common/IconButton";

const validate = Yup.object({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(6, "Must be 6 characters or more")
    .required("Required"),
});

function SignIn() {
  const [passwordClear, setPasswordClear] = useState(false);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validate,
    onSubmit,
  });

  async function onSubmit(values) {
    try {
      await auth.login({
        email: values.email,
        password: values.password,
      });
      // navigate("/districts");
      window.location = "/dashboard";
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      )
        toast.error(error.response.data.error);
    }
  }

  function handleForgotPassword() {
    if (formik.values.email && !formik.errors.email)
      return auth.forgotPassword();

    if (formik.errors.email)
      return toast.warning("Please enter a valid email.");

    toast.info("Please enter your email");
  }

  return (
    <div className="login-form-container d-flex justify-content-center align-items-center flex-lg-row flex-column">
      <Cockpit />
      <div className="form">
        <div className="mb-3 signinForm__topLogo">
          <img src={logo} alt="" height={70} />
        </div>
        <SignInUpHeader
          title={<span>Sign In to School Facility Pro</span>}
          classes="my-4"
        >
          New Here?{" "}
          <Link to="/signup" className="custom-link">
            Create an Account
          </Link>
        </SignInUpHeader>

        <form onSubmit={formik.handleSubmit}>
          <IconInput
            label="Work Email"
            icon={faEnvelope}
            classes="mb-4"
            error={formik.errors.email}
            touched={formik.touched.email}
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <PasswordField
            label="Password"
            icon={faLock}
            classes="mb-4"
            error={formik.errors.password}
            touched={formik.touched.password}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
          />

          <Button type="submit" classes="btn-custom-primary" marginClass="my-3">
            <span>
              Sign In <FontAwesomeIcon icon={faRightToBracket} />
            </span>
          </Button>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <a
              href="#"
              className="custom-link normalize-text"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </a>
          </div>

          {/* <p className="text-center text-secondary my-3 text-bold normalize-text">
            OR
          </p>

          <IconButton
            icon={<FontAwesomeIcon icon={faGoogle} />}
            label="Continue with Google"
            classes="btn-light text-secondary"
            marginClass="my-3"
          />

          <IconButton
            icon={
              <FontAwesomeIcon icon={faFacebook} className="fw-bolder fs-6" />
            }
            label="Continue with Facebook"
            classes="btn-light text-secondary"
            marginClass="my-3"
          />

          <IconButton
            icon={<FontAwesomeIcon icon={faApple} className="fw-bolder fs-6" />}
            label="Continue with Apple"
            classes="btn-light text-secondary"
            marginClass="my-3"
          /> */}
        </form>
      </div>
    </div>
  );
}

export default SignIn;
