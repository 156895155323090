import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PureComponent } from "react";

export default class AssessmentDetailPrintAble extends PureComponent {
  render() {
    const siteDetailsSystems = this.props.siteDetailsSystems;
    const setSidebarImages = this.props.setSidebarImages;
    const setIsSidebarOpen = this.props.setIsSidebarOpen;

    if (!Object.values(siteDetailsSystems).some((record) => record.length > 0))
      return <div className="text-center mt-4">No items!</div>;

    const getUnit = (unit) => {
      if (unit === "square feet") return "sqft";

      if (unit === "linear feet") return "lft";

      return "";
    };

    return (
      <div>
        {Object.keys(siteDetailsSystems).map((system) => {
          if (siteDetailsSystems[system]?.length === 0)
            return <span key={system}></span>;

          return (
            <div className="px-4" key={system}>
              <div
                className="table-container mx-3 mb-5 mt-4"
                style={{ backgroundColor: "#fff" }}
                key={system}
              >
                <table>
                  <thead>
                    <tr>
                      <th>{system}</th>
                      <th colSpan={4}>Scope of work Description</th>
                      <th>Area/Units</th>
                      <th>Image</th>
                      <th>Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {siteDetailsSystems[system]?.map((detail) => (
                      <tr key={detail._id}>
                        <td>{detail.subSystem}</td>
                        <td colSpan={4}>
                          <div
                            className="assessment__detail__noteContainer"
                            dangerouslySetInnerHTML={{
                              __html: detail?.note?.replaceAll("&lt;", "<"),
                            }}
                          ></div>
                        </td>
                        <td>{detail.areaUnits + " " + getUnit(detail.unit)}</td>
                        <td>
                          <span
                            className="text-custom-primary"
                            style={{ fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => {
                              setSidebarImages(detail.imgUrls);
                              setIsSidebarOpen(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faImage} />
                          </span>
                        </td>
                        <td>
                          {"$" +
                            parseInt(
                              ("" + detail.totalCost).replace(/\D/g, ""),
                              10
                            ).toLocaleString()}
                        </td>
                      </tr>
                    ))}

                    <tr key={"total"}>
                      <td></td>
                      <td colSpan={4}></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>
                          {"$" +
                            parseInt(
                              (
                                "" +
                                siteDetailsSystems[system]?.reduce((p, c) => {
                                  if (c.totalCost) return p + +c.totalCost;
                                  return p;
                                }, 0)
                              ).replace(/\D/g, ""),
                              10
                            ).toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="table-divider"></div> */}
            </div>
          );
        })}
      </div>
    );
  }
}
