import React from "react";
import SchoolMapIconShape from "./SchoolMapIconShape";
import SchoolMapTextShape from "./SchoolMapTextShape";

const SchoolMapPlaygroundShapes = ({
  nodes,
  onClickShape,
  onDoubleClickShape,
}) => {
  return (
    <g id="shapes">
      {nodes.map((node) => {
        if (node.icon)
          return (
            <SchoolMapIconShape
              key={node.name}
              node={node}
              onClickShape={onClickShape}
              onDoubleClickShape={onDoubleClickShape}
            />
          );

        if (node.fontSize && node.text)
          return (
            <SchoolMapTextShape
              key={node.name}
              node={node}
              onClickShape={onClickShape}
              onDoubleClickShape={onDoubleClickShape}
            />
          );

        if (node.type === "roomData") return <span key={node.name}></span>;

        return (
          <SchoolMapTextShape
            key={node.name}
            node={node}
            onClickShape={onClickShape}
            onDoubleClickShape={onDoubleClickShape}
          />
        );
      })}
    </g>
  );
};

export default SchoolMapPlaygroundShapes;
