export const BUILDING_STATISTIC_COLUMNS = [
  {
    Header: () => <span className="text-sm font-semibold">Building Type</span>,
    Footer: "Building Statistic",
    accessor: "buildingType",
  },
  {
    Header: () => <span className="text-sm font-semibold">Building Area</span>,
    Footer: "Building Area",
    accessor: "totalInteriorSquareFeet",
    Cell: ({ value }) => {
      return Number(value).toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: () => <span className="text-sm font-semibold">Roof Area</span>,
    Footer: "Roof Area",
    accessor: "totalAreaUnites",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: () => <span className="text-sm font-semibold">Classrooms</span>,
    Footer: "Classrooms",
    accessor: "totalRooms",
    Cell: ({ value }) => {
      return value.toLocaleString(); // This will add commas as thousand separators.
    },
  },
  {
    Header: () => <span className="text-sm font-semibold">Average Age</span>,
    Footer: "Average Age",
    accessor: "avgAge",
    Cell: ({ value }) => value ?? 0, // If the value is null, display 0
  },
];
