import axios from "axios";
import { log } from "./logService";
import config from "../config/config.json";

const instance = axios.create({
  baseURL: config.apiEndPoint,
});

instance.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    log("Logging the error", error);
  }

  return Promise.reject(error);
});

const setJwt = (jwt) => {
  instance.defaults.headers.common["x-auth-token"] = jwt;
  instance.defaults.headers.common["authorization"] = "Bearer " + jwt;
};

const http = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  setJwt,
};

export default http;
