export const DISTRICTS_COLUMNS = [
  {
    Header: "District Name",
    Footer: "District Name",
    accessor: "name",
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "userName",
  },
];
