import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  faCircleXmark,
  // faFloppyDisk,
} from "@fortawesome/pro-regular-svg-icons";

const ModalHeaderForForms = ({
  title,
  formId,
  onRequestClose,
  icon,
  hideSaveButton,
}) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center bg-700"
      style={{
        height: "60px",
        // backgroundColor: "#eee",
      }}
    >
      <div className="d-flex align-items-center px-3">
        {icon && (
          <div className="bg-white w-8 h-8 flex flex-col justify-center rounded-sm">
            <FontAwesomeIcon icon={icon} color="#334155" fontSize="16px" />
          </div>
        )}{" "}
        <h6
          className="p-2 text-white text-lg font-medium"
          style={{ margin: 0 }}
        >
          {title}
        </h6>
      </div>

      <div className="mx-3 flex flex-row">
        {/* {!hideSaveButton && (
          <button
            type="submit"
            form={formId}
            className="mx-4"
            title="Save the changes"
          >
            <FontAwesomeIcon icon={faFloppyDisk} color="#fff" fontSize="18px" />
          </button>
        )} */}
        <div
          title="close the modal"
          className="mx-2 cursor-pointer"
          onClick={onRequestClose}
        >
          <FontAwesomeIcon icon={faCircleXmark} color="#fff" fontSize="18px" />
        </div>

        {/* <button
          onClick={onRequestClose}
          className="mx-3 modal-btn-custom btn-custom-light"
        >
          Close
        </button> */}
      </div>
    </div>
  );
};

export default ModalHeaderForForms;
