import React from "react";

const DashboardNumberItem = ({ label, value, backgroundColor }) => {
  const style = {};

  if (backgroundColor) style.backgroundColor = backgroundColor;

  return (
    <div className="dashboard__number__container" style={style}>
      <h6>{label} </h6>
      <span>{value ? getValue(value) : 0} </span>
    </div>
  );
};

function getValue(value) {
  return (
    "$" + parseInt(value.toString().replace(/\D/g, ""), 10).toLocaleString()
  );
}

export default DashboardNumberItem;
