import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faEnvelope,
  faBuilding,
  faRightToBracket,
} from "@fortawesome/pro-regular-svg-icons";
import * as Yup from "yup";

import Cockpit from "./Cockpit";
import Button from "../Button";
import IconInput from "../IconInput";
import IconInputSmall from "../common/IconInputSmall";
import * as userService from "../../services/userService";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import PasswordField from "../common/PasswordField";
import SignInUpHeader from "./SignInUpHeader";

// Validation Schema
const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .min(3, "Must be 3 characters or more")
    .required("Required"),
  lastName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .min(3, "Must be 3 characters or more")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  organization: Yup.string()
    .min(3, "Must be 3 characters or more")
    .required("Required"),
  password: Yup.string()
    .min(6, "Must be 6 characters or more")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  organization: "",
  password: "",
  confirmPassword: "",
};

async function onSubmit(values) {
  try {
    const { data } = await userService.register({
      name: values.firstName + " " + values.lastName,
      email: values.email,
      password: values.password,
      orgName: values.organization,
    });

    console.log(data);
    auth.loginWithJwt(data.token);
    window.location = "/dashboard";
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    )
      toast.error(error.response.data.error);
    // console.log(error);
  }
}

function SignUp() {
  const [passwordClear, setPasswordClear] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="login-form-container d-flex justify-content-center align-items-center flex-lg-row flex-column">
      <Cockpit />

      <div className="form d-flex flex-column align-items-center py-1">
        <SignInUpHeader title="Create an Account" classes="mt-4">
          Already have an account?{" "}
          <Link to="/signin" className="custom-link">
            Sign in here
          </Link>
          <br />
        </SignInUpHeader>
        <p className="text-center text-secondary mb-4 normalize-text">OR</p>

        <form onSubmit={formik.handleSubmit}>
          <div className="d-sm-flex gap-3 d-block mb-4">
            <div className="col">
              <IconInputSmall
                label="First Name"
                iconClasses="fw-bolder"
                // icon={faUser}
                classes="mb-custom-3"
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="col">
              <IconInputSmall
                label="Last Name"
                iconClasses="fw-bolder"
                // icon={faUser}
                classes="mb-custom-3"
                error={formik.errors.lastName}
                touched={formik.touched.lastName}
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="mb-2">
            <IconInput
              label="Work Email"
              icon={faEnvelope}
              classes="mb-2"
              error={formik.errors.email}
              touched={formik.touched.email}
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div>
            {" "}
            <IconInput
              label="Organization"
              icon={faBuilding}
              classes="mb-4"
              error={formik.errors.organization}
              touched={formik.touched.organization}
              type="text"
              name="organization"
              value={formik.values.organization}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <PasswordField
            label="Password"
            icon={faLock}
            classes="mb-2"
            error={formik.errors.password}
            touched={formik.touched.password}
            name="password"
            value={formik.values.password}
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <PasswordField
            label="Confirm Password"
            icon={faLock}
            classes="mb-1"
            error={formik.errors.confirmPassword}
            touched={formik.touched.confirmPassword}
            name="confirmPassword"
            value={formik.values.confirmPassword}
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <div
            className="px-2 text-sm mt-2 text-500"
            // style={{ width: "21rem", wordWrap: "break-word" }}
            id="terms-conditions"
          >
            * By clicking Sign Up, you agree to our Terms and that you have read
            our Data Use Policy, including our Cookie Use.
          </div>

          <Button type="submit" classes="btn-custom-primary" marginClass="my-3">
            <span>
              Sign Up <FontAwesomeIcon icon={faRightToBracket} />
            </span>
          </Button>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
