import React, { useState } from "react";
// import { SiteChildrenContext } from "../context/SiteChildrenContext";
// import { NEW_CONSTRUCTION_COLUMNS } from "../data/newConstructionColumns";

const options = ["Buildings", "Site Systems"];

const SiteChildren = ({ buildings, newConstructionsPrinted, siteDetails }) => {
  const [choosenOption, setChoosenOption] = useState(options[0]);
  // eslint-disable-next-line no-unused-vars
  // const { siteDetails, newConstructions } = useContext(SiteChildrenContext);

  //   printTable(
  //     Array.isArray(data[0]) ? data[0] : [],
  //     getTableColumns("building"),
  //     {
  //       building: true,
  //       facilitySystem: false,
  //     }
  //   )

  function getData(option) {
    if (option === options[0]) return buildings;

    if (option === options[1]) return siteDetails;

    if (option === options[2]) return newConstructionsPrinted;

    //   return printTable(newConstructions, NEW_CONSTRUCTION_COLUMNS, {
    //     building: false,
    //     facilitySystem: false,
    //   });
  }

  return (
    <div>
      <div className="my-3 flex flex-row justify-center">
        {/* {options.map((option) => (
          <span
            key={option}
            role="button"
            onClick={() => setChoosenOption(option)}
            className={
              option === choosenOption
                ? "dashboard__menuitem mb-2 active"
                : "dashboard__menuitem mb-2"
            }
          >
            {option}
          </span>
        ))} */}
      </div>

      {getData(choosenOption)}
    </div>
  );
};

export default SiteChildren;
