import React from "react";
import DashboardNumberItem from "./DashboardNumberItem";

const DashboardMainContentNumbers = ({ siteSummary }) => {
  return (
    <div className="dashboard__numbers">
      <DashboardNumberItem
        label="Modernization Cost"
        value={siteSummary && siteSummary.modernizationCost}
        backgroundColor="#EAF9FF"
      />
      <DashboardNumberItem
        label="New Construction Cost"
        value={siteSummary && siteSummary.newConstructionCost}
      />
      <DashboardNumberItem
        label="Total Assessment Cost"
        value={
          siteSummary &&
          siteSummary.modernizationCost + siteSummary.newConstructionCost
        }
        backgroundColor="#EDE8FF"
      />
    </div>
  );
};

export default DashboardMainContentNumbers;
