import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import siteService from "../services/siteService";
import { toast } from "react-toastify";
import Table from "./common/Table";
import { SCHOOL_FOR_PUBLIC_COLUMNS } from "../data/schoolForPublicColumns";
import districtService from "../services/districtService";

const DistrictSchoolPublic = () => {
  const [district, setDistrict] = useState(null);
  const [sites, setSites] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  const loadResource = useCallback(async () => {
    try {
      const { data: res1 } = await districtService.getDistrictById(
        params.districtID
      );
      if (res1.success) setDistrict(res1.data);

      const { data: res } = await siteService.getSitesByDistrict(
        params.districtID
      );

      if (res.success) {
        setSites(res.data);
      }
    } catch (error) {
      toast.error(
        "Something went error while loading schools, check your connection"
      );
    }

    setIsLoading(false);
  }, [params]);

  useEffect(() => {
    loadResource();
  }, [loadResource]);

  if (isLoading) return <div className="p-4">Loading...</div>;

  if (!isLoading && (!district || !sites))
    return (
      <div className="p-4">Sorry, something went wrong while loading data.</div>
    );

  return (
    <div className="px-5">
      {district && (
        <div className="d-flex justify-content-between align-items-center my-4 px-1">
          <div>
            <h4>{district.name}</h4>
          </div>
          <div>
            <img
              src={district.imgUrl}
              style={{ borderRadius: "5px", width: "150px" }}
              alt=""
            />
          </div>
        </div>
      )}

      {sites && (
        <Table tableColumns={SCHOOL_FOR_PUBLIC_COLUMNS} tableData={sites} />
      )}
    </div>
  );
};

export default DistrictSchoolPublic;
