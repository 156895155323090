import React from "react";

function SignInUpHeader({ title, classes, children }) {
  return (
    <div className={`sign-in-out-header ${classes}`}>
      <h3 className="text-700 text-2xl font-rubik">{title}</h3>
      <p className="text-center text-sm">{children}</p>
    </div>
  );
}

export default SignInUpHeader;
