import React, { useState, useRef, useEffect } from "react";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownList from "./DropdownList";

const Dropdown = ({
  label,
  selected,
  setSelected,
  options,
  disabled,
  isFilterActive,
  icon,
  idKey,
  valueKey,
  notMargin,
}) => {
  const [isActive, setIsActive] = useState(false);

  let selected1 = "";
  options.forEach((option) => {
    if (selected === option[idKey]) selected1 = option[valueKey];
  });

  const node = useRef();

  // Track events outside scope
  const clickOutside = (e) => {
    if (node && node.current && !node.current.contains(e.target)) {
      // outside click
      setIsActive(false);
      return;
    }
    // inside click
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);

    // clean up function before running new effect
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [isActive]);

  return (
    <div className={getDropdownClasses(disabled, isFilterActive, notMargin)}>
      <div
        className={`custom-dropdown-btn ${
          !disabled && selected === "" && label !== "Facility System"
            ? "active-filter"
            : ""
        } `}
        onClick={(e) => setIsActive(!isActive)}
      >
        <span>
          <span style={{ marginRight: "0.6rem" }}>
            <FontAwesomeIcon icon={icon} />
          </span>

          {selected1
            ? selected1.length <= 20
              ? selected1
              : selected1.substring(0, 18) + "..."
            : label}
        </span>

        <span>
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </div>

      {!disabled && isActive && options && (
        <div ref={node}>
          <DropdownList
            options={options}
            selected={selected1}
            setSelected={setSelected}
            setIsActive={setIsActive}
            idKey={idKey}
            valueKey={valueKey}
          />
        </div>
      )}
    </div>
  );
};

function getDropdownClasses(disabled, isFilterActive, notMargin) {
  let classes = "custom-dropdown ";

  if (disabled) classes += "custom-dropdown-disabled ";

  if (isFilterActive) classes += "used-filter ";

  if (!notMargin) classes += "custom-dropdown-margin";

  return classes;
}

export default Dropdown;
