export const OPSCCodes = [
  "CR",
  "LAB",
  "AD",
  "LIB",
  "MU",
  "KT",
  "GYM",
  "S/L",
  "RR",
  "ST",
  "WW",
  "OCWW",
  "SG",
  "COMP",
  "AUD",
  "MAINT",
  "AG",
  "OTHER",
];
