import {
  faBuilding,
  faCalendar,
  // faClone,
} from "@fortawesome/pro-regular-svg-icons";
import { faPeopleRoof } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import DashboardInfoBlock from "./DashboardInfoBlock";

const DashboardMainContentBasicInfo = ({ selectedSchoolObj, siteSummary }) => {
  return (
    <div className="mb-4 districtUser__basicInfo__container">
      {selectedSchoolObj.yearBuild && (
        <DashboardInfoBlock
          label="Date Built"
          value={selectedSchoolObj.yearBuild}
          icon={faCalendar}
        />
      )}

      {siteSummary && (
        <DashboardInfoBlock
          label="Total Classrooms"
          value={siteSummary?.totalClassrooms}
          icon={faPeopleRoof}
          iconColor="#551FFF"
          iconBackground="#EDE8FF"
        />
      )}

      {siteSummary && (
        <DashboardInfoBlock
          label="Permanent Classrooms"
          value={siteSummary?.permanentClassrooms}
          icon={faPeopleRoof}
          iconColor="#769718"
          iconBackground="#f2fadc"
        />
      )}

      {siteSummary && (
        <DashboardInfoBlock
          label="Portable Classrooms"
          value={siteSummary?.portableClassrooms}
          icon={faPeopleRoof}
          iconColor="#a321de"
          iconBackground="#f0dcfa"
        />
      )}

      {siteSummary && (
        <DashboardInfoBlock
          label="Total Building Area"
          value={siteSummary?.totalArea + " sq feet"}
          icon={faBuilding}
          iconColor="tomato"
          iconBackground="#FFEBEF"
          // isLastItem={true}
        />
      )}

      {siteSummary && (
        <DashboardInfoBlock
          label="Permanent Area"
          value={siteSummary?.permanentArea + " sq feet"}
          icon={faBuilding}
          iconColor="#21deb8"
          iconBackground="#e4fbf6"
          // isLastItem={true}
        />
      )}

      {siteSummary && (
        <DashboardInfoBlock
          label="Portable Area"
          value={siteSummary?.portableArea + " sq feet"}
          icon={faBuilding}
          iconColor="#abde21"
          iconBackground="#eef8d3"
          // isLastItem={true}
        />
      )}

      {siteSummary && (
        <DashboardInfoBlock
          label="Other Non-DSA Area"
          value={siteSummary?.otherArea + " sq feet"}
          icon={faBuilding}
          iconColor="#ded921"
          iconBackground="#f6f4c1"
          // isLastItem={true}
        />
      )}

      {/* <DashboardInfoBlock
        label="Total Acres"
        value={0}
        icon={faClone}
        iconColor="dodgerblue"
        iconBackground="#EAF9FF"
        // isLastItem={true}
      /> */}

      {/* <DashboardInfoBlock
        label="Permanent Classrooms"
        value={0}
        icon={faClone}
        // iconColor="dodgerblue"
        // iconBackground="#EAF9FF"
        isLastItem={true}
      /> */}
      {/* <DashboardInfoBlock label="Portable Classrooms" value={0} /> */}
    </div>
  );
};

export default DashboardMainContentBasicInfo;
