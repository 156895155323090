import { toast } from "react-toastify";
// import http from "../services/httpService";

const apiEndPoint = "https://maps.googleapis.com/maps/api/geocode/json?";

export const addressToLatLng = async (address, setPlaces) => {
  const params = {
    address,
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  };
  const queryString = new URLSearchParams(params).toString();
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(`${apiEndPoint}${queryString}`, {
      requestOptions,
    });

    const result = await response.json();

    if (result.status.toUpperCase() === "OK") setPlaces(result.results);
    else setPlaces([]);
  } catch (err) {
    toast.error(
      "Geocode was not successful, please check the address and try again!"
    );
  }
};
