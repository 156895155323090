import React, { useState, useEffect, useCallback, useContext } from "react";
// import { useFormik } from "formik";
// import * as Yup from "yup";

// import Select from "../components/common/Select";
// import Textarea from "../components/common/Textarea";
// import Input from "../components/common/Input";
// import Modal from "../components/common/Modal";

// import { uploadImage } from "./../utils/uploadImage";
// import auth from "../services/authService";
import siteService from "../../services/siteService";
import buildingService from "../../services/buildingService";
// import ModalHeaderForForms from "../components/ModalHeaderForForms";
// import { faCity } from "@fortawesome/free-regular-svg-icons";
// import AddSystem from "./AddSystem";
// import AddSubSystem from "./AddSubSystem";
// import AddActionItem from "./AddActionItem";
// import LeafletDrawOnMapModal from "../components/LeafletDrawOnMapModal";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMapLocationDot } from "@fortawesome/pro-regular-svg-icons";
// import { MapModeContext } from "../context/MapModeContext";
// import MapModeSwitcher from "../components/MapModeSwitcher";
// import DrawOnMapModal from "../components/DrawOnMapModal";
// import Editor from "../components/Editor";
// import { PRIORITY_OPTIONS } from "../data/priority";
import BuildingForm from "./BuildingForm";
import { toast } from "react-toastify";
import Table from "../common/Table";
import { BUILDING_DETAIL_COLUMNS } from "../../data/buildingDetailColumns";
import BuildingDetailForm from "./BuildingDetailForm";
import buildingDetailService from "../../services/buildingDetailService";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessageLines,
  faEdit,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import DeleteDialog from "../common/DeleteDialog";
// import img_placeholder from "../assets/img-placeholder.jpg";
import ActionBtn from "../common/ActionBtn";
import { NoteContext } from "../../context/NoteContext";
import BuildingSystemsTable from "./BuildingSystemsTable";
import buildingSystemService from "../../services/buildingSystemService";
import Editor from "../Editor";

// Fake data
// import actions from "../data/action.json";
// import buildings from "../data/Building.json";
// import priority from "../data/priority.json";
// import schools from "../data/schools.json";
// import subSystems from "../data/subSystem.json";
// import systems from "../data/system.json";

// Validation Schema
// const validate = Yup.object({
//   // schoolName: Yup.string()
//   //   .max(15, "Must be 15 characters or less")
//   //   .min(2, "Must be 2 characters or more")
//   //   .required("Required"),
//   buildingName: Yup.string()
//     .min(2, "Must be 2 characters or more")
//     .required("required"),
//   // system: Yup.string()
//   //   .min(2, "Must be 2 characters or more")
//   //   .required("Required"),
//   // subSystem: Yup.string().required("Required"),
//   // actionItem: Yup.string().required("Required"),
//   // constructionCost: Yup.number().required(),
//   yearBuild: Yup.number()
//     .min(1900, "Should not be less than 1900")
//     .max(3000, "Should not be more than 3000"),
// });

// const initialValues = {
// schoolName: "",
// buildingID: "",
// buildingName: "",
// yearBuild: "",
// system: "",
// subSystem: "",
// action: "",
// actionItem: "",
// note: "",
// areaUnits: "",
// constructionCost: "",
// softCost: "",
// priority: "",
// };

// const modalMockState = {
//   siteLocation: false,
//   system: false,
//   subSystem: false,
//   action: false,
//   actionItem: false,
//   editOverlay: false,
// };

const tabs = ["Building Information", "Building System", "Building Assessment"];

const AddBuilding = ({
  filters,
  formId,
  onRequestClose,
  saveData,
  feature,
  point,
  data,
  buildingArea,
  clickedBuildingInteriorArea,
}) => {
  const { setCurrentNote, setIsNoteOpen } = useContext(NoteContext);

  // const [imageErrors, setImageErrors] = useState("");

  const [details, setDetails] = useState(null);
  const [building, setBuilding] = useState(data ? data : null);
  const [siteName, setSiteName] = useState("");
  // const [showBuildingForm, setShowBuildingForm] = useState(true);
  const [showBuildingDetailForm, setShowBuildingDetailForm] = useState(false);
  const [detailToEdit, setDetailToEdit] = useState(null);
  const [detailToEditIndex, setDetailToEditIndex] = useState(-1);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("Building Information");
  const [buildingSystems, setBuildingSystems] = useState(null);
  const [note, setNote] = useState("");

  const [preloadData, setPreloadData] = useState({
    system: [],
    subSystem: [],
    action: [],
    actionItem: [],
    priority: [],
  });

  const loadBuildingDetails = useCallback(async () => {
    if (!data) return;

    const { data: res } =
      await buildingDetailService.getBuildingDetailsByBuilding(data._id);
    setDetails(res.data[0]);
    setNote(res.data[0]?.note ?? "");
  }, [data]);

  async function loadPreloadData() {
    const { data: res } = await buildingService.getPreloadData();
    setPreloadData(res.data[0]);
  }

  useEffect(() => {
    loadPreloadData();
    loadBuildingDetails();
  }, [loadBuildingDetails]);

  useEffect(() => {
    // Fetch data from backend
    const fetchData = async () => {
      const { data: res } =
        await buildingSystemService.getBuildingSystemsByBuilding(building._id);

      if (res.success) {
        let newBuildingSystems = res.data[0];
        if (res.data.length === 0) {
          newBuildingSystems = {
            systems: [],
          };
        }
        setBuildingSystems(newBuildingSystems);
      }
    };

    if (building) fetchData();
  }, [building]);

  const loadSite = useCallback(
    async function () {
      const { data: res } = await siteService.getSiteById(filters.site);
      setSiteName(res.data.schoolName);
    },
    [filters.site]
  );

  useEffect(() => {
    loadSite();
  }, [loadSite]);

  async function onSubmit(e) {
    e.preventDefault();

    if (!building) return toast.error("Add Building first, then try to save.");

    // if (showBuildingForm)
    //   return toast.info(
    //     "First update the building or cancel updating using buttons located at the end of the form then try to save."
    //   );

    // if (images.length === 0) return setImageErrors("Required");

    // values.note = note;
    // values.imgUrls = images;
    // values.user = auth.getCurrentUser().id;
    // values.unit = unit;
    // values.totalCost = totalCost;
    // values.schoolName = siteName;
    // values.siteID = filters.site;

    // if (feature) values.feature = feature;
    // if (point)
    //   values.feature = {
    //     type: "point",
    //     coord: point,
    //   };
    // if (
    //   editedOverlay &&
    //   editedOverlay.type &&
    //   editedOverlay.type === "polygon"
    // ) {
    //   values.feature = editedOverlay;
    // } else if (editedOverlay && !editedOverlay.type) {
    //   values.feature = {
    //     type: "point",
    //     coord: editedOverlay,
    //   };
    // }

    if (details || note) {
      const values = {
        details: details?.details,
        note: note,
        buildingID: building._id,
        siteID: filters?.site,
        // user: auth.getCurrentUser().id,
      };

      if (details?._id) values._id = details._id;

      try {
        await buildingDetailService.saveBuildingDetail(values);
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        )
          toast.error(error.response.data.error);
        else toast.error("something went wrong");
      }
    }

    if (buildingSystems) {
      const values = {
        systems: buildingSystems.systems,
        buildingID: building._id,
        siteID: filters?.site,
      };

      if (buildingSystems._id) values._id = buildingSystems._id;

      try {
        await buildingSystemService.saveBuildingSystem(values);
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        )
          toast.error(error.response.data.error);
        else toast.error("something went wrong");
      }
    }

    onRequestClose();
  }

  async function handleAddBuilding(values) {
    values.schoolName = siteName;
    values.siteID = filters.site;
    // if (data && data.feature) values.feature = data.feature;
    // if (feature) values.feature = feature;
    // if (point)
    //   values.feature = {
    //     type: "point",
    //     coord: point,
    //   };
    // if (
    //   editedOverlay &&
    //   editedOverlay.type &&
    //   editedOverlay.type === "polygon"
    // ) {
    //   values.feature = editedOverlay;
    // } else if (editedOverlay && !editedOverlay.type) {
    //   values.feature = {
    //     type: "point",
    //     coord: editedOverlay,
    //   };
    // }

    const newBuilding = await saveData(
      values,
      "building",
      data ? data._id : null
    );
    setBuilding(newBuilding);
    // setShowBuildingForm(false);

    // setBuildingID(newBuilding?._id);
  }

  function handleAddBuildingDetail(detail) {
    setDetails((pre) => {
      if (pre) {
        const newBuildingDetails = { ...pre };

        if (detailToEdit && detailToEditIndex !== -1) {
          const newDetails = [...newBuildingDetails.details];
          newDetails[detailToEditIndex] = detail;
          newBuildingDetails.details = newDetails;
          return newBuildingDetails;
        }

        newBuildingDetails.details = [...newBuildingDetails.details, detail];
        return newBuildingDetails;
      }

      return {
        details: [detail],
      };
    });

    setShowBuildingDetailForm(false);
  }

  const handleDelete = () => {
    setDetails((pre) => {
      const newSiteDetail = { ...pre };
      const newDetails = [...newSiteDetail.details];
      newDetails.splice(detailToEditIndex, 1);
      newSiteDetail.details = newDetails;
      return newSiteDetail;
    });
    setDetailToEdit(null);
    setDetailToEditIndex(-1);
    setIsDeleteDialogOpen(false);
  };

  const getActionsButtons = (row) => {
    return (
      <>
        <ActionBtn
          icon={faEdit}
          onClick={() => {
            setDetailToEdit(row);
            // setShowBuildingForm(false);
            setShowBuildingDetailForm(true);
          }}
        />

        <ActionBtn
          icon={faMessageLines}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentNote(row.note);
            setIsNoteOpen(true);
          }}
        />

        <ActionBtn
          isDeleteBtn={true}
          icon={faTrashCan}
          onClick={(e) => {
            setDetailToEdit(row);
            setIsDeleteDialogOpen(true);
          }}
        />
      </>
    );
  };

  const getTableColumns = () => {
    return [
      ...BUILDING_DETAIL_COLUMNS,
      {
        Header: "Actions",
        Footer: "",
        id: "actions",
        accessor: (row) => getActionsButtons(row),
      },
    ];
  };

  const getTabContent = () => {
    if (currentTab === tabs[0])
      return (
        // <div className="modal-content-2">
        <BuildingForm
          data={building}
          onSaveBuilding={handleAddBuilding}
          isEditing={!!building}
          // onCancel={building ? () => setShowBuildingForm(false) : null}
          // onMapEdit={() => openModal("editOverlay")}
          // preloadData={preloadData}
          buildingArea={buildingArea}
          clickedBuildingInteriorArea={clickedBuildingInteriorArea}
        />
        // </div>
      );

    if (currentTab === tabs[1])
      return (
        <BuildingSystemsTable
          buildingSystems={buildingSystems}
          setBuildingSystems={setBuildingSystems}
        />
      );

    if (currentTab === tabs[2])
      return (
        <>
          <div className="d-flex flex-row justify-content-between mb-4">
            <button
              type="button"
              className="btn btn-sm btn-custom-primary"
              onClick={() => {
                setShowBuildingDetailForm(true);
                // setShowBuildingForm(false);
                setDetailToEdit(null);
                setDetailToEditIndex(-1);
              }}
            >
              Add Task
            </button>
          </div>
          {details && (
            <>
              <Table
                tableData={details ? details.details : []}
                tableColumns={getTableColumns()}
                resourceToLoad="Site Details"
                onRowClicked={(_record, index) => {
                  setDetailToEditIndex(index);
                }}
              />
              <div className="my-5 d-flex justify-content-center">
                <div
                  style={{ borderTop: "1px solid #aaa", width: "12rem" }}
                ></div>
              </div>
            </>
          )}

          {!details && (
            <>
              <div className="text-center">
                No details yet, try to add by clicking add detail
              </div>
              <hr style={{ margin: "30px" }} />
            </>
          )}

          <Editor label="Site Observations" data={note} onChange={setNote} />

          <div className="my-5 d-flex justify-content-center">
            <div style={{ borderTop: "1px solid #aaa", width: "12rem" }}></div>
          </div>

          <div className="modal-content-1  mb-3">
            {showBuildingDetailForm && (
              <BuildingDetailForm
                data={detailToEdit}
                onAddDetail={handleAddBuildingDetail}
                // onAddSystem={() => openModal("system")}
                // onAddSubSystem={() => openModal("subSystem")}
                preloadData={preloadData}
              />
            )}
          </div>
        </>
      );
  };

  return (
    <>
      <div className="d-none">
        <form onSubmit={onSubmit} id={formId}></form>
      </div>

      <div className="mb-2 d-flex justify-content-center w-full">
        {tabs.map((tab) => (
          <span
            key={tab}
            role="button"
            onClick={() => setCurrentTab(tab)}
            className={`mx-2 px-2 py-1 rounded-sm ${
              tab === currentTab ? " text-white bg-700" : "font-normal"
            }`}
            // className={
            //   tab === currentTab
            //     ? "dashboard__menuitem mb-2 active"
            //     : "dashboard__menuitem mb-2"
            // }
          >
            {tab}
          </span>
        ))}
      </div>

      {getTabContent()}

      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        message={`Your related sites, buildings, facility system and rooms data will be deleted, and you can not undo that`}
        onRequestClose={() => {
          setDetailToEdit(null);
          setDetailToEditIndex(-1);
          setIsDeleteDialogOpen(false);
        }}
        onDelete={handleDelete}
      />
    </>
  );
};

export default AddBuilding;
