import React from "react";
import { Link } from "react-router-dom";
import {
  faArrowRightFromBracket,
  // faHome,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "reactjs-popup";
import logo from "../assets/Icon_only_transparent.svg";

const Navbar = ({ user, onHomeButtonClicked }) => {
  // function handleActiveNavItem({ isActive }) {
  //   return isActive
  //     ? "custom-nav-item custom-nav-item-active"
  //     : "custom-nav-item";
  // }

  return (
    <div
      className="custom-navbar d-flex justify-content-between align-items-center hidden-custom-print bg-700"
      // style={{
      //   paddingLeft: "0px !important",
      // }}
    >
      <nav className="custom-nav bg-700">
        <Link
          to="/districts"
          className="brand-logo custom-nav-item"
          onClick={onHomeButtonClicked}
        >
          {/* <FontAwesomeIcon icon={faHome} /> */}
          <div className="flex flex-col justify-center mx-3">
            <div>
              <img
                className=""
                src={logo}
                alt="Logo"
                height="50px"
                width="50px"
              />
            </div>
            <div className="text-xs text-white pl-2 pt-1">Home</div>
          </div>
          <div className="text-white text-lg"> SCHOOL FACILITY PRO</div>
        </Link>
        {/* 
        <NavLink to="/dashboard" className={handleActiveNavItem}>
          Dashboard 
        </NavLink> */}

        {/* {user?.types !== "organization" && (
          <NavLink to="/dashboard" className={handleActiveNavItem}>
            Dashboard
          </NavLink>
        )} */}

        {/* <NavLink to="/districts" className={handleActiveNavItem} end>
          School Facility Pro
        </NavLink> */}

        {/* <NavLink to="/districts/map" className={handleActiveNavItem}>
          Map
        </NavLink> */}
      </nav>

      <div>
        <Popup
          position="bottom left"
          on="click"
          closeOnDocumentClick
          contentStyle={{ padding: "0px", border: "none" }}
          arrow={false}
          trigger={
            <div className="profile-avatar-container mx-2">
              {user && user.image ? (
                <img className="profile-avatar" src={user.image} alt="" />
              ) : (
                <FontAwesomeIcon
                  className="user-icon text-secondary"
                  icon={faUser}
                />
              )}
              {/* <span className="navbar__username">{user.name}</span> */}
            </div>
          }
        >
          <div className="navbar__avatar__popup text-700">
            <Link to="/profile" className="flex flex-row m-2 outline-0">
              <span className="bg-200 w-8 h-8 rounded-md mr-2 text-center flex flex-col justify-center">
                <FontAwesomeIcon icon={faUser} />
              </span>
              <span className="h-8 flex flex-col justify-center">Profile</span>
            </Link>
            <Link to="/logout" className="flex flex-row m-2 outline-0">
              <span className="bg-200 w-8 h-8 rounded-md mr-2 text-center flex flex-col justify-center">
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </span>
              <span className="h-8 flex flex-col justify-center">Logout</span>
            </Link>
          </div>
        </Popup>
      </div>
      {/* </Link> */}
    </div>
  );
};

export default Navbar;
