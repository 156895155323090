import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

export const saveBuildingDetail = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(
      http.put(`${config.apiEndPoint1}/buildingdetails/${id}`, data),
      {
        pending: "Updating Building Detail...",
        success: "Building Detail Updated Successfully!",
        error: "Failed, please try again.",
      }
    );
  }

  return toast.promise(
    http.post(config.apiEndPoint1 + "/buildingdetails", data),
    {
      pending: "Adding Building...",
      success: "Building detail Added Successfully!",
      error: "Failed, please try again.",
    }
  );
};

export const getBuildingDetails = () => {
  return http.get(config.apiEndPoint1 + "/buildingdetails");
};
export const getBuildingDetailsByBuilding = (buildingID) => {
  return http.get(
    `${config.apiEndPoint1}/buildingdetails/${buildingID}/allbuildingdetails`
  );
};

export const deleteBuildingDetail = (id) => {
  return toast.promise(
    http.delete(`${config.apiEndPoint1}/buildingdetails/${id}`),
    {
      pending: "Deleting Building detail...",
      success: "Building detail Deleted Successfully!",
      error: "Building detail has already been deleted",
    }
  );
};

export const getBuildingDetailById = (id) => {
  return http.get(`${config.apiEndPoint1}/buildingdetails/${id}`);
};

const buildingDetailService = {
  saveBuildingDetail,
  getBuildingDetails,
  getBuildingDetailsByBuilding,
  deleteBuildingDetail,
  getBuildingDetailById,
};

export default buildingDetailService;
