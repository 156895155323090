import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../common/Input";
import Select from "../common/Select";
import { OPSCCodes } from "../../data/OPSCCodes";
import { GRADE_LEVELS } from "../../data/roomGradeLevels";
import { uploadImage } from "../../utils/uploadImage";
import auth from "../../services/authService";
import PhotoPreviewForForms from "../common/PhotoPreviewForForms";
import FileInput from "../common/FileInput";
import { useStore } from "../../store";

// Validation Schema
const validate = Yup.object({
  // schoolName: Yup.string()
  //   .max(40, "Must be 15 characters or less")
  //   .min(2, "Must be 2 characters or more")
  //   .required("Required"),
  // buildingName: Yup.string()
  //   .min(3, "Must be 3 characters or more")
  //   .required("Required"),
  // system: Yup.string()
  //   .min(2, "Must be 2 characters or more")
  //   .required("Required"),
  // subSystem: Yup.string().required("Required"),
  // actionItem: Yup.string().required("Required"),
  // constructionCost: Yup.number().required(),
});

const RoomForm = ({ data, onSaveRoom, isEditing, onCancel, roomArea }) => {
  const [images, setImages] = useState(data ? data.imgUrls : []);
  // const [stateLoading, setStateLoading] = useState(0);

  const initialValues = {
    schoolName: "",
    buildingName: "",
    system: "",
    subSystem: "",
    action: "",
    actionItem: "",
    note: "",
    areaUnits: "",
    constructionCost: "",
    softCost: "",
    priority: "",
    stateLoading: "",
    districtLoading: "",
    exclude: false,
    // newly added fields
    roomNumber: "",
    roomUsage: "",
    OPSCCode: "",
    gradeLevel: "",
    interiorSquarFeet: "",
  };
  const formik = useFormik({
    initialValues: data ? data : initialValues,
    validationSchema: validate,
    enableReinitialize: true,
    onSubmit,
  });

  let lowgrades = ["TK", "K", "1st", "2nd", "3rd", "4th", "5th", "6th"];
  let highgrades = ["7th", "8th", "9th", "10th", "11th", "12th"];

  let capacityCode = ["LAB", "CR"];

  const getStateLoading = () => {
    let stateLoading = 0;
    let grade = formik.values.gradeLevel;
    if (grade) {
      if (lowgrades.includes(grade)) {
        stateLoading = 25;
      } else if (highgrades.includes(grade)) {
        stateLoading = 27;
      } else if (grade === "SDC") {
        stateLoading = 13;
      }
    }
    return stateLoading;
  };

  function onSubmit(values) {
    values.imgUrls = images;
    values.stateLoading = getStateLoading();
    values.user = auth.getCurrentUser().id;
    if (roomArea) values.interiorSquarFeet = +roomArea;

    onSaveRoom(values);
  }

  const onChooseFile = async (e) => {
    const images = await uploadImage(e.target.files);
    setImages(images);
  };
  // console.log(useStore((state) => state.roomArea));
  return (
    <>
      <div></div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full h-full flex flex-col justify-center align-middle">
          <div className="flex flex-col border-2 border-200 mb-2 rounded-lg pt-4 w-full h-full">
            <div className="flex flex-col flex-grow w-full justify-center px-2">
              <div className="flex flex-row w-full justify-between">
                <Input
                  label={"Room Number"}
                  name="roomNumber"
                  error={formik.errors.roomNumber}
                  touched={formik.touched.roomNumber}
                  value={formik.values.roomNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  smallinput={true}
                />

                <Input
                  label={"Room Usage"}
                  name="roomUsage"
                  error={formik.errors.roomUsage}
                  touched={formik.touched.roomUsage}
                  value={formik.values.roomUsage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  smallinput={true}
                />

                <Select
                  options={OPSCCodes}
                  label={"OPSC Code"}
                  name="OPSCCode"
                  classes="mb-custom-3"
                  error={formik.errors.OPSCCode}
                  touched={formik.touched.OPSCCode}
                  value={formik.values.OPSCCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  smallinput={true}
                />
                <Select
                  options={GRADE_LEVELS}
                  label={"Grade Level"}
                  name="gradeLevel"
                  classes="mb-custom-3"
                  error={formik.errors.gradeLevel}
                  touched={formik.touched.gradeLevel}
                  value={
                    formik.values.OPSCCode === "LAB"
                      ? formik.values.gradeLevel
                      : formik.values.OPSCCode === "CR"
                      ? formik.values.gradeLevel
                      : ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  smallinput={true}
                  disabled={!capacityCode.includes(formik.values.OPSCCode)}
                />
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
              <div className="flex flex-row w-full justify-between">
                <Input
                  label="Interior Square Feet"
                  name="interiorSquarFeet"
                  error={formik.errors.interiorSquarFeet}
                  touched={formik.touched.interiorSquarFeet}
                  value={useStore((state) => state.roomArea)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="string"
                  min={0}
                  smallinput={true}
                  disabled
                />
                <Input
                  label="State Loading"
                  name="stateLoading"
                  error={formik.errors.stateLoading}
                  touched={formik.touched.stateLoading}
                  value={getStateLoading()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  min={0}
                  smallinput={true}
                  disabled
                />
                <Input
                  label="District Loading"
                  name="districtLoading"
                  error={formik.errors.districtLoading}
                  touched={formik.touched.districtLoading}
                  value={formik.values.districtLoading}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  min={0}
                  smallinput={true}
                  disabled={!capacityCode.includes(formik.values.OPSCCode)}
                />
                <div className="mx-4  flex flex-col justify-center">
                  <div className="flex flex-row space-x-2">
                    <input
                      type="checkbox"
                      id="exclude"
                      name="exclude"
                      checked={formik.values.exclude}
                      onChange={formik.handleChange}
                      value={formik.values.exclude}
                    />
                    <div for="exclude">Exclude from capacity</div>
                  </div>
                </div>
              </div>
              <div className="h-0.5 w-full bg-200 mb-4"></div>
              <div className="flex flex-row w-full justify-left">
                <FileInput
                  label={"Images"}
                  showLabel={true}
                  name="images"
                  type="file"
                  onChange={onChooseFile}
                />
                <PhotoPreviewForForms data={images} setData={setImages} />
              </div>
            </div>

            {/* <Input
              label={"Images"}
              type="file"
              onChange={onChooseFile}
              multiple
              // error={imageErrors}
              // touched={imageErrors !== ""}
            />

            <PhotoPreviewForForms data={images} setData={setImages} /> */}

            {/* <Editor data={note} onChange={setNote} /> */}

            {/* <div
                className="px-2"
                role="button"
                onClick={() => openModal("editOverlay")}
              >
                <FontAwesomeIcon
                  icon={faMapLocationDot}
                  className="text-custom-primary"
                  style={{
                    fontSize: "1.5rem",
                  }}
                />
              </div> */}
            <br />
          </div>
          <div className="flex flex-row justify-end  flex-nowrap max-w-5xl ">
            {/* {onCancel && (
            <button
              onClick={onCancel}
              className="mx-3 px-3 modal-btn-custom btn-light"
              type="button"
            >
              Details List
            </button>
          )} */}

            <button
              className="w-3/5 bg-700 text-white h-10 rounded-md font-medium"
              type="submit"
            >
              {isEditing ? "Update" : "Add Room"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RoomForm;
