import React from "react";
import Dropdown from "../common/Dropdown";
import img_placeholder from "../../assets/img-placeholder.jpg";
import DashboardSideMenuItem from "./DashboardSideMenuItem";
import { faMap } from "@fortawesome/pro-regular-svg-icons";
import { faConnectdevelop } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import {
  faBoxesStacked,
  faMapMarked,
  faPrint,
  faSquarePollHorizontal,
  faSquarePollVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideMenu = ({
  sites,
  selected,
  handleOnSelect,
  selectedSchoolObj,
  onCreateReport,
}) => {
  return (
    <div className="text-center dashboard__menu__container py-3 position-relative">
      <Dropdown
        label="School"
        icon={faMapMarked}
        disabled={!sites}
        isFilterActive={true}
        options={sites}
        selected={selected}
        idKey="_id"
        valueKey="schoolName"
        setSelected={handleOnSelect}
        notMargin={true}
      />

      {/* <div className="custom__separtor"></div> */}

      <img
        src={
          selectedSchoolObj?.imgUrls[0]
            ? selectedSchoolObj?.imgUrls[0]
            : img_placeholder
        }
        alt=""
        className="rounded"
      />

      {/* <div className="custom__separtor"></div> */}

      <div>
        <NavLink to="/dashboard" className="no-underline" end>
          {({ isActive }) => (
            <DashboardSideMenuItem
              label="Assessment Summary"
              isActive={isActive}
              icon={faSquarePollHorizontal}
            />
          )}
        </NavLink>

        <NavLink to="/dashboard/assessment-details" className="no-underline">
          {({ isActive }) => (
            <DashboardSideMenuItem
              label="Assessment Details"
              icon={faSquarePollVertical}
              isActive={isActive}
            />
          )}
        </NavLink>

        <NavLink to="/dashboard/building-inventory" className="no-underline">
          {({ isActive }) => (
            <DashboardSideMenuItem
              label="Building Inventory"
              icon={faBoxesStacked}
              isActive={isActive}
            />
          )}
        </NavLink>

        {/* <NavLink to="/districts/map" className="no-underline">
          {({ isActive }) => ( */}
        <span>
          <DashboardSideMenuItem
            label="View Map"
            icon={faMap}
            // isActive={isActive}
          />
        </span>
        {/* )}
        </NavLink> */}

        <NavLink to="/dashboard/guide" className="no-underline">
          {({ isActive }) => (
            <DashboardSideMenuItem
              label="Deferred Maintenance Pacing Guide"
              icon={faConnectdevelop}
              isActive={isActive}
            />
          )}
        </NavLink>
      </div>

      {onCreateReport && (
        <button
          className="modal-btn-custom btn-custom-primary mt-2"
          onClick={onCreateReport}
          style={{ position: "absolute", bottom: "1rem" }}
        >
          <FontAwesomeIcon icon={faPrint} className="normalize-text" />
          &nbsp;&nbsp;Create Report
        </button>
      )}
    </div>
  );
};

export default SideMenu;
