import React from "react";
import { SCHOOL_MAP_ICONS_DISPLAY } from "../data/schooMapIcons";

const SchoolMapIconShape = ({ node, onClickShape, onDoubleClickShape }) => {
  return (
    <g
      key={node.name}
      className="node"
      id={node.name}
      style={{
        transform: `translate( ${node.x}  + ${node.y} )`,
        pointerEvents: "all",
      }}
    >
      <image
        xlinkHref={SCHOOL_MAP_ICONS_DISPLAY[node.icon]}
        x={node.x}
        y={node.y}
        height={15}
        width={15}
        onClick={() => {
          onClickShape(node);
        }}
        // onDoubleClick={() => onDoubleClickShape(node)}
        style={{ pointerEvents: "all" }}
      />
    </g>
  );
};

export default SchoolMapIconShape;
