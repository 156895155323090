import React, { useState, useCallback, useEffect, useContext } from "react";
// import { useFormik } from "formik";
// import * as Yup from "yup";
import {
  faMessageLines,
  faPenToSquare,
  // faCity,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";

import siteService from "../../services/siteService";
import buildingService from "../../services/buildingService";
import roomService from "../../services/roomService";
import Table from "../common/Table";
import { ROOM_DETAIL_COLUMNS } from "../../data/roomDetailColumns";
import ActionBtn from "../common/ActionBtn";
import { NoteContext } from "../../context/NoteContext";
import RoomForm from "./RoomForm";
import RoomDetailForm from "./RoomDetailForm";
import DeleteDialog from "../common/DeleteDialog";
import { toast } from "react-toastify";
import roomDetailService from "../../services/roomDetailService";
import Editor from "../Editor";
import roomSystemService from "../../services/roomSystemService";
import RoomSystemsTable from "./RoomSystemsTable";

// Validation Schema
// const validate = Yup.object({
//   // schoolName: Yup.string()
//   //   .max(40, "Must be 15 characters or less")
//   //   .min(2, "Must be 2 characters or more")
//   //   .required("Required"),
//   // buildingName: Yup.string()
//   //   .min(3, "Must be 3 characters or more")
//   //   .required("Required"),
//   // system: Yup.string()
//   //   .min(2, "Must be 2 characters or more")
//   //   .required("Required"),
//   // subSystem: Yup.string().required("Required"),
//   // actionItem: Yup.string().required("Required"),
//   // constructionCost: Yup.number().required(),
// });

// const initialValues = {
//   schoolName: "",
//   buildingName: "",
//   system: "",
//   subSystem: "",
//   action: "",
//   actionItem: "",
//   note: "",
//   areaUnits: "",
//   constructionCost: "",
//   softCost: "",
//   priority: "",

//   // newly added fields
//   roomNumber: "",
//   roomUsage: "",
//   OPSCCode: "",
//   gradeLevel: "",
//   interiorSquarFeet: "",
// };

// const modalMockState = {
//   siteLocation: false,
//   system: false,
//   subSystem: false,
//   action: false,
//   actionItem: false,
//   editOverlay: false,
// };

const tabs = ["Room Information", "Room System", "Room Assessment"];

const AddRoom = ({
  filters,
  formId,
  onRequestClose,
  saveData,
  feature,
  point,
  data,
  roomArea,
}) => {
  const { setCurrentNote, setIsNoteOpen } = useContext(NoteContext);

  const [details, setDetails] = useState(null);
  const [room, setRoom] = useState(data ? data : null);
  const [siteName, setSiteName] = useState("");
  // const [showRoomForm, setShowRoomForm] = useState(true);
  const [showRoomDetailForm, setShowRoomDetailForm] = useState(false);
  const [detailToEdit, setDetailToEdit] = useState(null);
  const [detailToEditIndex, setDetailToEditIndex] = useState(-1);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("Room Information");
  const [roomSystems, setRoomSystems] = useState(null);
  const [note, setNote] = useState("");

  // const [imageErrors, setImageErrors] = useState("");
  // const [editedOverlay, setEditedOverlay] = useState(null);
  // const [note, setNote] = useState(data ? data.note : "");
  // const [images, setImages] = useState(data ? data.imgUrls : []);
  // eslint-disable-next-line no-unused-vars
  const [buildingOrFacility, setBuildingOrFacility] = useState("");
  // const [unit, setUnit] = useState(
  //   data && data.unit ? data.unit : "linear feet"
  // );
  // const [totalCost, setTotalCost] = useState(data ? data.totalCost : "");
  // const [isModalOpen, setIsModalOpen] = useState({
  //   siteLocation: false,
  //   system: false,
  //   subSystem: false,
  //   action: false,
  //   actionItem: false,
  //   editOverlay: false,
  // });
  const [preloadData, setPreloadData] = useState({
    siteLocation: [],
    system: [],
    subSystem: [],
    action: [],
    actionItem: [],
    priority: [],
  });

  const loadRoomDetails = useCallback(async () => {
    if (!data) return;

    const { data: res } = await roomDetailService.getRoomDetailsByRoom(
      data._id
    );
    setDetails(res.data[0]);
    setNote(res.data[0].note);
    // console.log(res.data[0]);
  }, [data]);

  async function loadPreloadData() {
    const { data: res } = await roomService.getPreloadData();
    setPreloadData(res.data[0]);
  }

  useEffect(() => {
    loadPreloadData();
    loadRoomDetails();
  }, [loadRoomDetails]);

  useEffect(() => {
    // Fetch data from backend
    const fetchData = async () => {
      const { data: res } = await roomSystemService.getRoomSystemsByRoom(
        room._id
      );

      if (res.success) {
        let newRoomSystems = res.data[0];
        if (res.data.length === 0) {
          newRoomSystems = {
            systems: [],
          };
        }
        setRoomSystems(newRoomSystems);
      }
    };

    if (room) fetchData();
  }, [room]);

  const loadData = useCallback(
    async function () {
      const { data: res } = await siteService.getSiteById(filters.site);
      setSiteName(res.data.schoolName);

      if (filters.building) {
        const { data: res1 } = await buildingService.getBuildingsByID(
          filters.building
        );
        setBuildingOrFacility(res1.data.buildingName);
      }
    },
    [filters.site, filters.building]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  async function onSubmit(e) {
    e.preventDefault();

    if (!room) return toast.error("Add Room first, then try to save.");

    // if (showRoomForm)
    //   return toast.info(
    //     "First update the room or cancel updating using buttons located at the end of the form then try to save."
    //   );

    // if (!details) return onRequestClose();

    if (details || note) {
      const values = {
        details: details.details,
        note: note,
        roomID: room._id,
        siteID: filters?.site,
        // user: auth.getCurrentUser().id,
      };

      if (details._id) values._id = details._id;

      try {
        await roomDetailService.saveRoomDetail(values);
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        )
          toast.error(error.response.data.error);
        else toast.error("something went wrong");
      }
    }

    if (roomSystems) {
      const values = {
        systems: roomSystems.systems,
        roomID: room._id,
        siteID: filters?.site,
      };

      if (roomSystems._id) values._id = roomSystems._id;

      try {
        await roomSystemService.saveRoomSystem(values);
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        )
          toast.error(error.response.data.error);
        else toast.error("something went wrong");
      }
    }

    // onRequestClose();
  }

  const getActionsButtons = (row) => {
    return (
      <>
        <ActionBtn
          icon={faPenToSquare}
          onClick={() => {
            setDetailToEdit(row);
            // setShowRoomForm(false);
            setShowRoomDetailForm(true);
          }}
        />

        <ActionBtn
          icon={faMessageLines}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentNote(row.note);
            setIsNoteOpen(true);
          }}
        />

        <ActionBtn
          isDeleteBtn={true}
          icon={faTrash}
          onClick={(e) => {
            setDetailToEdit(row);
            setIsDeleteDialogOpen(true);
          }}
        />
      </>
    );
  };

  const getTableColumns = () => {
    return [
      ...ROOM_DETAIL_COLUMNS,
      {
        Header: "Actions",
        Footer: "",
        id: "actions",
        accessor: (row) => getActionsButtons(row),
      },
    ];
  };

  const handleAddRoom = async (values) => {
    values.schoolName = siteName;
    values.siteID = filters.site;
    values.buildingOrFacilityID = filters.building;

    // if (feature) values.feature = feature;
    // if (point)
    //   values.feature = {
    //     type: "point",
    //     coord: point,
    //   };

    // if (
    //   editedOverlay &&
    //   editedOverlay.type &&
    //   editedOverlay.type === "polygon"
    // ) {
    //   values.feature = editedOverlay;
    // } else if (editedOverlay && !editedOverlay.type) {
    //   values.feature = {
    //     type: "point",
    //     coord: editedOverlay,
    //   };
    // }

    const newRoom = await saveData(values, "room", data ? data._id : null);
    setRoom(newRoom);
    // setShowRoomForm(false);
  };

  const handleAddRoomDetail = (detail) => {
    setDetails((pre) => {
      if (pre) {
        const newRoomDetails = { ...pre };

        if (detailToEdit && detailToEditIndex !== -1) {
          const newDetails = [...newRoomDetails.details];
          newDetails[detailToEditIndex] = detail;
          newRoomDetails.details = newDetails;
          return newRoomDetails;
        }

        newRoomDetails.details = [...newRoomDetails.details, detail];
        return newRoomDetails;
      }

      return {
        details: [detail],
      };
    });

    setShowRoomDetailForm(false);
  };

  const handleDelete = () => {
    setDetails((pre) => {
      const newSiteDetail = { ...pre };
      const newDetails = [...newSiteDetail.details];
      newDetails.splice(detailToEditIndex, 1);
      newSiteDetail.details = newDetails;
      return newSiteDetail;
    });
    setDetailToEdit(null);
    setDetailToEditIndex(-1);
    setIsDeleteDialogOpen(false);
  };

  const getTabContent = () => {
    if (currentTab === tabs[0])
      return (
        <RoomForm
          data={room}
          onSaveRoom={handleAddRoom}
          isEditing={!!room}
          // onCancel={room ? () => setShowRoomForm(false) : null}
          roomArea={roomArea}
        />
      );

    if (currentTab === tabs[1])
      return (
        <RoomSystemsTable
          roomSystems={roomSystems}
          setRoomSystems={setRoomSystems}
        />
      );

    if (currentTab === tabs[2])
      return (
        <>
          <div className="d-flex flex-row justify-content-between mb-4">
            <h5>Room Assessment</h5>
            <button
              type="button"
              className="btn btn-sm btn-custom-primary"
              onClick={() => {
                setShowRoomDetailForm(true);
                // setShowRoomForm(false);
                setDetailToEdit(null);
                setDetailToEditIndex(-1);
              }}
            >
              Add Task
            </button>
          </div>
          {details && (
            <>
              <Table
                tableData={details ? details.details : []}
                tableColumns={getTableColumns()}
                resourceToLoad="Site Details"
                onRowClicked={(_record, index) => {
                  setDetailToEditIndex(index);
                }}
              />
              <div className="my-5 d-flex justify-content-center">
                <div
                  style={{ borderTop: "1px solid #aaa", width: "12rem" }}
                ></div>
              </div>
            </>
          )}

          {!details && (
            <>
              <div className="text-center">
                No details yet, try to add by clicking add detail
              </div>
              <hr style={{ margin: "30px" }} />
            </>
          )}

          <Editor label="Site Observations" data={note} onChange={setNote} />

          <div className="my-5 d-flex justify-content-center">
            <div style={{ borderTop: "1px solid #aaa", width: "12rem" }}></div>
          </div>

          <div className="modal-content-1">
            {showRoomDetailForm && (
              <RoomDetailForm
                data={detailToEdit}
                onAddDetail={handleAddRoomDetail}
                preloadData={preloadData}
              />
            )}
          </div>
        </>
      );
  };

  return (
    <>
      <div className="d-none">
        <form onSubmit={onSubmit} id={formId}></form>
      </div>

      <div className="mb-1 d-flex justify-content-center w-full">
        {tabs.map((tab) => (
          <span
            key={tab}
            role="button"
            onClick={() => setCurrentTab(tab)}
            className={
              tab === currentTab
                ? "dashboard__menuitem mb-2 active"
                : "dashboard__menuitem mb-2"
            }
          >
            {tab}
          </span>
        ))}
      </div>

      {getTabContent()}

      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        message={`Your related sites, buildings, facility system and rooms data will be deleted, and you can not undo that`}
        onRequestClose={() => {
          setDetailToEdit(null);
          setDetailToEditIndex(-1);
          setIsDeleteDialogOpen(false);
        }}
        onDelete={handleDelete}
      />
    </>
  );
};

export default AddRoom;
