import http from "./httpService";
import config from "../config/config.json";
import { toast } from "react-toastify";

// const apiEndPoint = config.apiEndPoint + "/buildings";

export const saveRoomSystem = (data) => {
  if (data._id) {
    const id = data._id;
    delete data._id;
    return toast.promise(
      http.put(`${config.apiEndPoint1}/roomsystems/${id}`, data),
      {
        pending: "Updating Room System...",
        success: "Room System Updated Successfully!",
        error: "Failed, please try again.",
      }
    );
  }

  return toast.promise(http.post(`${config.apiEndPoint1}/roomsystems`, data), {
    pending: "Adding Room System...",
    success: "Room System Added Successfully!",
    error: "Failed, please try again.",
  });
};

export const getRoomSystem = () => {
  return http.get(config.apiEndPoint1 + "/roomsystems");
};

export const getRoomSystemsByRoom = (roomID) => {
  return http.get(
    `${config.apiEndPoint1}/roomsystems/${roomID}/allroomsystems`
  );
};

export const deleteRoomSystem = (id) => {
  return toast.promise(
    http.delete(`${config.apiEndPoint1}/roomsystems/${id}`),
    {
      pending: "Deleting Room system...",
      success: "Room system Deleted Successfully!",
      error: "Room system has already been deleted",
    }
  );
};

export const getRoomSystemByID = (id) => {
  return http.get(`${config.apiEndPoint1}/roomsystems/${id}`);
};

const roomSystemService = {
  saveRoomSystem,
  getRoomSystem,
  deleteRoomSystem,
  getRoomSystemsByRoom,
  getRoomSystemByID,
};

export default roomSystemService;
