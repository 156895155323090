export const BUILDING_OPSC_COLUMNS = [
  {
    Header: "Building Name",
    Footer: "Building Name",
    accessor: "buildingName",
  },
  {
    Header: "Building Type",
    Footer: "Building Type",
    accessor: "buildingType",
  },
  {
    Header: "Date Constructed",
    Footer: "Date Constructed",
    accessor: "yearBuild",
  },
  {
    Header: "Building Area",
    Footer: "Building Area",
    accessor: "areaUnits",
    // Cell: ({ value }) => {
    //   return value.toLocaleString();
    // },
    Cell: ({ value }) => {
      let numValue = Number(value);
      if (!isNaN(numValue)) {
        return numValue.toLocaleString(); // This will add commas as thousand separators.
      } else {
        console.error(`'value' cannot be converted to a number: ${value}`);
        return value; // or return some fallback value
      }
    },
  },
  {
    Header: "OPSC Code",
    Footer: "OPSC Code",
    accessor: "OPSCCodes",
    Cell: ({ value }) => {
      return (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {value.map((val, i) => (
              <span className="border-b-2 border-200" key={i}>
                {val}
              </span>
            ))}
          </div>
          <span className="font-bold">Total</span>
        </>
      );
    },
  },
  {
    Header: "Building Area",
    Footer: "Building Area",
    accessor: "interiorSquarFeets",
    // Cell: ({ value }) => {
    //   let numValue = Number(value);
    //   if (!isNaN(numValue)) {
    //     return numValue.toLocaleString(); // This will add commas as thousand separators.
    //   } else {
    //     console.error(`'value' cannot be converted to a number: ${value}`);
    //     return value; // or return some fallback value
    //   }
    // },
    Cell: ({ value }) => {
      const sum = value.reduce((partialSum, a) => partialSum + a, 0);
      return (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {value.map(
              (val, i) => {
                let numValue = Number(val);
                if (!isNaN(numValue)) {
                  return (
                    <span className="border-b-2 border-200">
                      {numValue.toLocaleString()}
                    </span>
                  ); // This will add commas as thousand separators.
                } else {
                  console.error(
                    `'value' cannot be converted to a number: ${val}`
                  );
                  return (
                    <span className="border-b-2 border-200" key={i}>
                      {val}
                    </span>
                  ); // or return some fallback value
                }
              }
              // <span key={i}>{val.toLocaleString()}</span>
            )}
          </div>
          <span className="font-bold">{sum.toLocaleString()}</span>
        </>
      );
    },
  },
];
