import React from "react";
import ReactDOM from "react-dom";
import { motion, AnimatePresence } from "framer-motion";

const Modal = ({ isOpen, onRequestClose, height, width, children }) => {
  if (isOpen) {
    return ReactDOM.createPortal(
      <AnimatePresence>
        <motion.div className={"modal-wrapper"}>
          <motion.div
            className="modal-backdrop"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 0.3,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.3,
              },
            }}
            onClick={onRequestClose}
          />
          <motion.div
            initial={{
              scale: 0,
              height: height,
              width: width,
            }}
            animate={{
              scale: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              scale: 0,
              transition: {
                delay: 0.3,
              },
            }}
            className={"modal-box"}
          >
            <motion.div
              className="normalize-text"
              style={{
                maxHeight: "90%",
              }}
              initial={{
                x: 100,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              }}
              exit={{
                x: 100,
                opacity: 0,
                transition: {
                  duration: 0.3,
                },
              }}
            >
              {/* <div className="modal-content"> */}

              {children}

              {/* </div> */}
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>,
      document.getElementById("modal-root")
    );
  }

  return null;
};

export default Modal;
