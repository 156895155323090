import React from "react";

const BuildingReportToPrintWithMap = ({ building }) => {
  if (!building) return null;

  return (
    <div className="buildingReport__container">
      {building.buildingName && (
        <span>Building Name: {building.buildingName}</span>
      )}

      {building.buildingType && (
        <span className="mx-5">Building Type: {building.buildingType}</span>
      )}

      {building.yearBuild && (
        <span className="mx-5">Date Constructed: {building.yearBuild}</span>
      )}
      {building.areaUnits && <span>Building Area: {building.areaUnits}</span>}
    </div>
  );
};

export default BuildingReportToPrintWithMap;
