import http from "./httpService";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import config from "../config/config.json";

// const apiEndPoint = config.apiEndPoint + "/users";
const tokenKey = "token";

http.setJwt(getJwt());

export const login = async (user) => {
  //  const { data: jwt } = await http.post(apiEndPoint, { email: user.email, password: user.password });

  const { data } = await toast.promise(
    http.post(config.apiEndPoint1 + "/auth/login", user),
    {
      pending: "Logging in, please wait...",
      success: "Successfully logged in!",
      error: "Email or password is incorrect.",
    }
  );

  localStorage.setItem(tokenKey, data.token);
  http.setJwt(data.token);
  document.cookie = `token=${data.token}`;
  return data;
};

export const loginWithJwt = (jwt) => {
  localStorage.setItem(tokenKey, jwt);
  document.cookie = `token=${jwt}`;
};

export const getCurrentUser = () => {
  try {
    const token = localStorage.getItem(tokenKey);
    return jwt_decode(token);
  } catch (error) {
    return null;
  }
};

export const logout = () => {
  localStorage.removeItem(tokenKey);
  http.get(config.apiEndPoint1 + "/auth/logout");
};

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export const me = () => {
  return http.get(config.apiEndPoint1 + "/auth/me");
};

export const forgotPassword = async (email) => {
  await toast.promise(
    http.post(config.apiEndPoint1 + "/auth/forgotPassword", { email }),
    {
      pending: "please wait...",
      success: "A link Successfully sent to your email!",
      error: "Failed, please check your email and try again.",
    }
  );
};

const auth = {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  me,
  forgotPassword,
};

export default auth;
