import React from "react";
import DashboardDataSummaryItem from "./DashboardDataSummaryItem";
import DashboardDetailItem from "./DashboardDetailItem";

const DashboardMainContentDetails = ({ siteSummary, isForPrint }) => {
  return (
    <div
      className={` ${
        isForPrint
          ? "dashboard__details__container__print"
          : "dashboard__details__container"
      } `}
    >
      <DashboardDetailItem
        label="SITE SYSTEMS"
        infoObj={siteSummary?.siteSystemData}
        isForPrint={isForPrint}
      />

      {/* <DashboardDetailItem
        label="BUILDING SYSTEMS"
        infoObj={siteSummary?.buildingSystemData}
      /> */}
      <DashboardDetailItem
        label="EXTERIOR SYSTEMS"
        infoObj={siteSummary?.exteriorBuildingSystems}
        isForPrint={isForPrint}
      />
      <DashboardDetailItem
        label="INTERIOR SYSTEMS"
        infoObj={siteSummary?.interiorBuildingSystems}
        isForPrint={isForPrint}
      />

      <div
        className={
          isForPrint ? "dashboard__detailItem__print" : "dashboard__detailItem"
        }
      >
        <div className="dashboard__detailItem__header">NEW CONSTRUCTION</div>
        {siteSummary && [
          siteSummary.newConstruction.map((newConstruction, index) => {
            return (
              <DashboardDataSummaryItem
                key={newConstruction.cost + newConstruction.system}
                label={newConstruction.subSystem}
                value={newConstruction.totalCost}
              />
            );
          }),
          <DashboardDataSummaryItem
            key="total"
            label="Total"
            value={siteSummary.newConstruction.reduce((p, c) => p + c.cost, 0)}
            isTotal={true}
          />,
        ]}
      </div>
    </div>
  );
};

export default DashboardMainContentDetails;
