import {
  faUserEdit,
  faUserPlus,
  faKey,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { useEffect } from "react";
import AddUser from "../components/Auth/AddUser";
import ChangePassword from "../components/Auth/ChangePassword";
import Modal from "./common/Modal";
import ModalHeaderForForms from "./ModalHeaderForForms";
import Navbar from "./Navbar";
import ProfileInfo from "./ProfileInfo";
import ProfileUsersList from "./ProfileUsersList";
import userService from "../services/userService";
import DeleteDialog from "./common/DeleteDialog";
import { toast } from "react-toastify";
import EditAdminUser from "../components/Auth/EditAdminUser";

const Profile = ({ user, onChangeUserInfo }) => {
  const [usersList, setUsersList] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState({
    changePassword: false,
    addUser: false,
    editAdmin: false,
  });

  async function loadData() {
    const { data: res1 } = await userService.getOrgUsers();
    setUsersList(res1.data);
  }

  async function handleDelete() {
    try {
      await userService.deleteOrgUser(recordToDelete._id);

      let newUsersList = [...usersList];
      newUsersList = newUsersList.filter(
        (user) => user._id !== recordToDelete._id
      );
      setUsersList(newUsersList);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      )
        toast.error(error.response.data.error);
    }

    setIsDeleteDialogOpen(false);
  }

  async function saveUser(user, id) {
    const { data: res } = await userService.saveOrgUser(user, id);
    // console.log(res.data);
    let newUsersList = [...usersList];

    if (id) {
      newUsersList = newUsersList.map((user) =>
        user._id === id ? res.data : user
      );
    } else {
      newUsersList.push(res.data);
    }

    setUsersList(newUsersList);
  }

  async function saveAdmin(data) {
    const { data: res } = await userService.update(data);

    onChangeUserInfo((preState) => {
      return {
        ...preState,
        ...res.data,
      };
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Navbar user={user} />
      <div className="profile-container">
        <ProfileInfo
          userInfo={user}
          setUserInfo={onChangeUserInfo}
          onEditUser={() =>
            setIsModalOpen({
              editAdmin: true,
              changePassword: false,
              addUser: false,
            })
          }
          onChangePassword={() =>
            setIsModalOpen({
              changePassword: true,
              addUser: false,
              editAdmin: false,
            })
          }
        />

        {user && user.types === "organization" && (
          <ProfileUsersList
            usersList={usersList}
            setRecordToEdit={setRecordToEdit}
            onDelete={(user) => {
              setRecordToDelete(user);
              setIsDeleteDialogOpen(true);
            }}
            onAddUser={() =>
              setIsModalOpen({
                addUser: true,
                changePassword: false,
                editAdmin: false,
              })
            }
          />
        )}
      </div>

      <Modal
        isOpen={isModalOpen.changePassword}
        onRequestClose={() =>
          setIsModalOpen({
            changePassword: false,
            addUser: false,
            editAdmin: false,
          })
        }
        height="25rem"
        width="30rem"
      >
        <ModalHeaderForForms
          formId="changePassword"
          icon={faKey}
          title="Change Password"
          onRequestClose={() =>
            setIsModalOpen({
              changePassword: false,
              addUser: false,
              editAdmin: false,
            })
          }
        />

        <div className="d-flex justify-content-center my-5">
          <ChangePassword
            formId="changePassword"
            onRequestClose={() =>
              setIsModalOpen({
                changePassword: false,
                addUser: false,
                editAdmin: false,
              })
            }
          />
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen.addUser}
        onRequestClose={() => {
          setRecordToEdit(null);
          setIsModalOpen({
            changePassword: false,
            addUser: false,
            editAdmin: false,
          });
        }}
        height="40rem"
        width="30rem"
        className="flex flex-col justify-center align-middle"
      >
        <ModalHeaderForForms
          formId="addUser"
          icon={faUserPlus}
          title="Add User"
          onRequestClose={() => {
            setRecordToEdit(null);
            setIsModalOpen({
              changePassword: false,
              addUser: false,
              editAdmin: false,
            });
          }}
        />

        <div className="d-flex justify-content-center my-5">
          <AddUser
            formId="addUser"
            data={recordToEdit}
            saveUser={saveUser}
            onRequestClose={() => {
              setRecordToEdit(null);
              setIsModalOpen({
                changePassword: false,
                addUser: false,
                editAdmin: false,
              });
            }}
          />
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen.editAdmin}
        onRequestClose={() => {
          setRecordToEdit(null);
          setIsModalOpen({
            changePassword: false,
            addUser: false,
            editAdmin: false,
          });
        }}
        height="25rem"
        width="30rem"
      >
        <ModalHeaderForForms
          formId="editUser"
          icon={faUserEdit}
          title="Edit User"
          onRequestClose={() => {
            setIsModalOpen({
              changePassword: false,
              addUser: false,
              editAdmin: false,
            });
          }}
        />

        <div className="d-flex justify-content-center my-5">
          <EditAdminUser
            formId="editUser"
            data={user}
            saveUser={saveAdmin}
            onRequestClose={() => {
              setIsModalOpen({
                changePassword: false,
                addUser: false,
                editAdmin: false,
              });
            }}
          />
        </div>
      </Modal>

      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        message={`By deleting the user, this user will no longer access to any districts or organization data.`}
        onRequestClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
};

export default Profile;
